import { Badge, Button, Center, Group, Loader, Stack, Table, Text, TextInput, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Check, GenderAgender, GenderFemale, GenderMale, X } from "tabler-icons-react";
import { Database, RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";
import PageUserEdit from "./edit";

type User = Database.User;

const SEX_TEXTS = {
  male: <GenderMale size={16} color="blue" />,
  female: <GenderFemale size={16} color="purple" />,
  other: <GenderAgender size={16} />,
};

type PARAMS = {
  id: string | undefined;
};

const PageUserList: React.FC<{}> = () => {
  const params = useParams<PARAMS>();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const [users, setUsers] = useState<User[] | null>(null);
  const [query, setQuery] = useState<string>("");

  const isMobile = useMediaQuery("(max-width: " + theme.breakpoints["md"] + "px)");

  const loadUsers = (): void => {
    ajax.get("/users").on(200, (res: RequestTypes.Users) => {
      setUsers(res);
    });
  };

  const currentUser = useMemo<User | "new" | null>(() => {
    if (params.id === "new") {
      return "new";
    }

    if (params.id !== undefined && users !== null) {
      let matchingUsers = users.filter((u) => u.id.toString() === params.id ?? "0");
      if (matchingUsers.length) {
        return matchingUsers[0];
      }
    }

    return null;
  }, [params, users]);

  const filteredUsers: User[] | null = useMemo(() => {
    if (users === null) return null;

    let queries = query.split(" ").map((i) => i.trim().toLowerCase());
    return users.filter((user) =>
      queries.every(
        (q) =>
          user.givenname.toLowerCase().includes(q) ||
          user.surname.toLowerCase().includes(q) ||
          (q === "sex:f" && user.sex === "female") ||
          (q === "sex:m" && user.sex === "male") ||
          (q === "sex:o" && user.sex === "other") ||
          (q.substring(0, 5) === "age:<" && dayjs().diff(dayjs(user.dateofbirth), "year") <= parseInt(q.substring(5))) ||
          (q.substring(0, 5) === "age:>" && dayjs().diff(dayjs(user.dateofbirth), "year") >= parseInt(q.substring(5)))
      )
    );
  }, [users, query]);

  useEffect(loadUsers, []);

  if (filteredUsers === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (currentUser === null) {
    if (isMobile) {
      return (
        <>
          <Stack spacing="xs">
            <Text weight="bold">Aktuell: {filteredUsers.length} Nutzer:innen</Text>
            <TextInput
              type="search"
              placeholder="Suche nach Vorname, Nachname, Geschlecht (sex:f|m|o), Alter (age:< / age:>)"
              value={query}
              onChange={(e) => setQuery(e.currentTarget.value)}
            />
            <Button type="button" color="teal" variant="outline" component={Link} to="/users/new">
              Neuer Benutzer
            </Button>
          </Stack>

          <Table highlightOnHover>
            <thead>
              <tr>
                <th style={{ width: "10%" }}>#</th>
                <th style={{ width: "40%" }}>Vorname</th>
                <th style={{ width: "40%" }}>Nachname</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id} style={{ cursor: "pointer" }} onClick={() => navigate(`/users/${user.id}`)}>
                  <td>{user.id}</td>
                  <td>{user.givenname}</td>
                  <td>{user.surname}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      );
    } else {
      return (
        <>
          <Group mb="md">
            <Text weight="bold">Aktuell: {filteredUsers.length} Nutzer:innen</Text>
            <TextInput
              type="search"
              placeholder="Suche nach Vorname, Nachname, Geschlecht (sex:f|m|o), Alter (age:< / age:>)"
              value={query}
              onChange={(e) => setQuery(e.currentTarget.value)}
              sx={{ flex: 1 }}
            />
            <Button type="button" color="teal" variant="outline" component={Link} to="/users/new">
              Neuer Benutzer
            </Button>
          </Group>

          <Table highlightOnHover>
            <thead>
              <tr>
                <th style={{ width: "5%" }}>#</th>
                <th style={{ width: "15%" }}>Vorname</th>
                <th style={{ width: "15%" }}>Nachname</th>
                <th style={{ width: "23%" }}>E-Mail</th>
                <th style={{ width: "8%" }}>Geschlecht</th>
                <th style={{ width: "8%" }}>Datenfreigabe</th>
                <th style={{ width: "10%" }}>Geburtsdatum</th>
                <th style={{ width: "8%" }}>Alter</th>
                <th style={{ width: "8%" }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id} style={{ cursor: "pointer" }} onClick={() => navigate(`/users/${user.id}`)}>
                  <td>{user.id}</td>
                  <td>{user.givenname}</td>
                  <td>{user.surname}</td>
                  <td>{user.mail}</td>
                  <td>{SEX_TEXTS[user.sex]}</td>
                  <td>{user.sharepersonal === "1" ? <Check size={16} color="green" /> : <X size={16} color="red" />}</td>
                  <td>{dayjs(user.dateofbirth).format("DD.MM.YYYY")}</td>
                  <td>{dayjs().diff(dayjs(user.dateofbirth), "year")}</td>
                  <td>
                    {user.state === "active" ? (
                      user.password === "" ? (
                        <Badge color="cyan">neu</Badge>
                      ) : (
                        <Badge color="green">aktiv</Badge>
                      )
                    ) : (
                      <Badge color="orange">deaktiviert</Badge>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      );
    }
  }

  return <PageUserEdit user={currentUser} reload={loadUsers} />;
};

export default PageUserList;

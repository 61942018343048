import {
  ActionIcon,
  Alert,
  Button,
  Card,
  Grid,
  Group,
  List,
  SimpleGrid,
  Stack,
  Switch,
  Table,
  Text,
  Title,
  TypographyStylesProvider,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Check, Clock, ExclamationMark, Paperclip, Send, X } from "tabler-icons-react";
import { MailType, RecipientListItem } from "./service";

type Props = {
  mails: MailType[];
  selection: MailType | null;
  select: (mail: MailType | null) => void;
  reload: () => void;
  resend: () => void;
};

const PageMailListDesktop: React.FC<Props> = (props) => {
  const [showHidden, setShowHidden] = useDisclosure(false);

  const hasFailedRecipients = useMemo<boolean>(() => {
    if (props.selection === null) return false;

    return props.selection.recipients.some((r) => r.status === "failed");
  }, [props.selection]);

  if (props.selection !== null) {
    return (
      <>
        <Button type="button" color="teal" variant="outline" mb="md" onClick={() => props.select(null)}>
          Zurück zur Übersicht
        </Button>

        <Grid>
          <Grid.Col xs={12} lg={6}>
            {hasFailedRecipients && (
              <Alert title="Mails fehlgeschlagen" icon={<ExclamationMark />} color="red" mb="md">
                <Group>
                  <Text sx={{ flex: "1" }}>
                    Nicht alle Mails konnten erfolgreich versendet werden. Bitte prüfe, ob für die fehlgeschlagenen Mails ein neuer Sendeversuch
                    erfolgen soll.
                  </Text>

                  <ActionIcon size="lg" onClick={props.resend}>
                    <Send size={26} />
                  </ActionIcon>
                </Group>
              </Alert>
            )}

            <SimpleGrid cols={2}>
              <List spacing={2}>
                {props.selection.recipients
                  .filter((_, i) => props.selection && i < props.selection.recipients.length / 2)
                  .map((r) => (
                    <RecipientListItem key={r.recipient} recipient={r.recipient} givenname={r.givenname} surname={r.surname} status={r.status} />
                  ))}
              </List>

              <List spacing={2}>
                {props.selection.recipients
                  .filter((_, i) => props.selection && i >= props.selection.recipients.length / 2)
                  .map((r) => (
                    <RecipientListItem key={r.recipient} recipient={r.recipient} givenname={r.givenname} surname={r.surname} status={r.status} />
                  ))}
              </List>
            </SimpleGrid>
          </Grid.Col>

          <Grid.Col xs={12} lg={6}>
            <Card shadow="xl">
              <Title order={4}>{props.selection.subject}</Title>

              <TypographyStylesProvider>
                <div dangerouslySetInnerHTML={{ __html: props.selection.message }} />
              </TypographyStylesProvider>

              <Stack spacing="xs" align="flex-end">
                {props.selection.attachments.map((a) => (
                  <Text variant="link" component="a" href={`${process.env.REACT_APP_API_BASE}/file/${a.hash}?name=${a.name}`} download={a.name}>
                    Anhang: {a.name}
                    <br />
                  </Text>
                ))}
              </Stack>
            </Card>
          </Grid.Col>
        </Grid>
      </>
    );
  }

  return (
    <>
      <Grid>
        <Grid.Col xs={12} lg={8} mb="md">
          <Switch label="Auch versteckte Mails anzeigen" checked={showHidden} onChange={setShowHidden.toggle} />
        </Grid.Col>

        <Grid.Col xs={12} lg={4} mb="md">
          <Button type="button" color="teal" variant="outline" fullWidth component={Link} to="/mail/new">
            Mail verfassen
          </Button>
        </Grid.Col>
      </Grid>

      <Table highlightOnHover>
        <thead>
          <tr>
            <th style={{ width: "20%" }}>Autor</th>
            <th style={{ width: "3%" }}></th>
            <th style={{ width: "48%" }}>Betreff</th>
            <th style={{ width: "20%" }}>Datum und Zeit</th>
            <th style={{ width: "3%" }}>
              <Clock size={15} />
            </th>
            <th style={{ width: "3%" }}>
              <Check size={15} />
            </th>
            <th style={{ width: "3%" }}>
              <X size={15} />
            </th>
          </tr>
        </thead>
        <tbody>
          {props.mails
            .filter((mail) => showHidden || mail.show)
            .map((mail) => (
              <tr key={mail.id} style={{ cursor: "pointer" }} onClick={() => props.select(mail)}>
                <td>{mail.author}</td>
                <td>{mail.attachments.length > 0 ? <Paperclip size={15} /> : ""}</td>
                <td>{mail.subject}</td>
                <td>{dayjs(mail.timestamp).format("DD.MM.YYYY, HH:mm") + " Uhr"}</td>
                <td>{mail.recipients.filter((r) => r.status === "waiting").length}</td>
                <td>{mail.recipients.filter((r) => r.status === "success").length}</td>
                <td>{mail.recipients.filter((r) => r.status === "failed").length}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
};

export default PageMailListDesktop;

import { Button as MantineButton, Grid, Group, Input, List, MultiSelect, SelectItem, Text, TextInput } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useDisclosure, useListState } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { RichTextEditor } from "@mantine/rte";
import React, { forwardRef, useEffect, useState } from "react";
import { CalendarEvent, CalendarOff, User } from "tabler-icons-react";
import { z } from "zod";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";
import FileUpload, { fileSizeToText } from "../../service/fileUpload";

type MailAttachmentType = {
  hash: string;
  name: string;
  size: number;
};

type CustomSelectItem = SelectItem & {
  group: string;
  type: "person" | "event-true" | "event-false";
};

type Recipients = {
  selectData: CustomSelectItem[];
  ids: string[];
};

type FormType = {
  recipients: string[];
  subject: string;
  message: string;
};

const MAIL_PREG = /^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

const PageMailNew: React.FC<{}> = () => {
  const mailForm = useForm<FormType>({
    initialValues: {
      recipients: [],
      subject: "",
      message: "",
    },
    validate: zodResolver(
      z.object({
        recipients: z.string().array().nonempty("Es muss mindestens ein Empfänger gewählt werden."),
        subject: z.string().min(1, "Der Betreff darf nicht leer sein.").max(100, "Der Betreff darf maximal 100 Zeichen lang sein."),
      })
    ),
  });
  const [mailFormLoading, changeMailFormLoading] = useDisclosure(false);

  const [recipients, setRecipients] = useState<Recipients>({
    selectData: [],
    ids: [],
  });
  const [attachments, setAttachments] = useListState<MailAttachmentType>([]);

  const loadData = (): void => {
    ajax.get("/mails/persons").on(200, (res: RequestTypes.MailsPersons) => {
      let persons: CustomSelectItem[] = res.persons.map((p) => ({
        value: p.id,
        label: `${p.givenname} ${p.surname}`,
        group: "Benutzer",
        type: "person",
      }));

      let events: CustomSelectItem[] = res.events.map((e) => ({
        value: res.eventParticipants
          .filter((row) => row.event === e.id)
          .map((row) => row.user)
          .join(","),
        label: e.title,
        group: "Aktionen - Teilnehmende",
        type: "event-true",
      }));

      let eventsInverse: CustomSelectItem[] = res.events.map((e) => ({
        value: res.persons
          .filter((user) => !res.eventParticipants.some((row) => row.event === e.id && row.user === user.id))
          .map((user) => user.id)
          .join(","),
        label: e.title,
        group: "Aktionen - Nicht Teilnehmende (z.B. für Reminder)",
        type: "event-false",
      }));

      setRecipients({
        selectData: [...persons, ...events, ...eventsInverse],
        ids: res.persons.map((p): string => p.id),
      });
    });
  };

  const addAllRecipients = (): void => {
    let all = Array.from(new Set(mailForm.values.recipients.concat(recipients.ids)));

    mailForm.setFieldValue("recipients", all);
  };

  const delAllRecipients = (): void => {
    mailForm.setFieldValue("recipients", []);
  };

  const changeRecipients = (data: string[]): void => {
    mailForm.setFieldValue(
      "recipients",
      Array.from(new Set(data.map((entry) => entry.split(",")).flat())).filter((id) => id !== "")
    );
  };

  const addAttachment = (name: string, type: string, size: number, hash: string) => {
    setAttachments.append({
      hash: hash,
      name: name,
      size: size,
    });
  };

  const send = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!mailForm.validate().hasErrors) {
      let data = {
        recipients: mailForm.values.recipients,
        subject: mailForm.values.subject,
        message: mailForm.values.message,
        attachments: attachments,
      };
      changeMailFormLoading.open();

      ajax
        .post("/mails", data)
        .on(204, () => {
          changeMailFormLoading.close();
          showNotification({
            title: "Mail wird gesendet",
            message: "Die E-Mail wurde auf dem Server gespeichert und wird in den nächsten Minuten versendet.",
            color: "green",
          });
        })
        .on(400, () => {
          changeMailFormLoading.close();
          showNotification({
            message: "Die E-Mail wurde auf dem Server gespeichert und wird in den nächsten Minuten versendet.",
            color: "red",
          });
        });
    }
  };

  useEffect(loadData, []);

  return (
    <>
      <ul>
        <li>
          <i>#anrede#</i> wird zu <i>Liebe</i> bzw <i>Lieber</i>, abhängig vom Geschlecht
        </li>
        <li>
          <i>#vorname#</i> wird zum Vornamen
        </li>
        <li>
          <i>#nachname#</i> wird zum Nachnamen
        </li>
      </ul>

      <form onSubmit={send}>
        <MultiSelect
          label="Empfänger"
          placeholder="Empfänger suchen"
          searchable
          creatable
          data={recipients.selectData}
          value={mailForm.values.recipients}
          onChange={changeRecipients}
          error={mailForm.errors.recipients}
          getCreateLabel={(query) => `Mail-Empfänger: ${query}`}
          shouldCreate={(query) => MAIL_PREG.test(query)}
          itemComponent={RecipientSelectItem}
        />

        <Group position="right" mt="xs" mb="md">
          <MantineButton type="button" color="teal" variant="outline" size="xs" onClick={addAllRecipients}>
            Alle hinzufügen
          </MantineButton>
          <MantineButton type="button" color="teal" variant="outline" size="xs" onClick={delAllRecipients}>
            Alle entfernen
          </MantineButton>
        </Group>

        <Grid>
          <Grid.Col xs={12} lg={8} mb="md">
            <TextInput placeholder="Betreff" label="Betreff" {...mailForm.getInputProps("subject")} />
          </Grid.Col>

          <Grid.Col xs={12} lg={4} mb="md">
            <Input.Wrapper label="Anhänge" labelElement="div">
              <div>
                {attachments.length > 0 && (
                  <List>
                    {attachments.map((a) => (
                      <List.Item key={a.name}>
                        {a.name}{" "}
                        <Text color="dimmed" component="span">
                          {fileSizeToText(a.size)}
                        </Text>
                      </List.Item>
                    ))}
                  </List>
                )}
              </div>

              <FileUpload
                multiple
                buttonProps={{ type: "button", color: "teal", variant: "outline", fullWidth: true }}
                onUploadSuccess={addAttachment}
              >
                Anhang hinzufügen
              </FileUpload>
            </Input.Wrapper>
          </Grid.Col>
        </Grid>

        <Input.Wrapper label="Nachricht" labelElement="div" mb="md">
          <RichTextEditor {...mailForm.getInputProps("message")} />
        </Input.Wrapper>

        <Grid justify="flex-end">
          <Grid.Col xs={12} lg={4}>
            <MantineButton type="submit" variant="outline" fullWidth loading={mailFormLoading}>
              Mail versenden
            </MantineButton>
          </Grid.Col>
        </Grid>
      </form>
    </>
  );
};

export default PageMailNew;

type RecipientSelectItemProps = CustomSelectItem & React.ComponentPropsWithoutRef<"div">;

const RecipientSelectItem = forwardRef<HTMLDivElement, RecipientSelectItemProps>(({ label, type, ...others }: RecipientSelectItemProps, ref) => (
  <div ref={ref} {...others}>
    <Group noWrap>
      {type === "person" && <User size={20} />}
      {type === "event-true" && <CalendarEvent size={20} />}
      {type === "event-false" && <CalendarOff size={20} />}

      <span>{label}</span>
    </Group>
  </div>
));

import { Button, Card, List, Modal, SimpleGrid, Stack, Switch, Text } from "@mantine/core";
import { isSameDate } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import dayjs from "dayjs";
import { useMemo } from "react";

type ServiceType = {
  id: string;
  time: string;
  kind: string;
  church: string;
  hint: string;
  totalAssigns?: number;
};

export type SelectorPerson = {
  id: string;
  title: string;
  size: "s" | "l" | null;
};

export type SelectorUserDatePair = {
  user: string;
  date: Date;
};

type ExtendedPerson = {
  id: string;
  title: string;
  size: "s" | "l" | null;
  serves: boolean;
  signout: boolean;
  distance: number;
};

type Props = {
  opened: boolean;
  onClose: () => void;
  service: ServiceType | null;
  onSave: (service: string, person: string) => void;
  persons: SelectorPerson[];
  assigns: SelectorUserDatePair[];
  signouts: SelectorUserDatePair[];
};

const PersonModal: React.FC<Props> = (props) => {
  const [showAll, setShowAll] = useDisclosure(false);

  const persons = useMemo<ExtendedPerson[]>(() => {
    if (props.service === null) {
      return [];
    }
    let serviceDate = dayjs(props.service.time).toDate();

    return props.persons.map(
      (p) =>
        ({
          id: p.id,
          title: p.title,
          size: p.size,
          serves: props.assigns.some((a) => a.user === p.id && isSameDate(a.date, serviceDate)),
          signout: props.signouts.some((s) => s.user === p.id && isSameDate(s.date, serviceDate)),
          distance: Math.min(...props.assigns.filter((a) => a.user === p.id).map((a) => Math.abs(dayjs(serviceDate).diff(a.date, "day")))),
        } as ExtendedPerson)
    );
  }, [props.persons, props.service, props.assigns, props.signouts]);

  return (
    <Modal opened={props.opened} onClose={props.onClose} title="Gottesdienst hinzufügen/bearbeiten" size="xl">
      <Switch label="Alle anzeigen (auch Leiter:innen)" mb="md" checked={showAll} onChange={setShowAll.toggle} />

      <SimpleGrid cols={showAll ? 3 : 2} mb="md">
        {showAll && (
          <PersonGroup
            persons={persons.filter((p) => p.size === null)}
            condition={(p) => !p.serves && !p.signout && p.distance >= 14}
            itemStyle="buttons"
            title="Verfügbar"
            onSelected={(p) => props.service && props.onSave(props.service.id, p.id)}
            showDistance
          />
        )}
        <PersonGroup
          persons={persons.filter((p) => p.size === "s")}
          condition={(p) => !p.serves && !p.signout && p.distance >= 14}
          itemStyle="buttons"
          title="Verfügbar"
          onSelected={(p) => props.service && props.onSave(props.service.id, p.id)}
          showDistance
        />
        <PersonGroup
          persons={persons.filter((p) => p.size === "l")}
          condition={(p) => !p.serves && !p.signout && p.distance >= 14}
          itemStyle="buttons"
          title="Verfügbar"
          onSelected={(p) => props.service && props.onSave(props.service.id, p.id)}
          showDistance
        />

        {showAll && (
          <PersonGroup
            persons={persons.filter((p) => p.size === null)}
            condition={(p) => !p.serves && !p.signout && p.distance < 14}
            itemStyle="buttons"
            title="Dient innerhalb von 14 Tagen"
            onSelected={(p) => props.service && props.onSave(props.service.id, p.id)}
            showDistance
          />
        )}
        <PersonGroup
          persons={persons.filter((p) => p.size === "s")}
          condition={(p) => !p.serves && !p.signout && p.distance < 14}
          itemStyle="buttons"
          title="Dient innerhalb von 14 Tagen"
          onSelected={(p) => props.service && props.onSave(props.service.id, p.id)}
          showDistance
        />
        <PersonGroup
          persons={persons.filter((p) => p.size === "l")}
          condition={(p) => !p.serves && !p.signout && p.distance < 14}
          itemStyle="buttons"
          title="Dient innerhalb von 14 Tagen"
          onSelected={(p) => props.service && props.onSave(props.service.id, p.id)}
          showDistance
        />

        {showAll && (
          <PersonGroup persons={persons.filter((p) => p.size === null)} condition={(p) => p.serves} itemStyle="list" title="Bereits eingeteilt" />
        )}
        <PersonGroup persons={persons.filter((p) => p.size === "s")} condition={(p) => p.serves} itemStyle="list" title="Bereits eingeteilt" />
        <PersonGroup persons={persons.filter((p) => p.size === "l")} condition={(p) => p.serves} itemStyle="list" title="Bereits eingeteilt" />

        {showAll && <PersonGroup persons={persons.filter((p) => p.size === null)} condition={(p) => p.signout} itemStyle="list" title="Abgemeldet" />}
        <PersonGroup persons={persons.filter((p) => p.size === "s")} condition={(p) => p.signout} itemStyle="list" title="Abgemeldet" />
        <PersonGroup persons={persons.filter((p) => p.size === "l")} condition={(p) => p.signout} itemStyle="list" title="Abgemeldet" />
      </SimpleGrid>
    </Modal>
  );
};

export default PersonModal;

type PersonGroupProps = {
  persons: ExtendedPerson[];
  condition: (p: ExtendedPerson) => boolean;
  itemStyle: "buttons" | "list";
  showDistance?: boolean;
  title: string;
  onSelected?: (p: ExtendedPerson) => void;
};

const PersonGroup: React.FC<PersonGroupProps> = (props) => (
  <Card shadow="xl">
    <Text color="dimmed" mb="sm">
      {props.title}
    </Text>

    {props.itemStyle === "buttons" && (
      <Stack align="flex-start" spacing={1}>
        {props.persons.filter(props.condition).map((p) => (
          <Button key={p.id} type="button" variant="subtle" compact onClick={() => props.onSelected && props.onSelected(p)}>
            {p.title}
            {props.showDistance && p.distance !== Infinity && ` (${p.distance} Tage)`}
          </Button>
        ))}
      </Stack>
    )}
    {props.itemStyle === "list" && (
      <List>
        {props.persons.filter(props.condition).map((p) => (
          <List.Item key={p.id}>{p.title}</List.Item>
        ))}
      </List>
    )}
  </Card>
);

import { Tabs } from "@mantine/core";
import "dayjs/locale/de";
import React from "react";
import { EditDataTab } from "./editDataTab";
import { EditParticipantsTab } from "./editParticipantsTab";
import { EventPersonType, EventType } from "./list";

type PageEventEditProps = {
  event: "new" | Required<EventType>;
  persons: EventPersonType[];
  reload: () => void;
};

const PageEventEdit: React.FC<PageEventEditProps> = (props) => {
  if (props.event === "new") {
    return <EditDataTab {...props} />;
  }

  return (
    <>
      <Tabs defaultValue="data">
        <Tabs.List>
          <Tabs.Tab value="data">Aktionsdaten</Tabs.Tab>
          <Tabs.Tab value="participants">Teilnehmende</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="data" pt="xs">
          <EditDataTab {...props} />
        </Tabs.Panel>

        <Tabs.Panel value="participants" pt="xs">
          <EditParticipantsTab event={props.event} persons={props.persons} reload={props.reload} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default PageEventEdit;

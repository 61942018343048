import {
  Autocomplete,
  Button,
  Center,
  Grid,
  Group,
  Input,
  Loader,
  Radio,
  SimpleGrid,
  Stack,
  Table,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { LatLngExpression } from "leaflet";
import { useEffect, useMemo, useState } from "react";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import { MapOff, Notes, Search } from "tabler-icons-react";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";

type TbaAddress = {
  id: string;
  name: string;
  street: string;
  number: string;
  mail: string;
  phone: string;
  donation: "tree" | "ask" | "other";
  note: string;
  timestamp: Dayjs;
  longitude: string;
  latitude: string;
  route: string | null;
  index: number;
};

const DonationTexts = {
  tree: "Am Baum",
  ask: "Klingeln",
  other: "Anderes",
};

const PageTbaList: React.FC<{}> = () => {
  const [addresses, setAddresses] = useState<TbaAddress[] | null>(null);
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);
  const [streets, setStreets] = useState<string[]>([]);

  const [currentAddress, setCurrentAddress] = useState<TbaAddress | null>(null);
  const [query, setQuery] = useState<string>("");

  const addressesFiltered = useMemo<TbaAddress[] | null>(() => {
    if (addresses === null) return null;

    let queries = query.toLowerCase().split(" ");
    return addresses.filter((a) => queries.every((q) => a.name.toLowerCase().includes(q) || a.street.toLowerCase().includes(q)));
  }, [addresses, query]);

  const loadData = (): void => {
    let url = "/tba/registration/";
    if (start !== null && end !== null) {
      url += dayjs(start).format("YYYY-MM-DD") + "/" + dayjs(end).format("YYYY-MM-DD");
    }

    ajax.get(url).on(200, (res: RequestTypes.TbaRegistration) => {
      setStart(dayjs(+res.start * 1000).toDate());
      setEnd(dayjs(+res.end * 1000).toDate());

      setAddresses(
        res.addresses.map((a) => ({
          id: a.id,
          name: a.name,
          street: a.street,
          number: a.number,
          mail: a.mail,
          phone: a.phone,
          donation: a.donation,
          note: a.note,
          timestamp: dayjs(a.timestamp),
          longitude: a.longitude,
          latitude: a.latitude,
          route: a.route,
          index: +a.index,
        }))
      );

      setStreets(
        res.streets
          .split("\n")
          .map((s) => s.trim())
          .filter((s) => s !== "")
      );
    });
  };

  useEffect(loadData, []);

  if (addressesFiltered === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (currentAddress !== null) {
    return <EditView address={currentAddress} streets={streets} reload={loadData} cancel={() => setCurrentAddress(null)} />;
  }

  return (
    <Stack>
      <Group>
        <DatePicker label="Anzeigezeitraum Start" value={start} onChange={setStart} sx={{ flex: 1 }} />
        <DatePicker label="Anzeigezeitraum Ende" value={end} onChange={setEnd} sx={{ flex: 1 }} />
        <Button type="button" variant="outline" color="teal" onClick={loadData} mt={27}>
          Liste laden
        </Button>
      </Group>

      <Group>
        <TextInput
          aria-label="Suche"
          placeholder="Suche nach Name oder Adresse"
          icon={<Search size={20} />}
          value={query}
          onChange={(e) => setQuery(e.currentTarget.value)}
          sx={{ flex: 1 }}
        />
        <Text>{addresses?.length} Adressen</Text>
      </Group>

      <Table highlightOnHover>
        <thead>
          <tr>
            <th style={{ width: "20%" }}>Name</th>
            <th style={{ width: "20%" }}>Adresse</th>
            <th style={{ width: "20%" }}>E-Mail</th>
            <th style={{ width: "20%" }}>Spende</th>
            <th style={{ width: "15%" }}>Route</th>
            <th style={{ width: "5%" }}></th>
          </tr>
        </thead>
        <tbody>
          {addressesFiltered.map((a) => (
            <tr key={a.id} onClick={() => setCurrentAddress(a)} style={{ cursor: "pointer" }}>
              <td>{a.name}</td>
              <td>
                {a.street} {a.number}
              </td>
              <td>
                <Text variant="link" component="a" href={"mailto:" + a.mail}>
                  {a.mail}
                </Text>
              </td>
              <td>{DonationTexts[a.donation]}</td>
              <td>{a.route || "Keine"}</td>
              <td>
                <Group spacing="xs" position="right">
                  {(a.latitude === "0" || a.longitude === "0") && (
                    <Tooltip label="Fehler bei der Adresse">
                      <span>
                        <MapOff color="red" size={16} />
                      </span>
                    </Tooltip>
                  )}
                  {a.note !== "" && (
                    <Tooltip label={a.note}>
                      <span>
                        <Notes size={16} />
                      </span>
                    </Tooltip>
                  )}
                </Group>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Stack>
  );
};

export default PageTbaList;

type EditViewProps = {
  address: TbaAddress;
  streets: string[];
  reload: () => void;
  cancel: () => void;
};

type FormType = {
  name: string;
  street: string;
  number: string;
  mail: string;
  phone: string;
  donation: "tree" | "ask" | "other";
  notes: string;
};

const EditView: React.FC<EditViewProps> = (props) => {
  const [coordinates, setCoordinates] = useState<[number, number]>([+props.address.latitude, +props.address.longitude]);

  const registerForm = useForm<FormType>({
    initialValues: {
      name: props.address.name,
      street: props.address.street,
      number: props.address.number,
      mail: props.address.mail,
      phone: props.address.phone,
      donation: props.address.donation,
      notes: props.address.note,
    },
    validate: {
      name: (val) => (val.length > 0 ? (val.length <= 50 ? null : "Der Name darf maximal 50 Zeichen lang sein.") : "Der Name darf nicht leer sein."),
      street: (val) =>
        val.length > 0 ? (val.length <= 50 ? null : "Die Straße darf maximal 50 Zeichen lang sein.") : "Die Straße darf nicht leer sein.",
      number: (val) =>
        val.length > 0 ? (val.length <= 15 ? null : "Die Hausnummer darf maximal 15 Zeichen lang sein.") : "Die Hausnummer darf nicht leer sein.",
      mail: (val) => (val.length <= 75 ? null : "Die Mail-Adresse darf maximal 75 Zeichen lang sein."),
      notes: (val, vals) =>
        val.length > 0 || vals.donation !== "other"
          ? val.length <= 250
            ? null
            : "Die Anmerkungen dürfen maximal 250 Zeichen lang sein."
          : "Wo ist die Spende?",
    },
  });

  const [streetDebounced] = useDebouncedValue(registerForm.values.street, 500);
  const [numberDebounced] = useDebouncedValue(registerForm.values.number, 500);

  const reloadCoordinates = (): void => {
    let encodedAddress = encodeURIComponent(`${streetDebounced} ${numberDebounced}, 47906 Kempen`);
    axios
      .get("https://nominatim.openstreetmap.org/search?format=json&q=" + encodedAddress)
      .then((res) => {
        if (res.data[0].lat !== undefined && res.data[0].lon !== undefined) {
          setCoordinates([res.data[0].lat, res.data[0].lon]);
        } else {
          showNotification({
            message: "Zu dieser Adresse konnten keine Koordinaten gefunden werden.",
            color: "orange",
          });
        }
      })
      .catch(() => {
        showNotification({
          message: "Es gab einen Fehler beim Laden der Koordinaten für diese Adresse. Bitte versuche die Änderung später erneut.",
          color: "red",
        });
      });
  };

  const edit: React.FormEventHandler = (e) => {
    e.preventDefault();

    if (!registerForm.validate().hasErrors) {
      let data = {
        name: registerForm.values.name,
        street: registerForm.values.street,
        number: registerForm.values.number,
        mail: registerForm.values.mail,
        phone: registerForm.values.phone,
        donation: registerForm.values.donation,
        note: registerForm.values.notes,
        longitude: coordinates[1],
        latitude: coordinates[0],
      };
      ajax
        .post("/tba/registration/" + props.address.id, data)
        .on(204, () => {
          showNotification({
            message: "Die Änderung an der Adresse wurde gespeichert.",
            color: "green",
          });

          props.reload();
          props.cancel();
        })
        .on([400, 500], () => {
          showNotification({
            message: "Es gab einen Fehler beim Speichern der Änderung. Bitte versuche die Änderung später erneut.",
            color: "red",
          });
        });
    }
  };

  const remove = (): void => {
    ajax.delete("/tba/registration/" + props.address.id).on(204, () => {
      showNotification({
        message: "Die Adresse wurde gelöscht.",
        color: "green",
      });

      props.reload();
      props.cancel();
    });
  };

  useEffect(reloadCoordinates, [streetDebounced, numberDebounced]);

  return (
    <>
      <Button type="button" variant="outline" color="teal" onClick={props.cancel} mb="md">
        Abbrechen und zurück
      </Button>

      <Grid>
        <Grid.Col lg={6}>
          <MapContainer scrollWheelZoom={false} style={{ height: "500px" }}>
            <ChangeMapView center={coordinates} zoom={16} />
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={coordinates} />
          </MapContainer>
        </Grid.Col>

        <Grid.Col lg={6}>
          <form onSubmit={edit}>
            <Stack>
              <TextInput label="Name" {...registerForm.getInputProps("name")} />

              <Input.Wrapper labelElement="div" label="Adresse">
                <Grid>
                  <Grid.Col md={8}>
                    <Autocomplete aria-label="Straße" placeholder="Straße" data={props.streets} {...registerForm.getInputProps("street")} />
                  </Grid.Col>

                  <Grid.Col md={4}>
                    <TextInput aria-label="Hausnummer" placeholder="Hsnr." {...registerForm.getInputProps("number")} />
                  </Grid.Col>
                </Grid>
              </Input.Wrapper>

              <SimpleGrid cols={2}>
                <TextInput label="Längengrad" value={coordinates[0]} readOnly />
                <TextInput label="Breitengrad" value={coordinates[1]} readOnly />
              </SimpleGrid>

              <TextInput type="email" label="E-Mail-Adresse" {...registerForm.getInputProps("mail")} />

              <TextInput type="tel" label="Telefonnummer" {...registerForm.getInputProps("phone")} />

              <Radio.Group label="Spende" {...registerForm.getInputProps("donation")}>
                <Radio value="tree" label="Am Baum" />
                <Radio value="ask" label="Bitte klingeln" />
                <Radio value="other" label="Sonstiges" />
              </Radio.Group>

              <Textarea label="Sonstige Anmerkungen" {...registerForm.getInputProps("notes")} />

              <Group position="right">
                <Button type="button" variant="outline" color="red" onClick={remove}>
                  Adresse löschen
                </Button>
                <Button type="submit" variant="outline">
                  Speichern
                </Button>
              </Group>
            </Stack>
          </form>
        </Grid.Col>
      </Grid>
    </>
  );
};

const ChangeMapView: React.FC<{ center: LatLngExpression; zoom: number }> = ({ center, zoom }) => {
  const map = useMap();

  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom]);

  return null;
};

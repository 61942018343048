// import "leaflet/dist/leaflet.css";
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  Center,
  Checkbox,
  Collapse,
  Grid,
  Group,
  Input,
  Loader,
  Modal,
  Radio,
  Stack,
  Text,
  Textarea,
  TextInput,
  Title,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import axios from "axios";
import dayjs from "dayjs";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { AppContext } from "../..";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";
import PageDefaults404 from "../defaults/404";

type DateConditionsType = {
  show: {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  };
  use: {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
  };
};

type FormType = {
  name: string;
  street: string;
  number: string;
  mail: string;
  phone: string;
  donation: "tree" | "ask" | "other";
  notes: string;
  privacyAccept: boolean;
};

const PageTbaRegister: React.FC<{}> = () => {
  const context = useContext(AppContext);

  const [dateConditions, setDateConditions] = useState<DateConditionsType | null>(null);
  const [intro, setIntro] = useState<string>("");
  const [streets, setStreets] = useState<string[]>([]);
  const [currentState, setCurrentState] = useState<"init" | "loadingCoordinates" | "checkingCoordinates" | "transferring" | "done" | "failed">(
    "init"
  );

  const registerForm = useForm<FormType>({
    initialValues: {
      name: "",
      street: "",
      number: "",
      mail: "",
      phone: "",
      donation: "tree",
      notes: "",
      privacyAccept: false,
    },
    validate: {
      name: (val) =>
        val.length > 0
          ? val.length <= 50
            ? null
            : "Der Name darf maximal 50 Zeichen lang sein."
          : "Bitte geben Sie Ihren Namen an, damit wir den korrekten Baum abholen.",
      street: (val) =>
        val.length > 0
          ? val.length <= 50
            ? null
            : "Die Straße darf maximal 50 Zeichen lang sein."
          : "Bitte geben Sie Ihre Straße an, damit wir den Baum finden.",
      number: (val) =>
        val.length > 0
          ? val.length <= 15
            ? null
            : "Die Hausnummer darf maximal 15 Zeichen lang sein."
          : "Bitte geben Sie Ihre Hausnummer an, damit wir den Baum finden.",
      mail: (val) => (val.length <= 75 ? null : "Die Mail-Adresse darf maximal 75 Zeichen lang sein."),
      notes: (val, vals) =>
        val.length > 0 || vals.donation !== "other"
          ? val.length <= 250
            ? null
            : "Die Anmerkungen dürfen maximal 250 Zeichen lang sein."
          : "Sie haben für den Ort der Spende Sonstiges ausgewählt. Bitte teilen Sie uns hier mit, wo wir Ihre Spende finden.",
      privacyAccept: (val: boolean) => (val ? null : "Sie müssen unsere Datenschutzhinweise akzeptieren, damit wir Ihre Adresse speichern dürfen."),
    },
  });

  const [privacyShow, setPrivacyShow] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<[number, number]>([0, 0]);

  const formDisabled = useMemo<boolean>(() => {
    if (dateConditions === null) return true;
    return currentState !== "init" || dateConditions.use.start.isAfter(dayjs()) || dateConditions.use.end.isBefore(dayjs());
  }, [dateConditions, currentState]);

  const loadData = (): void => {
    ajax.get("/tba").on(200, (res: RequestTypes.Tba) => {
      setDateConditions({
        show: {
          start: dayjs(+res.show.start * 1000),
          end: dayjs(+res.show.end * 1000),
        },
        use: {
          start: dayjs(+res.use.start * 1000),
          end: dayjs(+res.use.end * 1000),
        },
      });

      setIntro(res.intro);
      setStreets(
        res.streets
          .split("\n")
          .map((s) => s.trim())
          .filter((s) => s !== "")
      );
    });
  };

  const loadCoordinates: React.FormEventHandler = (e) => {
    e.preventDefault();

    if (!registerForm.validate().hasErrors) {
      setCurrentState("loadingCoordinates");

      let encodedAddress = encodeURIComponent(`${registerForm.values.street} ${registerForm.values.number}, 47906 Kempen`);
      axios
        .get("https://nominatim.openstreetmap.org/search?format=json&q=" + encodedAddress)
        .then((res) => {
          if (res.data[0].lat !== undefined && res.data[0].lon !== undefined) {
            setCoordinates([+res.data[0].lat, +res.data[0].lon]);
            setCurrentState("checkingCoordinates");
          }
        })
        .catch(sendAddressAndCoordinates);
      // fallback: save without coordinates
    }
  };

  const sendAddressAndCoordinates = (): void => {
    setCurrentState("transferring");

    let data = {
      name: registerForm.values.name,
      street: registerForm.values.street,
      number: registerForm.values.number,
      mail: registerForm.values.mail,
      phone: registerForm.values.phone,
      donation: registerForm.values.donation,
      note: registerForm.values.notes,
      latitude: coordinates[0],
      longitude: coordinates[1],
    };
    ajax
      .post("/tba/registration", data)
      .on(204, () => {
        setCurrentState("done");
      })
      .on([400, 500], () => {
        setCurrentState("failed");
      });
  };

  const reset = (): void => {
    registerForm.reset();

    setCurrentState("init");
    setPrivacyShow(false);
    setCoordinates([0, 0]);
  };

  useEffect(loadData, []);

  if (dateConditions === null || currentState === "transferring") {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (
    (dateConditions.show.start.isAfter(dayjs()) || dateConditions.show.end.isBefore(dayjs())) &&
    (context.user === null || !context.user.permissions.includes("tba"))
  ) {
    return <PageDefaults404 />;
  }

  return (
    <>
      <Modal opened={currentState === "checkingCoordinates"} onClose={() => {}} title="Adresse prüfen" size="lg">
        <Stack>
          <Text>
            Bitte überprüfen Sie, ob die angezeigte Adresse korrekt ist. Ein nachträgliches Ändern der Adresse ist nur per Mail an{" "}
            <Text variant="link" component="a" href="mailto:info@md-st-josef.de">
              info@md-st-josef.de
            </Text>{" "}
            möglich.
          </Text>

          <MapContainer center={coordinates} zoom={16} scrollWheelZoom={false} style={{ height: "500px" }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={coordinates} />
          </MapContainer>

          <Group position="right">
            <Button
              variant="outline"
              color="teal"
              onClick={() => {
                setCurrentState("init");
                setCoordinates([0, 0]);
              }}
            >
              Nein, zurück
            </Button>
            <Button variant="outline" onClick={sendAddressAndCoordinates}>
              Diese Adresse ist korrekt
            </Button>
          </Group>
        </Stack>
      </Modal>

      {(currentState === "init" || currentState === "loadingCoordinates" || currentState === "checkingCoordinates") && (
        <Grid>
          <Grid.Col xs={12} lg={6}>
            <Title order={3}>Tannenbaumaktion</Title>

            {(dateConditions.use.start.isAfter(dayjs()) || dateConditions.use.end.isBefore(dayjs())) && (
              <Alert variant="filled" color="orange">
                Eine Anmeldung zur Tannenbaumaktion ist aktuell nicht möglich. Der Anmeldezeitraum für die kommende Tannenbaumaktion startet am{" "}
                {dateConditions.use.start.format("DD.MM.YYYY")} und geht bis zum {dateConditions.use.end.format("DD.MM.YYYY, HH:mm")} Uhr.
              </Alert>
            )}

            <div dangerouslySetInnerHTML={{ __html: intro }} />
          </Grid.Col>

          <Grid.Col xs={12} lg={6}>
            <form onSubmit={loadCoordinates}>
              <Stack>
                <TextInput
                  label="Ihr Name"
                  placeholder="Bitte wie auf dem Klingelschild angeben"
                  {...registerForm.getInputProps("name")}
                  disabled={formDisabled}
                />

                <Input.Wrapper labelElement="div" label="Ihre Adresse">
                  <Grid>
                    <Grid.Col md={8}>
                      <Autocomplete
                        aria-label="Straße"
                        placeholder="Straße"
                        data={streets}
                        {...registerForm.getInputProps("street")}
                        disabled={formDisabled}
                      />
                    </Grid.Col>

                    <Grid.Col md={4}>
                      <TextInput aria-label="Hausnummer" placeholder="Hsnr." {...registerForm.getInputProps("number")} disabled={formDisabled} />
                    </Grid.Col>
                  </Grid>
                </Input.Wrapper>

                <TextInput
                  type="email"
                  label="Ihre E-Mail-Adresse"
                  placeholder="empfohlen, freiwillige Angabe"
                  {...registerForm.getInputProps("mail")}
                  disabled={formDisabled}
                />

                <TextInput
                  type="tel"
                  label="Ihre Telefonnummer"
                  placeholder="freiwillige Angabe"
                  {...registerForm.getInputProps("phone")}
                  disabled={formDisabled}
                />

                <Radio.Group label="Wo finden wir Ihre Spende?" {...registerForm.getInputProps("donation")}>
                  <Radio value="tree" label="Am Baum" disabled={formDisabled} />
                  <Radio value="ask" label="Bitte klingeln" disabled={formDisabled} />
                  <Radio value="other" label="Sonstiges (bitte angeben)" disabled={formDisabled} />
                </Radio.Group>

                <Textarea
                  label="Sonstige Anmerkungen"
                  placeholder="freiwillige Angabe"
                  {...registerForm.getInputProps("notes")}
                  disabled={formDisabled}
                />

                <Checkbox
                  label="Ich bin - bis auf Widerruf - mit den Datenschutzhinweisen einverstanden"
                  {...registerForm.getInputProps("privacyAccept")}
                  disabled={formDisabled}
                />

                <Text variant="link" style={{ cursor: "pointer" }} onClick={() => setPrivacyShow((old) => !old)}>
                  {privacyShow ? "Datenschutzhinweise ausblenden" : "Datenschutzhinweise einblenden"}
                </Text>

                <Collapse in={privacyShow}>
                  <Text color="dimmed">
                    Durch Absenden dieses Formulares übersenden Sie personenbezogene Daten an uns. Diese speichern wir gemäß unserer
                    Datenschutzbestimmung, um die Erfüllung des von Ihnen gegebenen Auftrags, d.h. die Abholung des Tannenbaumes, möglich zu machen.
                    Dabei werden wir Ihre Daten niemals an außenstehene Dritte weitergeben.
                  </Text>
                  <Text color="dimmed">
                    Haben Sie eine E-Mail-Adresse angegeben, werden wir Ihnen direkt nach Absenden dieses Formulars eine E-Mail als Bestätigung des
                    Eingangs zu. Sind Sie damit nicht einverstanden, geben Sie bitte keine E-Mail-Adresse an.
                  </Text>
                  <Text color="dimmed">
                    Mit Eingabe einer E-Mail-Adresse erklären Sie sich damit einverstanden, dass wir Sie zu Werbezwecken im Zuge der nächsten
                    Tannenbaumaktion kontaktieren. Sind Sie damit nicht einverstanden, geben Sie bitte keine E-Mail-Adresse an.
                  </Text>
                  <Text color="dimmed">
                    Sollte es Unklarheiten mit Ihrer Anmeldung geben, erlauben Sie es uns mit Absenden Ihrer Daten, Sie per Mail oder Telefon zu
                    kontaktieren. Sind Sie damit nicht einverstanden, geben Sie bitte keine Telefonnummer und keine E-Mail-Adresse an.
                  </Text>
                  <Text color="dimmed">
                    Sie haben jederzeit die Möglichkeit, der Speicherung und Nutzung Ihrer Daten zu widersprechen. Bitte nutzen Sie dazu unser
                    Kontaktformular. Bitte beachten Sie, dass dann keine Erfüllung des von Ihnen erteilten Auftrags möglich ist.
                  </Text>
                </Collapse>

                <Button type="submit" variant="outline" fullWidth disabled={formDisabled}>
                  Anmeldung absenden
                </Button>
              </Stack>
            </form>
          </Grid.Col>
        </Grid>
      )}

      {currentState === "done" && (
        <Grid justify="center">
          <Grid.Col xs={12} md={6} lg={4}>
            <Card shadow="xl">
              <Stack>
                <Title order={4}>Anmeldung erfolgreich</Title>

                <Text>Hallo {registerForm.values.name}</Text>
                <Text>
                  Ihre Anmeldung für die Adresse {registerForm.values.street} {registerForm.values.number} wurde entgegengenommen. Sofern Sie eine
                  E-Mail-Adresse angegeben haben, wurde Ihnen an diese Adresse eine Bestätigung geschickt.
                </Text>
                <Text>Vielen Dank für Ihre Unterstützung.</Text>

                <Button type="button" variant="outline" color="teal" onClick={reset}>
                  Neue Anmeldung absenden
                </Button>
              </Stack>
            </Card>
          </Grid.Col>
        </Grid>
      )}

      {currentState === "failed" && (
        <Grid justify="center">
          <Grid.Col xs={12} md={6} lg={4}>
            <Card shadow="xl">
              <Stack>
                <Title order={4}>Anmeldung erfolgreich</Title>

                <Text>Hallo {registerForm.values.name}</Text>
                <Text>
                  Leider konnte Ihre Anmeldung nicht entgegengenommen werden. Bitte versuchen Sie es später erneut oder schreiben Sie uns alternativ
                  eine E-Mail an{" "}
                  <Text variant="link" component="a" href="mailto:info@md-st-josef.de">
                    info@md-st-josef.de
                  </Text>
                  .
                </Text>

                <Button type="button" variant="outline" color="teal" onClick={reset}>
                  Neue Anmeldung absenden
                </Button>
              </Stack>
            </Card>
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};

export default PageTbaRegister;

import { Button, Grid, List, NumberInput, SimpleGrid, Stack, TextInput } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { useListState } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import RichTextEditor from "@mantine/rte";
import dayjs from "dayjs";
import "dayjs/locale/de";
import React from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import ajax from "../../service/ajax";
import FileUpload from "../../service/fileUpload";
import { combineDateTime } from "../../service/service";
import { EventAttachmentType, EventPersonType, EventType } from "./list";

type Props = {
  event: "new" | EventType;
  persons: EventPersonType[];
  reload: () => void;
};

type EditForm = {
  title: string;
  start: Date | undefined;
  end: Date | undefined;
  location: string;
  target: string;
  deadline: Date | null;
  costs: number;
  description: string;
  attachments: EventAttachmentType[];
};

export const EditDataTab: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const editForm = useForm<EditForm>({
    initialValues: {
      title: props.event === "new" ? "" : props.event.title,
      start: props.event === "new" ? undefined : props.event.start.toDate(),
      end: props.event === "new" ? undefined : props.event.end.toDate(),
      location: props.event === "new" ? "" : props.event.location,
      target: props.event === "new" ? "" : props.event.for,
      deadline: props.event === "new" || props.event.deadline === null ? null : props.event.deadline.toDate(),
      costs: props.event === "new" ? 0 : props.event.costs,
      description: props.event === "new" ? "" : props.event.description,
      attachments: props.event === "new" ? [] : props.event.attachments,
    },
    validate: zodResolver(
      z.object({
        title: z.string().min(1, "Bitte gebe der Aktion einen Titel.").max(50, "Der Titel darf maximal 50 Zeichen lang sein."),
        start: z.instanceof(Date, { message: "Der Startzeitpunkt (Datum und/oder Zeit) ist kein gültiges Datum." }),
        end: z.instanceof(Date, { message: "Der Endzeitpunkt (Datum und/oder Zeit) ist kein gültiges Datum." }), // TODO: not before start
        location: z.string().min(1, "Bitte gebe den Ort der Aktion sein.").max(50, "Der Ort darf maximal 50 Zeichen lang sein."),
        target: z.string().min(1, "Bitte gebe eine Zielgruppe für die Aktion an.").max(50, "Die Zielgruppe darf maximal 50 Zeichen lang sein."),
        deadline: z.instanceof(Date, { message: "Die Anmeldefrist ist kein gültiges Datum." }).nullable(),
        costs: z.number({ invalid_type_error: "Bitte gebe eine Zahl an." }).optional(),
        description: z.string().min(1, "Bitte gebe eine genauere Beschreibung für die Aktion an."),
        // attachments: ,
      })
    ),
  });

  const [attachments, setAttachments] = useListState<EventAttachmentType>(props.event === "new" ? [] : props.event.attachments);

  const attachmentUploaded = (name: string, type: string, size: number, hash: string): void => {
    setAttachments.append({ hash, name });
  };

  const attachmentRemove = (hash: string): void => {
    setAttachments.remove(attachments.findIndex((row) => row.hash === hash));
  };

  const save = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!editForm.validate().hasErrors) {
      let data = {
        title: editForm.values.title,
        start: dayjs(editForm.values.start).format("YYYY-MM-DD HH:mm:00"),
        end: dayjs(editForm.values.end).format("YYYY-MM-DD HH:mm:00"),
        location: editForm.values.location,
        for: editForm.values.target,
        deadline: editForm.values.deadline === null ? null : dayjs(editForm.values.deadline).format("YYYY-MM-DD 23:59:00"),
        costs: editForm.values.costs ?? 0,
        description: editForm.values.description,
        attachments: attachments,
      };

      if (props.event === "new") {
        ajax
          .post("/events", data)
          .on(204, () => {
            showNotification({
              message: "Die Aktion wurde erfolgreich hinzugefügt.",
              color: "green",
            });
            props.reload();
            navigate("/event");
          })
          .on(400, () => {
            showNotification({
              message: "Die Aktion konnte aufgrund eines unbekannten Fehlers nicht hinzugefügt werden.",
              color: "red",
            });
          });
      } else {
        ajax
          .post("/events/data/" + props.event.id, data)
          .on(204, () => {
            showNotification({
              message: "Die Aktion wurde erfolgreich gespeichert.",
              color: "green",
            });
            props.reload();
            navigate("/event");
          })
          .on(400, () => {
            showNotification({
              message: "Die Aktion konnte aufgrund eines unbekannten Fehlers nicht gespeichert werden.",
              color: "red",
            });
          });
      }
    }
  };

  return (
    <form onSubmit={save}>
      <Stack spacing="sm" mb="md">
        <TextInput label="Titel" placeholder="Kurze Beschreibung" {...editForm.getInputProps("title")} />

        <SimpleGrid
          breakpoints={[
            { minWidth: "xs", cols: 1 },
            { minWidth: "sm", cols: 2 },
            { minWidth: "md", cols: 4 },
          ]}
        >
          <DatePicker
            label="Start (Datum)"
            placeholder="Datum wählen"
            clearable={false}
            locale="de"
            inputFormat="DD. MMMM YYYY"
            value={editForm.values.start}
            onChange={(date) => editForm.setFieldValue("start", combineDateTime(date, editForm.values.start))}
            error={editForm.errors.start}
          />
          <TimeInput
            label="(Zeit)"
            value={editForm.values.start}
            onChange={(time) => editForm.setFieldValue("start", combineDateTime(editForm.values.start, time))}
            error={editForm.errors.start}
          />

          <DatePicker
            label="Ende (Datum)"
            placeholder="Datum wählen"
            clearable={false}
            locale="de"
            inputFormat="DD. MMMM YYYY"
            value={editForm.values.end}
            onChange={(date) => editForm.setFieldValue("end", combineDateTime(date, editForm.values.end))}
            error={editForm.errors.end}
          />
          <TimeInput
            label="(Zeit)"
            value={editForm.values.end}
            onChange={(time) => editForm.setFieldValue("end", combineDateTime(editForm.values.end, time))}
            error={editForm.errors.end}
          />
        </SimpleGrid>

        <SimpleGrid
          breakpoints={[
            { minWidth: "xs", cols: 1 },
            { minWidth: "sm", cols: 2 },
          ]}
        >
          <TextInput label="Ort" placeholder="z.B. St. Josef, Buttermarkt" {...editForm.getInputProps("location")} />

          <TextInput label="An wen richtet sich die Aktion" placeholder="z.B. alle, 13+" {...editForm.getInputProps("target")} />
        </SimpleGrid>

        <SimpleGrid
          breakpoints={[
            { minWidth: "xs", cols: 1 },
            { minWidth: "sm", cols: 2 },
          ]}
        >
          <DatePicker
            label="Anmeldefrist"
            placeholder="Keine Anmeldung notwendig"
            locale="de"
            inputFormat="DD. MMMM YYYY"
            {...editForm.getInputProps("deadline")}
          />

          <NumberInput
            label="Kosten"
            placeholder="Keine Kosten"
            decimalSeparator=","
            precision={2}
            step={0.5}
            min={0}
            {...editForm.getInputProps("costs")}
          />
        </SimpleGrid>
      </Stack>

      <Grid mb="md">
        <Grid.Col sm={12} md={9}>
          <RichTextEditor {...editForm.getInputProps("description")} />
        </Grid.Col>

        <Grid.Col sm={12} md={3}>
          {attachments.length > 0 && (
            <List mb="md">
              {attachments.map((a) => (
                <List.Item key={a.hash} onClick={() => attachmentRemove(a.hash)}>
                  {a.name}
                </List.Item>
              ))}
            </List>
          )}

          <FileUpload
            multiple
            buttonProps={{ type: "button", color: "teal", variant: "outline", fullWidth: true }}
            onUploadSuccess={attachmentUploaded}
          >
            Anhang hinzufügen
          </FileUpload>
        </Grid.Col>
      </Grid>

      <Grid justify="flex-end">
        <Grid.Col sm={12} md={8} lg={4}>
          <Button type="submit" variant="outline" fullWidth>
            Speichern
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
};

import { Drawer } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, { useContext, useMemo } from "react";
import { AppContext } from "..";
import LoginDrawer from "../pages/login/login";
import ajax from "../service/ajax";
import { Navbar } from "./mantine-navbar";
import { NavbarGenerator, NavbarGeneratorType } from "./mantine-navbar-generator";

type ComponentNavbarProps = {
  tbaVisiblity: boolean;
};

const ComponentNavbar: React.FC<ComponentNavbarProps> = (props) => {
  const context = useContext(AppContext);

  const [loginDrawer, setLoginDrawer] = useDisclosure(false);

  const navbarGenerator = useMemo<NavbarGeneratorType>(() => {
    const hp = (permission: string): boolean => {
      if (context.user === null) {
        return false;
      }

      return context.user.permissions.includes(permission);
    };

    const logout = (): void => {
      ajax.delete("/login").on(204, () => {
        showNotification({
          message: "Du wurdest erfolgreich ausgeloggt.",
          color: "green",
        });
        context.reloadUser();
      });
    };

    let generator = NavbarGenerator();
    let left = generator.addGroup();
    let right = generator.addGroup();

    if (context.user === null) {
      left.addLink("Messdienerplan", "/plan");
    } else {
      let plan = left.addDropdown("Messdienerplan").addLink("Plan ansehen", "/plan").addLink("Abmelden", "/plan/signout");
      if (hp("plan-edit")) {
        plan.addDivider().addLink("Bearbeiten", "/plan/edit").addLink("Plan senden", "/plan/send").addLink("Personen", "/plan/persons");
      }
    }

    if (hp("tba")) {
      left
        .addDropdown("Tannenbaumaktion")
        .addLink("Anmeldung", "/tba")
        .addDivider()
        .addLink("Adressliste", "/tba/list")
        .addLink("Routenplaner", "/tba/routes")
        .addLink("Einstellungen", "/tba/settings")
        .addLink("Exporte", "/tba/exports");
    } else if (props.tbaVisiblity) {
      left.addLink("Tannenbaumaktion", "/tba");
    }

    left.addLink("Aktionen", "/event");

    if (context.user !== null && hp("mail")) {
      left.addLink("Mails", "/mail");
    }

    if (hp("users")) {
      left.addLink("Benutzer", "/users");
    } else if (context.user !== null) {
      left.addLink("Kontaktliste", "/contact-list");
    }

    if (hp("permissions-edit")) {
      left.addLink("Berechtigungen", "/permissions");
    }

    if (hp("fine-register")) {
      left.addLink("Strafkasse", "/fine-register");
    }

    if (context.user === null) {
      right.addButton("Login", setLoginDrawer.open);
    } else {
      right.addLink("Profil", "/profile");
      right.addButton("Logout", logout);
    }

    return generator;
  }, [context, props.tbaVisiblity, setLoginDrawer.open]);

  return (
    <>
      <Drawer opened={loginDrawer} onClose={setLoginDrawer.close} title="Login" padding="xl" size="xl">
        <LoginDrawer close={setLoginDrawer.close} />
      </Drawer>

      <Navbar breakpoint="md" definition={navbarGenerator.getDefinition()} hideMobileNavigation={loginDrawer} />
    </>
  );
};

export default ComponentNavbar;

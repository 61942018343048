import { Button, Card, Center, Grid, Loader, PasswordInput, Stack, Text, Title } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Check, X } from "tabler-icons-react";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";

type PARAMS = {
  token: string;
};

type PasswordRequirementsType = {
  length: boolean;
  lowerCase: boolean;
  upperCase: boolean;
  digit: boolean;
  identical: boolean;
};

const PageLoginReset: React.FC<{}> = () => {
  const params = useParams<PARAMS>();

  const [tokenValid, setTokenValid] = useState<null | boolean | "expired">(null);
  const [resetDone, setResetDone] = useState<null | boolean | "expired">(null);
  const [name, setName] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");

  useEffect(() => {
    ajax
      .get("/login/resetcheck/" + params.token)
      .on(200, (res: RequestTypes.LoginResetcheck) => {
        setTokenValid(true);
        setName(res.givenname + " " + res.surname);
      })
      .on(400, () => {
        setTokenValid(false);
      })
      .on(410, () => {
        setTokenValid("expired");
      });
  }, [params]);

  const passwordRequirements = useMemo<PasswordRequirementsType>(
    () => ({
      length: password.length >= 8,
      lowerCase: /[a-z]/.test(password),
      upperCase: /[A-Z]/.test(password),
      digit: /[0-9]/.test(password),
      identical: password !== "" && password === password2,
    }),
    [password, password2]
  );

  const reset: React.FormEventHandler = (e) => {
    e.preventDefault();

    let data = {
      token: params.token,
      password: password,
    };
    ajax
      .post("/login/reset", data)
      .on(204, () => {
        setResetDone(true);
      })
      .on(400, (res) => {
        setResetDone(false);
      })
      .on(410, (res) => {
        setResetDone("expired");
      });
  };

  return (
    <Grid justify="center">
      <Grid.Col xs={12} md={8} xl={4}>
        {resetDone === null && (
          <>
            {tokenValid === null && (
              <Center>
                <Loader />
              </Center>
            )}
            {tokenValid === true && (
              <Card shadow="xl">
                <Title order={4}>Passwort setzen</Title>
                <Text mb="md">{name}</Text>

                <Text mb="md">
                  Bitte wähle hier ein neues Passwort. Dieses muss mindestens acht Stellen lang sein sowie Klein- und Großbuchstaben sowie eine Zahl
                  beinhalten.
                </Text>

                <form onSubmit={reset}>
                  <Stack>
                    <PasswordInput
                      label="Dein neues Passwort"
                      placeholder="Bitte eingeben"
                      value={password}
                      onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                    <PasswordInput
                      label="Wiederholen"
                      placeholder="Bitte wiederholen"
                      value={password2}
                      onChange={(e) => setPassword2(e.currentTarget.value)}
                    />

                    <div className="mb-3 ps-3">
                      {passwordRequirements.length ? <Check /> : <X />} Mindestens acht Zeichen
                      <br />
                      {passwordRequirements.lowerCase ? <Check /> : <X />} Kleinbuchstaben
                      <br />
                      {passwordRequirements.upperCase ? <Check /> : <X />} Großbuchstaben
                      <br />
                      {passwordRequirements.digit ? <Check /> : <X />} Zahlen
                      <br />
                      {passwordRequirements.identical ? <Check /> : <X />} Identisch
                      <br />
                    </div>

                    <Button type="submit" variant="outline" fullWidth disabled={Object.values(passwordRequirements).some((i) => !i)}>
                      Passwort zurücksetzen
                    </Button>
                  </Stack>
                </form>
              </Card>
            )}
            {tokenValid === false && (
              <Card shadow="xl">
                <Title order={4}>Token unbekannt</Title>
                <Text>Der Token, den du verwendest, ist nicht bekannt. Bitte öffne den Link aus der Mail, die wir dir zugeschickt haben.</Text>
              </Card>
            )}
            {tokenValid === "expired" && (
              <Card shadow="xl">
                <Title order={4}>Token abgelaufen</Title>
                <Text>
                  Der Token, den du verwendest, ist bereits abgelaufen. Bitte gehe zurück zum{" "}
                  <Text variant="link" component={Link} to="/login">
                    Login
                  </Text>{" "}
                  und lasse dir eine neue Mail zum Zurücksetzen deines Passworts zusenden.
                </Text>
              </Card>
            )}
          </>
        )}
        {resetDone === true && (
          <Card shadow="xl">
            <Title order={4}>Passwort zurückgesetzt</Title>
            <Text>
              Das Passwort wurde zurückgesetzt. Gehe jetzt zum{" "}
              <Text variant="link" component={Link} to="/login">
                Login
              </Text>{" "}
              und melde dich mit diesem Passwort an.
            </Text>
          </Card>
        )}
        {resetDone === false && (
          <Card shadow="xl">
            <Title order={4}>Fehler</Title>
            <Text>
              Durch einen unbekannten Fehler konnte das Passwort nicht zurückgesetzt werden. Bitte versuche es zu einem späteren Zeitpunkt erneut oder
              melde dich{" "}
              <Text variant="link" component="a" href="mailto:info@md-st-joef.de">
                per Mail
              </Text>{" "}
              bei uns.
            </Text>
          </Card>
        )}
        {resetDone === "expired" && (
          <Card shadow="xl">
            <Title order={4}>Token abgelaufen</Title>
            <Text>
              Der Token, den du verwendest, ist bereits abgelaufen. Bitte gehe zurück zum{" "}
              <Text variant="link" component={Link} to="/login">
                Login
              </Text>{" "}
              und lasse dir eine neue Mail zum Zurücksetzen deines Passworts zusenden. Das Passwort muss spätestens zu dem in der Mail angegebenen
              Zeitpunkt geändert werden.
            </Text>
          </Card>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default PageLoginReset;

import {
  ActionIcon,
  Alert,
  Button,
  Card,
  Center,
  Group,
  List,
  Loader,
  Stack,
  Switch,
  Table,
  Text,
  Title,
  TypographyStylesProvider,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ExclamationMark, Send } from "tabler-icons-react";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";
import { MailType, RecipientListItem } from "./service";

type Props = {
  mails: MailType[];
  selection: MailType | null;
  select: (mail: MailType | null) => void;
  reload: () => void;
  resend: () => void;
};

const PageMailListMobile: React.FC<Props> = (props) => {
  const [showHidden, setShowHidden] = useDisclosure(false);

  const hasFailedRecipients = useMemo<boolean>(() => {
    if (props.selection === null) return false;

    return props.selection.recipients.some((r) => r.status === "failed");
  }, [props.selection]);

  if (props.selection !== null) {
    return (
      <Stack>
        <Button type="button" color="teal" variant="outline" mb="md" onClick={() => props.select(null)}>
          Zurück zur Übersicht
        </Button>

        <Table>
          <tbody>
            <tr>
              <td>Autor</td>
              <td>{props.selection.author}</td>
            </tr>
            <tr>
              <td>Datum und Zeit</td>
              <td>{dayjs(props.selection.timestamp).format("DD.MM.YYYY, HH:mm") + " Uhr"}</td>
            </tr>
          </tbody>
        </Table>

        <Card shadow="xl">
          <Title order={4} mb="md">
            {props.selection.subject}
          </Title>

          <TypographyStylesProvider>
            <div dangerouslySetInnerHTML={{ __html: props.selection.message }} />
          </TypographyStylesProvider>

          <Stack spacing="xs" align="flex-end">
            {props.selection.attachments.map((a) => (
              <Text variant="link" component="a" href={`${process.env.REACT_APP_API_BASE}/file/${a.hash}?name=${a.name}`} download={a.name}>
                Anhang: {a.name}
                <br />
              </Text>
            ))}
          </Stack>
        </Card>

        {hasFailedRecipients && (
          <Alert title="Mails fehlgeschlagen" icon={<ExclamationMark />} color="red" mb="md">
            <Group>
              <Text sx={{ flex: "1" }}>
                Nicht alle Mails konnten erfolgreich versendet werden. Bitte prüfe, ob für die fehlgeschlagenen Mails ein neuer Sendeversuch erfolgen
                soll.
              </Text>

              <ActionIcon size="lg" onClick={props.resend}>
                <Send size={26} />
              </ActionIcon>
            </Group>
          </Alert>
        )}

        <List spacing={2}>
          {props.selection.recipients.map((r) => (
            <RecipientListItem key={r.recipient} recipient={r.recipient} givenname={r.givenname} surname={r.surname} status={r.status} />
          ))}
        </List>
      </Stack>
    );
  }

  return (
    <Stack>
      <Button type="button" color="teal" variant="outline" fullWidth component={Link} to="/mail/new">
        Mail verfassen
      </Button>
      <Switch label="Auch versteckte Mails anzeigen" checked={showHidden} onChange={setShowHidden.toggle} />

      <Table highlightOnHover>
        <thead>
          <tr>
            <th style={{ width: "20%" }}>Autor</th>
            <th style={{ width: "80%" }}>Betreff</th>
          </tr>
        </thead>
        <tbody>
          {props.mails
            .filter((mail) => showHidden || mail.show)
            .map((mail) => (
              <tr key={mail.id} style={{ cursor: "pointer" }} onClick={() => props.select(mail)}>
                <td>{mail.author}</td>
                <td>{mail.subject}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Stack>
  );
};

export default PageMailListMobile;

import { Button, ButtonProps } from "@mantine/core";
import axios from "axios";
import React, { useRef } from "react";

export type UploadFile = {
  name: string;
  type: string;
  size: number;
  content: string;
};

type FileUploadProps = {
  buttonProps?: ButtonProps;
  multiple?: boolean;
  onUploadStarted?: (name: string, type: string, size: number) => void;
  onUploadSuccess?: (name: string, type: string, size: number, hash: string) => void;
  children?: JSX.Element | string;
};

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const nativeUploadButton = useRef<HTMLInputElement>(null);

  const click = (): void => {
    if (nativeUploadButton.current === null) return;
    nativeUploadButton.current.click();
  };

  const select = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.currentTarget.files === null) return;

    // get the content of each file
    let promises: Promise<UploadFile>[] = Array.from(e.currentTarget.files).map((f) => {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(f);

        reader.onload = () => {
          if (props.onUploadStarted) {
            props.onUploadStarted(f.name, f.type, f.size);
          }

          resolve({
            name: f.name,
            type: f.type,
            size: f.size,
            content: reader.result?.toString() ?? "",
          });
        };
      });
    });

    Promise.all(promises).then((files) => {
      let data = {
        attachments: files.map((f) => f.content),
      };
      let options = {
        withCredentials: true,
        headers: {
          "Content-Type": "mutlipart/form-data",
        },
      };

      axios.post(process.env.REACT_APP_API_BASE + "/file", data, options).then((res) => {
        if (props.onUploadSuccess) {
          for (let i = 0; i < files.length; i++) {
            props.onUploadSuccess(files[i].name, files[i].type, files[i].size, res.data[i]);
          }
        }
      });
    });
  };

  return (
    <>
      <input type="file" multiple={props.multiple} ref={nativeUploadButton} style={{ display: "none" }} onChange={select} />
      <Button {...props.buttonProps} onClick={click}>
        {props.children}
      </Button>
    </>
  );
};

export default FileUpload;

export const fileSizeToText = (size: number): string => {
  if (size < 1024) return size + " B";
  if (size < 1024 ** 2) return (size / 1024).toLocaleString("de-DE", { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + " KB";
  if (size < 1024 ** 3) return (size / 1024 ** 2).toLocaleString("de-DE", { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + " MB";
  return (size / 1024 ** 3).toLocaleString("de-DE", { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + " GB";
};

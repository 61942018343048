import {
  ActionIcon,
  Alert,
  Button,
  Chip,
  Grid,
  Group,
  Input,
  Select,
  SelectItem,
  SimpleGrid,
  Stack,
  Switch,
  Table,
  Tabs,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import "dayjs/locale/de";
import React, { useMemo, useState } from "react";
import { GenderFemale, GenderMale, GenderTransgender, Trash } from "tabler-icons-react";
import ajax from "../../service/ajax";
import { EventPersonType, EventType } from "./list";

type Props = {
  event: Required<EventType>;
  persons: EventPersonType[];
  reload: () => void;
};

export const EditParticipantsTab: React.FC<Props> = (props) => {
  const SEX_OPTIONS = [
    { id: "female", label: "weiblich" },
    { id: "male", label: "männlich" },
    { id: "other", label: "divers" },
  ];

  const participantsInternal = useMemo<EventPersonType[]>(
    () => props.persons.filter((p) => props.event.participants.internal.includes(p.id)),
    [props.event, props.persons]
  );

  return (
    <>
      <Grid>
        <Grid.Col xs={12} lg={3}>
          <Table>
            <tbody>
              <tr>
                <td>
                  <b>Gesamtzahl</b>
                </td>
                <td>{participantsInternal.length + props.event.participants.external.length}</td>
              </tr>

              <tr>
                <td style={{ paddingLeft: "15px" }}>
                  <b>davon Kinder</b>
                </td>
                <td>
                  {participantsInternal.filter((p) => p.age < 16).length} + {props.event.participants.external.length}
                </td>
              </tr>
              {SEX_OPTIONS.map(({ id, label }) => (
                <tr>
                  <td style={{ paddingLeft: "30px" }}>
                    <b>{label}</b>
                  </td>
                  <td>
                    {participantsInternal.filter((p) => p.age < 16 && p.sex === id).length} +{" "}
                    {props.event.participants.external.filter((p) => p.sex === id).length}
                  </td>
                </tr>
              ))}

              <tr>
                <td style={{ paddingLeft: "15px" }}>
                  <b>davon Leiter</b>
                </td>
                <td>{participantsInternal.filter((p) => p.age >= 16).length}</td>
              </tr>
              {SEX_OPTIONS.map(({ id, label }) => (
                <tr>
                  <td style={{ paddingLeft: "30px" }}>
                    <b>{label}</b>
                  </td>
                  <td>{participantsInternal.filter((p) => p.age >= 16 && p.sex === id).length}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Grid.Col>

        <Grid.Col xs={12} lg={9}>
          <Tabs defaultValue="internal">
            <Tabs.List>
              <Tabs.Tab value="internal">Intern</Tabs.Tab>
              <Tabs.Tab value="external">Extern</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="internal" pt="xs">
              <Internal {...props} />
            </Tabs.Panel>

            <Tabs.Panel value="external" pt="xs">
              <External {...props} />
            </Tabs.Panel>
          </Tabs>
        </Grid.Col>
      </Grid>
    </>
  );
};

const Internal: React.FC<Props> = (props) => {
  const [query, setQuery] = useState<string>("");
  const [participantIds, setParticipantIds] = useState<string[]>(
    props.event.participants.internal.filter((id) => props.persons.some((p) => id === p.id))
  );

  const sortedPersons = useMemo<EventPersonType[]>(
    () =>
      props.persons.sort((a, b) => {
        if (a.surname === b.surname) return a.givenname.localeCompare(b.givenname);
        return a.surname.localeCompare(b.surname);
      }),
    [props.persons]
  );

  const filteredParticipants = useMemo<EventPersonType[]>(() => {
    let queries = query.toLowerCase().split(" ");
    return sortedPersons.filter((p) =>
      queries.every(
        (q) => p.givenname.toLowerCase().includes(q) || p.surname.toLowerCase().includes(q) || (q === ":in" && participantIds.includes(p.id))
      )
    );
  }, [sortedPersons, query, participantIds]);

  const changed = useMemo<boolean>(() => {
    return (
      props.event.participants === undefined ||
      props.event.participants.internal.length !== participantIds.length ||
      participantIds.some((id) => !props.event.participants.internal.includes(id))
    );
  }, [props.event.participants, participantIds]);

  const switchParticipant = (id: string): void => {
    setParticipantIds((state) => {
      if (state.includes(id)) return state.filter((i) => i !== id);
      return [...participantIds, id];
    });
  };

  const save = (): void => {
    ajax
      .post(`/events/${props.event.id}/participants/internal`, { participants: participantIds })
      .on(204, () => {
        showNotification({
          message: "Die Teilnehmer:innen wurde erfolgreich gespeichert.",
          color: "green",
        });

        props.reload();
      })
      .on(400, () => {
        showNotification({
          message: "Die Teilnehmer:innen konnten aufgrund eines unbekannten Fehlers nicht gespeichert werden.",
          color: "red",
        });
      });
  };

  return (
    <>
      <Stack>
        <Group>
          <TextInput
            type="search"
            aria-label="Suche nach Vorname, Nachname und :in für nur angemeldete"
            placeholder="Suche nach Vorname, Nachname und :in für nur angemeldete"
            value={query}
            onChange={(e) => setQuery(e.currentTarget.value)}
            sx={{ flex: 1 }}
          />

          <Button type="submit" variant="outline" disabled={!changed} onClick={save}>
            Speichern
          </Button>
        </Group>

        <SimpleGrid
          breakpoints={[
            { minWidth: "xs", cols: 2 },
            { minWidth: "sm", cols: 2 },
            { minWidth: "md", cols: 3 },
          ]}
        >
          {filteredParticipants.map((p) => (
            <Switch
              key={p.id}
              label={`${p.givenname} ${p.surname}`}
              checked={participantIds.includes(p.id)}
              onChange={() => switchParticipant(p.id)}
            />
          ))}
        </SimpleGrid>
      </Stack>
    </>
  );
};

type AddExternalForm = {
  name: string;
  sex: "male" | "female" | "other";
  contact: string | null;
};

const External: React.FC<Props> = (props) => {
  type ExternalParticipant = {
    id: string;
    name: string;
    sex: "male" | "female" | "other";
    contact: string | null;
  };

  const modals = useModals();

  const SEX_TEXTS = {
    male: <GenderMale size={16} color="blue" />,
    female: <GenderFemale size={16} color="purple" />,
    other: <GenderTransgender size={16} />,
  };

  const addForm = useForm<AddExternalForm>({
    initialValues: {
      name: "",
      sex: "other",
      contact: null,
    },
    validate: {
      name: (val) => (val === "" ? "Der Name darf nicht leer sein." : null),
    },
  });

  const contactsMap = useMemo<Map<string | null, string>>(
    () => new Map(props.persons.map((p) => [p.id, `${p.givenname} ${p.surname}`])),
    [props.persons]
  );

  const contactsSelector = useMemo<SelectItem[]>(
    () =>
      props.persons
        .sort((a, b) => {
          if (a.surname === b.surname) return a.givenname.localeCompare(b.givenname);
          return a.surname.localeCompare(b.surname);
        })
        .map((p) => ({
          value: p.id,
          label: `${p.givenname} ${p.surname}`,
        })),
    [props.persons]
  );

  const save = (e: React.FormEvent): void => {
    e.preventDefault();

    if (!addForm.validate().hasErrors) {
      ajax.post(`/events/${props.event.id}/participants/external`, addForm.values).on(204, () => {
        props.reload();
        addForm.setValues({
          name: "",
          sex: "other",
          contact: null,
        });

        showNotification({
          message: "Der Teilnehmende wurde erfolgreich hinzugefügt.",
          color: "green",
        });
      });
    }
  };

  const remove = (p: ExternalParticipant): void => {
    modals.openConfirmModal({
      title: "Externen Teilehmenden abmelden",
      children: (
        <Text>
          Bist Du Dir sicher, dass du <i>{p.name}</i> von der Aktion abmelden möchtest?
        </Text>
      ),
      labels: {
        cancel: "Nein, abbrechen",
        confirm: "Ja, abmelden",
      },
      cancelProps: {
        variant: "outline",
        color: "gray",
      },
      confirmProps: {
        variant: "outline",
        color: "red",
      },
      onConfirm: () => {
        ajax.delete(`/events/${props.event.id}/participants/external/${p.id}`).on(204, () => {
          props.reload();
          showNotification({
            message: "Der externe Teilehmende wurde erfolgreich abgemeldet.",
            color: "green",
          });
        });
      },
    });
  };

  return (
    <>
      <Grid>
        <Grid.Col xs={12} md={8}>
          {props.event.participants.external && props.event.participants.external.length === 0 && (
            <Alert variant="filled" color="indigo">
              Aktuell gibt es keine externen Teilnehmenden
            </Alert>
          )}
          {props.event.participants.external && props.event.participants.external.length > 0 && (
            <Table>
              <thead>
                <tr>
                  <th style={{ width: "40%" }}>Name</th>
                  <th style={{ width: "15%" }}>Geschlecht</th>
                  <th style={{ width: "40%" }}>Kontakt</th>
                  <th style={{ width: "5%" }}></th>
                </tr>
              </thead>
              <tbody>
                {props.event.participants.external.map((p) => (
                  <tr key={p.id} className="on-hover-visible-child">
                    <td>{p.name}</td>
                    <td>{SEX_TEXTS[p.sex]}</td>
                    <td>{contactsMap.get(p.contact) ?? ""}</td>
                    <td>
                      <ActionIcon color="red" onClick={() => remove(p)}>
                        <Trash size={18} />
                      </ActionIcon>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Grid.Col>

        <Grid.Col xs={12} md={4}>
          <form onSubmit={save}>
            <Stack>
              <TextInput label="Name" placeholder="Name" {...addForm.getInputProps("name")} />

              <Select
                label="Kontaktperson"
                placeholder="Bitte wählen"
                clearable
                searchable
                data={contactsSelector}
                {...addForm.getInputProps("contact")}
              />

              <Input.Wrapper label="Geschlecht" error={addForm.errors.sex}>
                <Chip.Group {...addForm.getInputProps("sex")}>
                  <Chip value="female">weiblich</Chip>
                  <Chip value="male">männlich</Chip>
                  <Chip value="other">divers</Chip>
                </Chip.Group>
              </Input.Wrapper>

              <Button type="submit">Teilnehmenden hinzufügen</Button>
            </Stack>
          </form>
        </Grid.Col>
      </Grid>
    </>
  );
};

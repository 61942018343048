import { Grid, Text, Title } from "@mantine/core";

const PageLegal: React.FC<{}> = () => {
  return (
    <Grid>
      <Grid.Col sm={12} md={6}>
        <Title order={3}>Impressum</Title>

        <Text>
          Diese Website wird betreut von der Leiterrunde der Messdienerschaft St. Mariae Geburt Kempen, erreichbar per Mail an{" "}
          <Text variant="link" component="a" href="mailto:info@md-st-josef.de">
            info@md-st-josef.de
          </Text>
          .
        </Text>
        <Text>
          Für weitere rechtliche Informationen besuche bitte das{" "}
          <Text variant="link" component="a" href="https://gdg-kempen-tönisvorst.de/impressum/">
            Impressum der Gemeinschaft der Gemeinden Kempen/Tönisvorst
          </Text>
          , wo Du weitere Informationen findest.
        </Text>
        <Text>
          Trotz sorgfältiger Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
          ausschliesslich deren Betreiber verantwortlich.
        </Text>
      </Grid.Col>

      <Grid.Col sm={12} md={6}>
        <Title order={3}>Datenschutz</Title>
        <Text>
          Nachfolgend findest Du ein paar kurze, zusammengefasste Informationen zur Verarbeitung von Daten auf unserer Website. Ausführliche und
          rechtlich relevante Informationen zum Datenschutz findest Du in den{" "}
          <Text variant="link" component="a" href="https://www.bistum-aachen.de/datenschutzbestimmungen/">
            Datenschutzbestimmungen des Bistums Aachen
          </Text>
          .
        </Text>

        <Title order={4}>Welche Daten werden über mich gespeichert?</Title>
        <Text>Zum aktuellen Zeitpunkt speichern wir folgende Daten über Dich:</Text>
        <ul>
          <li>Vor- und Nachname</li>
          <li>E-Mail-Adresse</li>
          <li>Wohnadresse</li>
          <li>Telefon</li>
          <li>Geburtsdatum</li>
          <li>Geschlecht</li>
        </ul>
        <Text>
          Solltest Du dein Profil nicht einsehen können, weil Du keine Zugangsdaten (Benutzername oder E-Mail und Passwort) hast, so speichern wir
          dort auch keine Daten über dich. Wenn Du sichergehen willst, dass wir keine Daten über dich gespeichert haben, nutze bitte die im Impressum
          angegebenen Kontaktdaten.
        </Text>
        <Text>
          Sobald Du uns weitere Daten über Dich übermittelst, zum Beispiel durch das Scheiben einer Mail oder der Anmeldung zur Tannenbaumaktion,
          werden diese Daten ebenfalls gespeichert. Dabei werden sämtliche Daten gespeichert, die Du in den entsprechenden Formularfeldern angibst.
        </Text>

        <Title order={4}>Wozu werden die Daten gespeichert?</Title>
        <Text>
          Die allgemeinen Profildaten werden für unsere Messdienerarbeit gespeichert, damit wir immer Deine Kontaktdaten haben, sollte währen unseren
          Aktionen einmal etwas Unvorhergesehenes passieren. Über deine E-Mail-Adresse treten wir teilen wir Dir außerdem in unregelmäßigen Abständen
          neue Infos wie Anmeldungen oder Messdienerpläne mit.
        </Text>
        <Text>Die Daten aus dem Kontaktformular nutzen wir ausschließlich zur Erfüllung des Anliegens, mit dem Du uns kontaktierst.</Text>
        <Text>
          Die Daten aus dem Anmeldeformular für die Tannenbaumaktion werden zur Durchführung selbiger genutzt. Weiterhin nutzen wir, sofern angegeben,
          Telefonnummer und E-Mail-Adresse für eventuelle Rückfragen bei Deiner Anmeldung sowie die E-Mail-Adresse zu Marktingzwecken.
        </Text>

        <Title order={4}>Was kann ich tun, damit meine Daten gelöscht werden?</Title>
        <Text>Bitte trete über die im Impressum angegebenen Kontaktdaten mit uns in Kontakt, damit wir Deine Daten löschen können.</Text>

        <Title order={4}>Findet ein Tracking meiner Aktivitäten statt?</Title>
        <Text>
          Wir setzen kein Tracking ein. Trotzdem können Spuren deines Besuches auf unserer Website auf unseren Servern in sogenannten Log-Dateien
          gespeichert werden. Diese enthalten zum Beispiel wichtige Informationen, sollte es bei der Benutzung zu Fehlern kommen. Diese Log-Dateien
          teilen wir jedoch nicht mit außenstehenden Personen.
        </Text>
        <Text>
          Weiterhin setzen wir Cookies ein, um Dich nach deinem Login zu identifizieren. Diese Datei enthält nur deine Benutzer-Identifikations-Nummer
          und einen Schlüssel, um die ID zu verifizieren und kann nicht zum Tracking verwendet werden.
        </Text>
      </Grid.Col>
    </Grid>
  );
};

export default PageLegal;

import { Container, MantineProvider, MantineThemeOverride } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider, showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import React, { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ComponentNavbar from "./navbar/navbar";
import PageDefaults404 from "./pages/defaults/404";
import PageLegal from "./pages/defaults/legal";
import PageDefaultsMain from "./pages/defaults/main";
import PageEventList from "./pages/event/list";
import PageFineRegister from "./pages/fine-register/fine-register";
import PageContactList from "./pages/list/list";
import PageLoginReset from "./pages/login/reset";
import PageMailList from "./pages/mails/list";
import PageMailNew from "./pages/mails/new";
import PagePlanCancel from "./pages/plan/cancel";
import PagePlanEdit from "./pages/plan/edit/index";
import PagePlanPersons from "./pages/plan/persons";
import PagePlanSend from "./pages/plan/send";
import PagePlanSignout from "./pages/plan/signout";
import PagePlanView from "./pages/plan/view";
import PageProfile from "./pages/profile/profile";
import PageSharePersonal from "./pages/profile/sharepersonal";
import PageTbaExport from "./pages/tba/export";
import PageTbaList from "./pages/tba/list";
import PageTbaRegister from "./pages/tba/register";
import PageTbaRoutes from "./pages/tba/routes";
import PageTbaSettings from "./pages/tba/settings";
import PageUserList from "./pages/user/list";
import PageUserPermissions from "./pages/user/permissions/main";
import { Database, RequestTypes } from "./request-types";
import ajax from "./service/ajax";
import "./styles.scss";

type User = Database.User & {
  permissions: string[];
};

// works for all pages
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekdaysShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
});

type AppContectType = {
  user: User | null;
  reloadUser: () => void;
};

type RouteType = {
  path: string;
  permissions: null | string[];
  component: JSX.Element;
};

export const AppContext = React.createContext<AppContectType>({
  user: null,
  reloadUser: () => {},
});

const theme: MantineThemeOverride = {
  colorScheme: "dark",
  fontFamily:
    'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
};

const App: React.FC<{}> = () => {
  const [user, setUser] = useState<User | null>(null);
  const [tbaVisibility, setTbaVisibility] = useState<boolean>(false);

  const grantedRoutes = useMemo<RouteType[]>(() => {
    const routes: RouteType[] = [
      {
        path: "/",
        permissions: null,
        component: <PageDefaultsMain />,
      },
      {
        path: "/legal",
        permissions: null,
        component: <PageLegal />,
      },
      {
        path: "/reset/:token",
        permissions: null,
        component: <PageLoginReset />,
      },
      {
        path: "/fine-register",
        permissions: ["fine-register"],
        component: <PageFineRegister />,
      },
      {
        path: "/event",
        permissions: null,
        component: <PageEventList />,
      },
      {
        path: "/event/:id",
        permissions: ["events-edit"],
        component: <PageEventList />,
      },
      {
        path: "/mail",
        permissions: ["mail"],
        component: <PageMailList />,
      },
      {
        path: "/mail/new",
        permissions: ["mail"],
        component: <PageMailNew />,
      },
      {
        path: "/plan",
        permissions: null,
        component: <PagePlanView />,
      },
      {
        path: "/plan/signout",
        permissions: [],
        component: <PagePlanSignout />,
      },
      {
        path: "/plan/cancel/:token",
        permissions: null,
        component: <PagePlanCancel />,
      },
      {
        path: "/plan/edit",
        permissions: ["plan-edit"],
        component: <PagePlanEdit />,
      },
      {
        path: "/plan/send",
        permissions: ["plan-edit", "mail"],
        component: <PagePlanSend />,
      },
      {
        path: "/plan/persons",
        permissions: ["plan-edit"],
        component: <PagePlanPersons />,
      },
      {
        path: "/tba",
        permissions: null,
        component: <PageTbaRegister />,
      },
      {
        path: "/tba/list",
        permissions: ["tba"],
        component: <PageTbaList />,
      },
      {
        path: "/tba/routes",
        permissions: ["tba"],
        component: <PageTbaRoutes />,
      },
      {
        path: "/tba/settings",
        permissions: ["tba"],
        component: <PageTbaSettings />,
      },
      {
        path: "/tba/exports",
        permissions: ["tba"],
        component: <PageTbaExport />,
      },
      {
        path: "/users",
        permissions: ["users"],
        component: <PageUserList />,
      },
      {
        path: "/users/:id",
        permissions: ["users"],
        component: <PageUserList />,
      },
      {
        path: "/permissions",
        permissions: ["permissions-edit"],
        component: <PageUserPermissions />,
      },
      {
        path: "/profile",
        permissions: [],
        component: <PageProfile />,
      },
      {
        path: "/profile/sharepersonal/:id/:token",
        permissions: null,
        component: <PageSharePersonal />,
      },
      {
        path: "/contact-list",
        permissions: [],
        component: <PageContactList />,
      },
    ];

    return routes.filter((route) => {
      if (route.permissions === null) return true;
      if (user === null) return false;

      if (route.permissions.length === 0) return true;
      return route.permissions.every((perm) => user.permissions.includes(perm));
    });
  }, [user]);

  // load user data on first load
  const loadUser = (): void => {
    ajax
      .get("/")
      .on(200, (res: RequestTypes.Base) => {
        if (res.login !== false) {
          setUser(res.login);
        } else {
          setUser(null);
        }

        setTbaVisibility(dayjs(+res.tbaVisibility.start * 1000).isBefore(dayjs()) && dayjs(+res.tbaVisibility.end * 1000).isAfter(dayjs()));
      })
      .on([400, 500], () => {
        showNotification({
          message: "Dein Login-Status konnte nicht geprüft werden. Bitte versuche es später erneut",
          color: "red",
        });
      });
  };

  const setDefaultAjaxListeners = (): void => {
    ajax.on(403, () => {
      showNotification({
        message: "Leider fehlt dir für diese Aktion die Berechtigung.",
        color: "red",
      });
    });

    ajax.on("*", () => {
      showNotification({
        message: "Die gewählte Aktion konnte nicht ausgefüht werden.",
        color: "red",
      });
    });
  };

  const context: AppContectType = {
    user: user,
    reloadUser: loadUser,
  };

  useEffect(setDefaultAjaxListeners, []);
  useEffect(loadUser, []);

  return (
    <React.StrictMode>
      <MantineProvider theme={theme} withGlobalStyles>
        <ModalsProvider>
          <NotificationsProvider>
            <AppContext.Provider value={context}>
              <Container size="xl">
                <BrowserRouter>
                  <ComponentNavbar tbaVisiblity={tbaVisibility} />

                  <Routes>
                    {grantedRoutes.map((route, i) => (
                      <Route key={i} path={route.path} element={route.component} />
                    ))}

                    <Route path="*" element={<PageDefaults404 />} />
                  </Routes>
                </BrowserRouter>
              </Container>
            </AppContext.Provider>
          </NotificationsProvider>
        </ModalsProvider>
      </MantineProvider>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App />);

import { Button, Grid, Tabs } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import RichTextEditor from "@mantine/rte";
import { useEffect, useState } from "react";
import "suneditor/dist/css/suneditor.min.css";
import { RequestTypes } from "../../../request-types";
import ajax from "../../../service/ajax";
import ComponentPlanEditExistingTab from "./existing";
import ComponentPlanHistoryTab from "./history";
import ComponentPlanEditNewTab from "./new";

const PagePlanEdit: React.FC<{}> = () => {
  const [intro, setIntro] = useState<string>("");

  const loadIntro = (): void => {
    ajax.get("/plan/intro").on(200, (res: RequestTypes.PlanIntro) => {
      setIntro(res.intro);
    });
  };

  const saveIntro = () => {
    ajax
      .post("/plan/intro", { intro: intro })
      .on(204, () => {
        showNotification({
          message: "Der Text wurde gespeichert.",
          color: "green",
        });
      })
      .on(400, () => {
        showNotification({
          message: "Der Text konnte nicht gespeichert werden.",
          color: "red",
        });
      });
  };

  useEffect(loadIntro, []);

  return (
    <>
      <Tabs mb="md" defaultValue="existing">
        <Tabs.List>
          <Tabs.Tab value="existing">Bestehende Gottesdienste bearbeiten</Tabs.Tab>
          <Tabs.Tab value="new">Neuen Plan erstellen</Tabs.Tab>
          <Tabs.Tab value="intro">Intro-Text</Tabs.Tab>
          <Tabs.Tab value="history">Historie</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="existing" pt="xs">
          <ComponentPlanEditExistingTab />
        </Tabs.Panel>

        <Tabs.Panel value="new" pt="xs">
          <ComponentPlanEditNewTab />
        </Tabs.Panel>

        <Tabs.Panel value="intro" pt="xs">
          <Grid justify="flex-end" mb="md">
            <Grid.Col xs={12} md={6} xl={4}>
              <Button role="button" variant="outline" fullWidth onClick={saveIntro}>
                Speichern
              </Button>
            </Grid.Col>
          </Grid>

          <RichTextEditor value={intro} onChange={setIntro} />
        </Tabs.Panel>

        <Tabs.Panel value="history" pt="xs">
          <ComponentPlanHistoryTab />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default PagePlanEdit;

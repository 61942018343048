import { Button, Center, Grid, Loader, Text, Title, TransferList, TransferListData, TransferListItem } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";

const PagePlanSend: React.FC<{}> = () => {
  const [persons, setPersons] = useState<TransferListData | null>(null);

  const loadPersons = (): void => {
    ajax.get("/plan/persons").on(200, (res: RequestTypes.PlanPersons) => {
      let personsTrue: TransferListItem[] = res
        .filter((p) => p.sendmails === "1")
        .map(
          (p): TransferListItem => ({
            value: p.id,
            label: `${p.surname}, ${p.givenname}`,
          })
        );

      let personsFalse: TransferListItem[] = res
        .filter((p) => p.sendmails === "0")
        .map(
          (p): TransferListItem => ({
            value: p.id,
            label: `${p.surname}, ${p.givenname}`,
          })
        );

      setPersons([personsTrue, personsFalse]);
    });
  };

  const saveSelectedPersons = (): void => {
    if (persons === null) {
      return;
    }

    let data = {
      persons: [
        ...persons[0].map((p) => ({
          id: p.value,
          sendmails: "1",
        })),
        ...persons[1].map((p) => ({
          id: p.value,
          sendmails: "0",
        })),
      ],
    };

    ajax.post("/plan/mail/recipients", data).on(204, () => {
      showNotification({
        message: "Die Empfänger wurden erfolgreich gespeichert.",
        color: "green",
      });
    });
  };

  const send = (): void => {
    ajax.post("/plan/mail/send", null).on(204, () => {
      showNotification({
        message: "Die Mail wurde erfolgreich gespeichert und wird in wenigen Minuten versendet.",
        color: "green",
      });
    });
  };

  useEffect(loadPersons, []);

  if (persons === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Grid>
      <Grid.Col xl={6}>
        <Title order={3}>Mail senden</Title>

        <Text mb="sm">Es wird der gesamt Plan ab heute an alle gewählten Personen gesendet.</Text>

        <Button type="button" color="blue" variant="outline" onClick={send} fullWidth>
          Plan senden
        </Button>
      </Grid.Col>

      <Grid.Col xl={6}>
        <Title order={3}>Empfänger wählen</Title>

        <TransferList
          value={persons}
          onChange={setPersons}
          searchPlaceholder="Suchen"
          nothingFound="Niemand gefunden"
          titles={["Erhält Mails", "Erhält keine Mails"]}
          breakpoint="md"
          showTransferAll={false}
          listHeight={500}
          mb="sm"
        />

        <Button type="button" color="blue" variant="outline" onClick={saveSelectedPersons} fullWidth>
          Speichern
        </Button>
      </Grid.Col>
    </Grid>
  );
};

export default PagePlanSend;

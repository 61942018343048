import { Grid, Text, Title } from "@mantine/core";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../..";

const PageDefaults404: React.FC<{}> = () => {
  const context = useContext(AppContext);

  return (
    <Grid>
      <Grid.Col sm={12} md={4}>
        <img src="https://md-st-josef.de/logo.jpg" alt="Buntes Bleiglasfenster in der Kirche St. Josef" width="100%" />
      </Grid.Col>

      <Grid.Col sm={12} md={8}>
        <Title order={2}>Herzlich Willkommen auf der Website der Messdienerschaft St. Mariae Geburt Kempen</Title>

        <Text>
          Leider konnten wir die Seite, die du aufgerufen hast, nicht finden. Bitte überprüfe, ob der Link, mit dem du auf diese Seite gekommen bist,
          wirklich korrekt ist.
        </Text>

        {context.user === null && (
          <p>
            Da du nicht eingeloggt bist, ist es auch möglich, dass du diese Seite erst sehen kannst, sobald du dich einloggst. Benutze dazu bitte den{" "}
            <Text variant="link" component={Link} to="/login">
              Login
            </Text>
            .
          </p>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default PageDefaults404;

import { ActionIcon, Button, Card, Grid, Group, Select, SelectItem, SimpleGrid, Stack, Switch, Table, Text, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useMemo, useState } from "react";
import { Search, Trash, Users } from "tabler-icons-react";
import { Database } from "../../../request-types";
import ajax from "../../../service/ajax";

dayjs.extend(customParseFormat);

type TabPermissionsProps = {
  permissions: Database.Permission[];
  assigns: Database.UserPermission[];
  users: Pick<Database.User, "id" | "givenname" | "surname">[];
  reload: () => void;
};

type AddForm = {
  name: string;
  code: string;
  clone: string | null;
};

export const TabPermissions: React.FC<TabPermissionsProps> = (props) => {
  const modals = useModals();

  const addForm = useForm<AddForm>({
    initialValues: {
      name: "",
      code: "",
      clone: null,
    },
    validate: {
      name: (val) => (val.length > 0 ? null : "Der Name darf nicht leer sein."),
      code: (val) => (val.length > 0 ? null : "Der Code darf nicht leer sein."),
    },
  });

  const cloneSelector = useMemo<SelectItem[]>(() => {
    return props.permissions.map((p) => ({
      value: p.code,
      label: p.name,
    }));
  }, [props.permissions]);

  const editUsers = (permission: Database.Permission): void => {
    let id = modals.openModal({
      title: `Benutzer mit Berechtigung ${permission.name}`,
      children: (
        <EditUsersModal
          permission={permission}
          assigns={props.assigns}
          users={props.users}
          reload={props.reload}
          close={() => modals.closeModal(id)}
        />
      ),
      size: "lg",
    });
  };

  const remove = (permission: Database.Permission): void => {
    modals.openConfirmModal({
      title: "Berechtigung löschen",
      children: (
        <Text>
          Bist Du Dir sicher, dass Du die Berechtigung <i>{permission.name}</i> löschen möchtest? Alle Benutzer, die die Berechtigung aktuell haben,
          werden sie verlieren.
        </Text>
      ),
      labels: {
        cancel: "Nein, abbrechen",
        confirm: "Ja, löschen",
      },
      cancelProps: {
        variant: "outline",
      },
      confirmProps: {
        color: "red",
        variant: "outline",
      },
      onConfirm: () => {
        ajax.delete(`/permissions/permission/${permission.code}`).on(204, () => {
          showNotification({
            message: "Die Berechtigung wurde erfolgreich gelöscht.",
            color: "green",
          });

          props.reload();
        });
      },
    });
  };

  const add: React.FormEventHandler = (e) => {
    e.preventDefault();

    if (!addForm.validate().hasErrors) {
      ajax
        .post("/permissions/permission", addForm.values)
        .on(204, () => {
          showNotification({
            message: "Die Berechtigung wurde angelegt.",
            color: "green",
          });

          props.reload();
          addForm.reset();
        })
        .on(409, () => {
          addForm.setFieldError("code", "Der Code ist bereits in Verwendung.");
        });
    }
  };

  return (
    <Grid>
      <Grid.Col xs={12} lg={8}>
        <Table>
          <thead>
            <tr>
              <th style={{ width: "39%" }}>Name</th>
              <th style={{ width: "39%" }}>Code</th>
              <th style={{ width: "14%" }}>Personen</th>
              <th style={{ width: "4%" }}></th>
              <th style={{ width: "4%" }}></th>
            </tr>
          </thead>
          <tbody>
            {props.permissions.map((permission) => (
              <tr key={permission.code} className="on-hover-visible-child">
                <td>{permission.name}</td>
                <td>{permission.code}</td>
                <td>{props.assigns.filter((a) => a.permission === permission.code).length}</td>
                <td>
                  <ActionIcon size="xs" className="hover-child" onClick={() => editUsers(permission)}>
                    <Users size={16} />
                  </ActionIcon>
                </td>
                <td>
                  <ActionIcon size="xs" color="red" className="hover-child" onClick={() => remove(permission)}>
                    <Trash size={16} />
                  </ActionIcon>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Grid.Col>

      <Grid.Col xs={12} lg={4}>
        <Card shadow="xl">
          <form onSubmit={add}>
            <Stack>
              <Title order={5}>Berechtigung hinzufügen</Title>

              <TextInput label="Name" placeholder="Name" {...addForm.getInputProps("name")} />
              <TextInput label="Code" placeholder="Code" {...addForm.getInputProps("code")} />

              <Select
                label="Benutzer kopieren von"
                placeholder="Nicht kopieren"
                data={cloneSelector}
                searchable
                clearable
                {...addForm.getInputProps("clone")}
              />

              <Group position="right">
                <Button type="submit" variant="outline">
                  Berechtigung anlegen
                </Button>
              </Group>
            </Stack>
          </form>
        </Card>
      </Grid.Col>
    </Grid>
  );
};

type EditUserModalProps = {
  permission: Database.Permission;
  assigns: Database.UserPermission[];
  users: Pick<Database.User, "id" | "givenname" | "surname">[];
  reload: () => void;
  close: () => void;
};

const EditUsersModal: React.FC<EditUserModalProps> = (props) => {
  const [query, setQuery] = useState<string>("");
  const [assigns, setAssigns] = useState<Set<string>>(
    new Set(props.assigns.filter((a) => a.permission === props.permission.code).map((a) => a.user))
  );

  const matching = useMemo<Pick<Database.User, "id" | "givenname" | "surname">[]>(() => {
    let queries = query.toLowerCase().split(" ");
    return props.users.filter((u) => queries.every((q) => u.givenname.toLowerCase().includes(q) || u.surname.toLowerCase().includes(q)));
  }, [props.users, query]);

  const save = (): void => {
    let data = {
      users: Array.from(assigns),
    };

    ajax.post(`/permissions/permission/${props.permission.code}`, data).on(204, () => {
      showNotification({
        message: "Die Berechtigung wurde für die gewählten Benutzer gesetzt.",
        color: "green",
      });

      props.reload();
      props.close();
    });
  };

  const changeAssign = (user: string, granted: boolean): void => {
    setAssigns((oldState) => {
      let copy = new Set(oldState);
      if (granted) {
        copy.add(user);
      } else {
        copy.delete(user);
      }
      return copy;
    });
  };

  return (
    <Stack>
      <TextInput type="search" placeholder="Suchen" icon={<Search size={20} />} value={query} onChange={(e) => setQuery(e.currentTarget.value)} />

      <SimpleGrid cols={2}>
        {matching.map((u) => (
          <Switch
            key={u.id}
            label={`${u.givenname} ${u.surname}`}
            checked={assigns.has(u.id)}
            onChange={(e) => changeAssign(u.id, e.currentTarget.checked)}
          />
        ))}
      </SimpleGrid>

      <Group position="right">
        <Button type="button" variant="outline" onClick={save}>
          Speichern
        </Button>
      </Group>
    </Stack>
  );
};

import { Center, Loader, Table, Text, TextInput } from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";
import { CircleOff, Search } from "tabler-icons-react";
import { Database, RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";

type User = Pick<Database.User, "id" | "surname" | "givenname" | "mail" | "phone" | "sharepersonal">;

const PageContactList: React.FC<{}> = () => {
  const [users, setUsers] = useState<User[] | null>(null);
  const [query, setQuery] = useState<string>("");

  const loadUsers = (): void => {
    ajax.get("/contact-list").on(200, (res: RequestTypes.Contactlist) => {
      setUsers(res);
    });
  };

  const filteredUsers: User[] | null = useMemo(() => {
    if (users === null) return null;

    let queries = query.split(" ").map((i) => i.trim().toLowerCase());
    return users.filter((user) => queries.every((q) => user.givenname.toLowerCase().includes(q) || user.surname.toLowerCase().includes(q)));
  }, [users, query]);

  useEffect(loadUsers, []);

  if (filteredUsers === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <Text mb="md">
        Im folgenden kannst du die E-Mail-Adressen und Telefonnummern von allen Messdiener:innen einsehen, die dies erlaubt haben. Damit kannst du zum
        Beispiel nach Vertretungen suchen, wenn du einmal bei einem Gottesdienst spontan nicht dienen kannst. Bitte beachte, dass du diese Daten nicht
        an Dritte weiterleiten darfst.
      </Text>

      <TextInput
        type="search"
        placeholder="Suche nach Vorname und Nachname"
        icon={<Search />}
        value={query}
        onChange={(e) => setQuery(e.currentTarget.value)}
        mb="md"
      />

      <Table highlightOnHover>
        <thead>
          <tr>
            <th style={{ width: "25%" }}>Vorname</th>
            <th style={{ width: "25%" }}>Nachname</th>
            <th style={{ width: "25%" }}>E-Mail</th>
            <th style={{ width: "25%" }}>Telefon</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.givenname}</td>
              <td>{user.surname}</td>
              <td>{user.sharepersonal === "1" ? user.mail : <CircleOff size="16" />}</td>
              <td>{user.sharepersonal === "1" ? user.phone : <CircleOff size="16" />}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default PageContactList;

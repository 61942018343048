import { Button, Card, Center, Grid, Group, Loader, Stack, Text, Title, UnstyledButton } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Trash } from "tabler-icons-react";
import { RequestTypes } from "../../../request-types";
import ajax from "../../../service/ajax";
import PersonModal, { SelectorPerson, SelectorUserDatePair } from "./personModal";
import ServiceModal from "./serviceModal";

type ServiceType = {
  id: string;
  time: string;
  kind: string;
  church: string;
  hint: string;
  assigns: {
    user: PersonType | undefined;
    canceled: boolean;
  }[];
};

type PersonType = {
  id: string;
  givenname: string;
  surname: string;
  size: "s" | "l" | null;
  group: string | null;
};

const ComponentPlanEditExistingTab: React.FC<{}> = () => {
  const modals = useModals();

  const [services, setServices] = useState<ServiceType[] | null>(null);
  const [personSelectorData, setPersonSelectorData] = useState<{
    persons: SelectorPerson[];
    assigns: SelectorUserDatePair[];
    signouts: SelectorUserDatePair[];
  }>({ persons: [], assigns: [], signouts: [] });

  const [editService, setEditService] = useState<ServiceType | null>(null);
  const [addAssign, setAddAssign] = useState<ServiceType | null>(null);

  const loadData = (): void => {
    ajax.get("/plan/services").on(200, (res: RequestTypes.PlanServices) => {
      let personsMap = new Map<string, PersonType>(res.persons.map((p: PersonType) => [p.id, p]));

      setServices(
        res.services.map((s) => ({
          id: s.id,
          time: s.time,
          kind: s.kind,
          church: s.church,
          hint: s.hint,
          assigns: res.assigns
            .filter((a) => a.service === s.id)
            .map((a) => ({
              user: personsMap.get(a.user),
              canceled: a.canceled === "1" || a.canceled === "2",
            })),
        }))
      );

      setPersonSelectorData({
        persons: res.persons.map((p: PersonType) => ({
          id: p.id,
          title: `${p.surname}, ${p.givenname}`,
          size: p.size,
        })),
        assigns: res.assigns.map((a) => ({
          user: a.user,
          date: dayjs(a.time).toDate(),
        })),
        signouts: res.signouts.map((s) => ({
          user: s.user,
          date: dayjs(s.date).toDate(),
        })),
      });
    });
  };

  const deleteAssign = (service: ServiceType, person: PersonType | undefined): void => {
    if (person === undefined) return;

    modals.openConfirmModal({
      title: "Einteilung löschen",
      children: (
        <Text>
          Bist du dir sicher, dass du die Einteilung von {person.givenname} {person.surname} am {dayjs(service.time).format("DD. MMMM YYYY")} löschen
          möchtest?
        </Text>
      ),
      labels: {
        cancel: "Nein, abbrechen",
        confirm: "Ja, löschen",
      },
      cancelProps: {
        variant: "outline",
      },
      confirmProps: {
        color: "red",
        variant: "outline",
      },
      onConfirm: () => {
        ajax.delete("/plan/assigns/" + service.id + "/" + person.id).on(204, () => {
          showNotification({
            message: `Die Einteilung von ${person.givenname} ${person.surname} wurde gelöscht.`,
            color: "green",
          });
          loadData();
        });
      },
    });
  };

  const execAddAssign = (service: string, person: string): void => {
    ajax.post("/plan/assigns", { user: person, service: service }).on(204, () => {
      loadData();

      showNotification({
        message: "Die Einteilung wurde hinzugefügt.",
        color: "green",
      });
    });
  };

  const deleteService = (service: ServiceType): void => {
    modals.openConfirmModal({
      title: "Einteilung löschen",
      children: (
        <Text>
          Bist du dir sicher, du den Gottesdienst am {dayjs(service.time).format("DD. MMMM YYYY")} in {service.church} löschen möchtest?
        </Text>
      ),
      labels: {
        cancel: "Nein, abbrechen",
        confirm: "Ja, löschen",
      },
      cancelProps: {
        variant: "outline",
      },
      confirmProps: {
        color: "red",
        variant: "outline",
      },
      onConfirm: () => {
        ajax.delete("/plan/services/" + service.id).on(204, () => {
          loadData();
          showNotification({
            message: "Der Gottesdienst wurde erfolgreich gelöscht.",
            color: "green",
          });
        });
      },
    });
  };

  const execEditService = (id: string | null, timestamp: Date, kind: string, church: string, hint: string): void => {
    if (id === null) return;

    let data = {
      time: dayjs(timestamp).format("YYYY-MM-DD HH:mm:00"),
      kind: kind,
      church: church,
      hint: hint,
    };

    ajax
      .post("/plan/services/" + id, data)
      .on(204, () => {
        showNotification({
          message: "Der Gottesdienst wurde erfolgreich bearbeitet.",
          color: "green",
        });

        setEditService(null);
        loadData();
      })
      .on(400, () => {
        showNotification({
          message: "Der Gottesdienst konnte nicht bearbeitet werden.",
          color: "red",
        });
      });
  };

  useEffect(loadData, []);

  if (services === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <ServiceModal lockDate show={editService !== null} onHide={() => setEditService(null)} service={editService} onSave={execEditService} />
      <PersonModal
        opened={addAssign !== null}
        onClose={() => setAddAssign(null)}
        service={addAssign}
        onSave={execAddAssign}
        {...personSelectorData}
      />

      {services.map((service) => (
        <Card key={service.id} shadow="xl" mb="md">
          <Grid justify="space-between">
            <Grid.Col sm={12} md={4}>
              <Title order={4}>{dayjs(service.time).format("ddd, DD.MM.YYYY, HH:mm [Uhr]")}</Title>
              <Text>
                {service.kind}, {service.church}
              </Text>

              {service.hint.length > 0 && <Text color="dimmed">{service.hint}</Text>}
            </Grid.Col>

            <Grid.Col sm={12} md={4}>
              <Stack spacing="xs">
                {service.assigns.map(
                  (assign) =>
                    assign.user === undefined || (
                      <UnstyledButton
                        key={assign.user.id}
                        type="button"
                        className="on-hover-visible-child"
                        onClick={() => deleteAssign(service, assign.user)}
                      >
                        <Group>
                          <span className="hover-child">
                            <Trash size={15} />
                          </span>
                          <span
                            style={
                              assign.canceled
                                ? {
                                    textDecoration: "line-through",
                                    textDecorationColor: "red",
                                  }
                                : {}
                            }
                          >
                            {assign.user.givenname} {assign.user.surname}
                          </span>
                        </Group>
                      </UnstyledButton>
                    )
                )}
              </Stack>
            </Grid.Col>

            <Grid.Col sm={12} md={4}>
              <Stack spacing="xs">
                <Button type="button" color="teal" variant="outline" onClick={() => setEditService(service)}>
                  Bearbeiten
                </Button>
                <Button type="button" color="teal" variant="outline" onClick={() => setAddAssign(service)}>
                  Messdiener:in hinzufügen
                </Button>
                <Button type="button" color="red" variant="outline" onClick={() => deleteService(service)}>
                  Löschen
                </Button>
              </Stack>
            </Grid.Col>
          </Grid>
        </Card>
      ))}
    </>
  );
};

export default ComponentPlanEditExistingTab;

import { Alert, Button, Card, Center, Checkbox, Divider, Grid, List, Loader, Stack, Text, Title, TypographyStylesProvider } from "@mantine/core";
import dayjs, { Dayjs } from "dayjs";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../..";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";

type ServiceType = {
  id: string;
  time: Dayjs;
  kind: string;
  church: string;
  hint: string;
  assigns: {
    user: string;
    givenname: string;
    surname: string;
    canceltoken?: string;
    canceled: string;
  }[];
};

const PagePlanView: React.FC<{}> = () => {
  const context = useContext(AppContext);

  const [services, setServices] = useState<ServiceType[] | null>(null);
  const [intro, setIntro] = useState<string>("");
  const [filterChurch, setFilterChurch] = useState<string[]>([]);
  const [filterMy, setFilterMy] = useState<boolean>(false);

  const churches = useMemo<{ key: string; name: string }[]>(() => {
    if (services === null) return [];

    let tmp = services.map((s) => s.church);
    tmp = tmp.filter((church, i) => tmp.indexOf(church) === i);

    setFilterChurch(tmp.map((church) => church.toLowerCase().replace(/[^a-z]/g, "")));

    return tmp.map((church) => ({
      key: church.toLowerCase().replace(/[^a-z]/g, ""),
      name: church,
    }));
  }, [services]);

  const filteredServices = useMemo<ServiceType[] | null>(() => {
    if (services === null) return null;

    return services.filter(
      (s) => filterChurch.includes(s.church.toLowerCase().replace(/[^a-z]/g, "")) && (!filterMy || s.assigns.some((a) => a.user === context.user?.id))
    );
  }, [services, filterChurch, filterMy]);

  const loadServices = () => {
    ajax.get("/plan").on(200, (res: RequestTypes.Plan) => {
      setServices(
        res.services.map((s) => ({
          id: s.id,
          time: dayjs(s.time),
          kind: s.kind,
          church: s.church,
          hint: s.hint,
          assigns: res.assigns.filter((a) => a.service === s.id),
        }))
      );

      setIntro(res.intro);
    });
  };

  const toggleChurch = (church: string, checked: boolean) => {
    setFilterChurch((state) => {
      if (checked) {
        return [...state, church];
      }
      return state.filter((i) => i !== church);
    });
  };

  useEffect(loadServices, []);

  if (filteredServices === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <Grid>
        <Grid.Col xs={12} md={4}>
          <div style={{ position: "sticky", top: "60px" }}>
            <Stack>
              <Card shadow="xl">
                <Stack>
                  <Stack spacing="sm">
                    <Title order={4}>Nach Kirche filtern</Title>

                    {churches.map((church) => (
                      <Checkbox
                        key={church.key}
                        label={church.name}
                        checked={filterChurch.includes(church.key)}
                        onChange={(e) => toggleChurch(church.key, e.currentTarget.checked)}
                      />
                    ))}
                  </Stack>

                  {context.user !== null && (
                    <>
                      <Divider />
                      <Checkbox
                        label="Zeige nur Gottesdienste, für die ich eingeteilt bin"
                        checked={filterMy}
                        onChange={(e) => setFilterMy(e.currentTarget.checked)}
                      />
                    </>
                  )}
                </Stack>
              </Card>

              <Button
                type="button"
                color="teal"
                variant="outline"
                component="a"
                href={"https://api.md-st-josef.de/plan.php?churches=" + filterChurch.join(",")}
                target="_blank"
                rel="noreferrer"
              >
                Download als PDF
              </Button>
            </Stack>
          </div>
        </Grid.Col>

        <Grid.Col xs={12} md={8}>
          {intro.length > 0 && (
            <TypographyStylesProvider>
              <div dangerouslySetInnerHTML={{ __html: intro }} />
            </TypographyStylesProvider>
          )}

          {filteredServices.length > 0 || <Alert color="blue">Aktuell entsprechen keine geplanten Gottesdienste deiner Filterung.</Alert>}

          {filteredServices.length > 0 && (
            <Stack>
              {filteredServices.map((service) => (
                <Card key={service.id} shadow="xl">
                  <Grid>
                    <Grid.Col sm={12} md={6}>
                      <Title order={4}>{service.time.format("ddd, DD.MM.YYYY, HH:mm [Uhr]")}</Title>
                      <Text>
                        {service.kind}, {service.church}
                      </Text>

                      {service.hint.length > 0 && <Text color="dimmed">{service.hint}</Text>}

                      {service.assigns.some((a) => a.canceltoken !== "") && (
                        <Alert color="grape" mt="md">
                          <Text>Du bist für diesen Gottesdienst eingeteilt.</Text>
                          <Text>
                            Du kannst nicht?{" "}
                            <Text
                              variant="link"
                              component={Link}
                              to={"/plan/cancel/" + service.assigns.find((a) => a.canceltoken !== "")?.canceltoken}
                            >
                              Jetzt abmelden.
                            </Text>
                          </Text>
                        </Alert>
                      )}
                    </Grid.Col>

                    <Grid.Col sm={12} md={6}>
                      <List>
                        {service.assigns.map((user, i) => (
                          <List.Item key={i}>
                            <span
                              style={
                                user.canceled === "1" || user.canceled === "2"
                                  ? {
                                      textDecoration: "line-through",
                                      textDecorationColor: "red",
                                    }
                                  : {}
                              }
                            >
                              {user.givenname} {user.surname}
                            </span>
                          </List.Item>
                        ))}
                      </List>
                    </Grid.Col>
                  </Grid>
                </Card>
              ))}
            </Stack>
          )}
        </Grid.Col>
      </Grid>
    </>
  );
};

export default PagePlanView;

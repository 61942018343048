import { useMemo, useState } from "react";

type InitValue<TV> = Map<string, TV> | [string, TV][];

type StableActions<TV> = {
  set: (key: string, value: TV) => void;
  setAll: (newMap: Map<string, TV>) => void;
  setFromArray: (data: [string, TV][]) => void;
  remove: (key: string) => void;
};

const useMapState = <TV extends any>(init?: InitValue<TV>): [Map<string, TV>, StableActions<TV>] => {
  let initMap: Map<string, TV>;
  if (init instanceof Array) {
    initMap = new Map<string, TV>();
    init.forEach(([key, value]) => initMap.set(key, value));
  } else if (init instanceof Map) {
    initMap = init;
  } else {
    initMap = new Map();
  }
  const [map, setMap] = useState<Map<string, TV>>(initMap);

  const stableActions = useMemo<StableActions<TV>>(
    () => ({
      set: (key, value) => {
        setMap((prevMap) => new Map(Array.from(prevMap.entries()).concat([[key, value]])));
      },
      setAll: (newMap) => {
        setMap(newMap);
      },
      setFromArray: (data) => {
        let newMap = new Map<string, TV>();
        data.forEach(([key, value]) => newMap.set(key, value));
        setMap(newMap);
      },
      remove: (key) => {
        setMap((prevMap) => new Map(Array.from(prevMap.entries()).filter(([k]) => k !== key)));
      },
    }),
    [setMap]
  );

  return [map, stableActions];
};

export default useMapState;

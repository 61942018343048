import { Grid, Text, Title } from "@mantine/core";

const PageDefaultsMain: React.FC<{}> = () => {
  return (
    <Grid>
      <Grid.Col sm={12} md={4}>
        <img src="https://md-st-josef.de/logo.jpg" alt="Buntes Bleiglasfenster in der Kirche St. Josef" width="100%" />
      </Grid.Col>

      <Grid.Col sm={12} md={8}>
        <Title order={2}>Herzlich Willkommen auf der Website der Messdienerschaft St. Mariae Geburt Kempen</Title>

        <Text>
          Als Messdiener in Kempen ist unsere Hauptaufgabe natürlich der Dienst hinter dem Altar in den drei Kempener Kirchen Christ König, St. Josef
          und St. Marien. Dazu treffen wir uns aber auch in regelmäßigen, meist wöchentlich stattfindenden Gruppenstunden, um einerseits das Dienen zu
          üben, aber auch um gemeinsam Spiele zu spielen, etwas leckeres zu kochen oder einfach zu machen, worauf wir gerade Lust haben.
        </Text>
        <Text>
          Für alle Messdienerinnen und Messdiener gibt es auf der Seite noch einige Funktonen, für die ihr euch anmelden müsst. So könnt ihr euch nach
          dem Login z.B. vom Messdienerplan abmelden. Bei Problemen mit dem Login schreibt uns bitte{" "}
          <Text variant="link" component="a" href="mailto:info@md-st-josef.de">
            eine Mail
          </Text>
          .
        </Text>
        <Text>
          Weiterhin organisieren wir als Leiterrunde immer zu Beginn des Jahres eine Tannenbaumabholung in Kempen. Damit sammeln wir Spenden für uns
          sowie das AIDS-Waisenhaus in Okatana, Namibia.
        </Text>
        <Text>
          Falls es noch Unklarheiten gibt, schreibt uns über das Kontaktformular gerne{" "}
          <Text variant="link" component="a" href="mailto:info@md-st-josef.de">
            eine Mail
          </Text>
          !
        </Text>
      </Grid.Col>
    </Grid>
  );
};

export default PageDefaultsMain;

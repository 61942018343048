import { Center, Loader, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";
import PageMailListDesktop from "./list-desktop";
import PageMailListMobile from "./list-mobile";
import { MailType } from "./service";

const PageMailList: React.FC<{}> = () => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery("(max-width: " + theme.breakpoints["md"] + "px)");

  const [mails, setMails] = useState<MailType[] | null>(null);
  const [selection, setSelection] = useState<MailType | null>(null);

  const loadMails = (): void => {
    ajax.get("/mails").on(200, (res: RequestTypes.Mails) => {
      setMails(
        res.mails.map((m) => ({
          ...m,
          author: m.author === null ? "System" : m.authorgivenname + " " + m.authorsurname,
          show: m.show === "1",
          attachments: res.attachments.filter((a) => a.mail === m.id),
          recipients: res.recipients.filter((r) => r.mail === m.id),
        }))
      );
    });
  };

  const resend = (): void => {
    if (selection === null) return;

    ajax.post(`/mails/resend/${selection.id}`, null).on(204, () => {
      loadMails();
      showNotification({
        message: "Für die fehlgeschlagenene Empfänger:innen wird ein neuer Sendeversuch gestartet.",
        color: "green",
      });
    });
  };

  useEffect(loadMails, []);

  if (mails === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (isMobile) {
    return <PageMailListMobile mails={mails} selection={selection} select={setSelection} reload={loadMails} resend={resend} />;
  } else {
    return <PageMailListDesktop mails={mails} selection={selection} select={setSelection} reload={loadMails} resend={resend} />;
  }
};

export default PageMailList;

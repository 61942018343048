import { NumberInput, Sx } from "@mantine/core";
import { ReactNode, useMemo } from "react";

export const combineDateTime = (date: Date | null | undefined, time: Date | null | undefined): Date => {
  let dt = new Date();

  if (date !== undefined && date !== null) {
    dt.setFullYear(date.getFullYear());
    dt.setMonth(date.getMonth());
    dt.setDate(date.getDate());
  }

  if (time !== undefined && time !== null) {
    dt.setHours(time.getHours());
    dt.setMinutes(time.getMinutes());
    dt.setSeconds(time.getSeconds());
  }
  return dt;
};

type CurrencyInputProps = {
  description?: string;
  decimalSeparator?: string;
  disabled?: boolean;
  error?: ReactNode;
  icon?: ReactNode;
  iconWidth?: number;
  label?: string;
  max?: number;
  min?: number;
  onChange?: (value: number) => void;
  placeholder?: string;
  precision?: number;
  step?: number;
  sx?: Sx;
  value?: number;
};

export const CurrencyInput: React.FC<CurrencyInputProps> = (props) => {
  const defaultedProps = useMemo<CurrencyInputProps>(
    () =>
      ({
        description: props.description,
        decimalSeparator: props.decimalSeparator || ",",
        disabled: props.disabled,
        error: props.error,
        icon: props.icon,
        iconWidth: props.iconWidth,
        label: props.label,
        max: props.max,
        min: props.min || 0,
        onChange: props.onChange,
        placeholder: props.placeholder,
        precision: props.precision || 2,
        step: props.step || 0.01,
        sx: props.sx || {},
        value: props.value,
      } as CurrencyInputProps),
    [props]
  );

  const parser = (value: string | undefined): string => {
    if (value === undefined) return "";
    return value.replace(/[^\d.]/g, "");
  };

  const formatter = (value: string | undefined): string => {
    if (value === undefined || isNaN(parseFloat(value))) {
      return "€ ";
    }

    return "€ " + value;
  };

  return <NumberInput {...defaultedProps} parser={parser} formatter={formatter} />;
};

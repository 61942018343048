import { Button, Center, Group, Input, Loader, SimpleGrid } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import RichTextEditor from "@mantine/rte";
import { useEffect, useState } from "react";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";
import { combineDateTime } from "../../service/service";

type FormType = {
  showStart: Date;
  showEnd: Date;
  useStart: Date;
  useEnd: Date;
  intro: string;
  mail: string;
};

const PageTbaSettings: React.FC<{}> = () => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const form = useForm<FormType>({
    initialValues: {
      showStart: new Date(),
      showEnd: new Date(),
      useStart: new Date(),
      useEnd: new Date(),
      intro: "",
      mail: "",
    },
  });

  useEffect(() => {
    ajax.get("/tba").on(200, (res: RequestTypes.Tba) => {
      form.setValues({
        showStart: new Date(+res.show.start * 1000),
        showEnd: new Date(+res.show.end * 1000),
        useStart: new Date(+res.use.start * 1000),
        useEnd: new Date(+res.use.end * 1000),
        intro: res.intro,
        mail: res.mail ?? "",
      });

      setLoaded(true);
    });
  }, []);

  const save: React.FormEventHandler = (e) => {
    e.preventDefault();

    let data = {
      "tba-show-start": Math.round(form.values.showStart.getTime() / 1000),
      "tba-show-end": Math.round(form.values.showEnd.getTime() / 1000),
      "tba-use-start": Math.round(form.values.useStart.getTime() / 1000),
      "tba-use-end": Math.round(form.values.useEnd.getTime() / 1000),
      "tba-intro": form.values.intro,
      "tba-mail": form.values.mail,
    };
    ajax
      .post("/tba/settings", data)
      .on(204, () => {
        showNotification({
          message: "Die neuen Einstellungen wurden gespeichert.",
          color: "green",
        });
      })
      .on(400, () => {
        showNotification({
          message: "Die neuen Einstellungen konnten nicht gespeichert werden.",
          color: "red",
        });
      });
  };

  if (!loaded) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <form onSubmit={save}>
        <SimpleGrid
          breakpoints={[
            { minWidth: "xs", cols: 1 },
            { minWidth: "md", cols: 2 },
            { minWidth: "lg", cols: 4 },
          ]}
          mb="md"
        >
          <DatePicker
            label="Anzeigezeitraum (von)"
            value={form.values.showStart}
            onChange={(d) => form.setFieldValue("showStart", combineDateTime(d, form.values.showStart))}
          />
          <TimeInput
            label="(Zeit)"
            value={form.values.showStart}
            onChange={(t) => form.setFieldValue("showStart", combineDateTime(form.values.showStart, t))}
          />
          <DatePicker
            label="(bis)"
            value={form.values.showEnd}
            onChange={(d) => form.setFieldValue("showEnd", combineDateTime(d, form.values.showEnd))}
          />
          <TimeInput
            label="(Zeit)"
            value={form.values.showEnd}
            onChange={(t) => form.setFieldValue("showEnd", combineDateTime(form.values.showEnd, t))}
          />

          <DatePicker
            label="Anmeldezeitraum (von)"
            value={form.values.useStart}
            onChange={(d) => form.setFieldValue("useStart", combineDateTime(d, form.values.useStart))}
          />
          <TimeInput
            label="(Zeit)"
            value={form.values.useStart}
            onChange={(t) => form.setFieldValue("useStart", combineDateTime(form.values.useStart, t))}
          />
          <DatePicker
            label="(bis)"
            value={form.values.useEnd}
            onChange={(d) => form.setFieldValue("useEnd", combineDateTime(d, form.values.useEnd))}
          />
          <TimeInput
            label="(Zeit)"
            value={form.values.useEnd}
            onChange={(t) => form.setFieldValue("useEnd", combineDateTime(form.values.useEnd, t))}
          />
        </SimpleGrid>

        <SimpleGrid
          breakpoints={[
            { minWidth: "xs", cols: 1 },
            { minWidth: "lg", cols: 2 },
          ]}
          mb="md"
        >
          <Input.Wrapper label="Intro-Text (wird auf der Anmeldeseite angezeigt)" labelElement="div">
            <RichTextEditor {...form.getInputProps("intro")} />
          </Input.Wrapper>

          <Input.Wrapper label="Mail-Text (wird zur Bestätigung versendet)" labelElement="div">
            <RichTextEditor {...form.getInputProps("mail")} />
          </Input.Wrapper>
        </SimpleGrid>

        <Group position="right">
          <Button type="submit" variant="outline">
            Änderungen speichern
          </Button>
        </Group>
      </form>
    </>
  );
};

export default PageTbaSettings;

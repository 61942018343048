import { Burger, Button, Divider, Drawer, Group, MantineSize, Menu, ScrollArea, Stack, useMantineTheme } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";

export type NavbarDefinition = NavbarGroup[];

export type NavbarGroup = NavbarElement[];

export type NavbarElement =
  | {
      type: "link";
      element: NavbarLink;
    }
  | {
      type: "button";
      element: NavbarButton;
    }
  | {
      type: "dropdown";
      element: NavbarDropdown;
    };

export type NavbarLink = {
  label: string;
  target: string;
  external?: boolean;
};

export type NavbarButton = {
  label: string;
  cb: () => void;
};

export type NavbarDropdown = {
  label: string;
  children: (NavbarLink | NavbarDropdownDivider)[];
};

export type NavbarDropdownDivider = "divider";

type Props = {
  definition: NavbarDefinition;
  breakpoint: MantineSize;
  hideMobileNavigation?: boolean;
};

export const Navbar: React.FC<Props> = (props) => {
  const theme = useMantineTheme();

  const isMobile = useMediaQuery("(max-width: " + theme.breakpoints[props.breakpoint] + "px)");
  const [mobileVisible, setMobileVisible] = useDisclosure(false);

  return (
    <nav style={{ position: "sticky", top: 0, zIndex: 100, backgroundColor: theme.colors.dark[9], borderRadius: "5px" }}>
      {isMobile && (
        <div>
          <Group position="left" p="xs" mb="xl">
            <Burger opened={mobileVisible} onClick={setMobileVisible.toggle} title="Navigation öffnen" />
          </Group>

          <Drawer opened={mobileVisible && props.hideMobileNavigation !== true} onClose={setMobileVisible.close}>
            <ScrollArea style={{ height: "95%" }}>
              <Stack spacing="md" align="flex-start" p="xs" mb="xl">
                {props.definition.flat(1).map((elem, i) => (
                  <NavbarElementComp key={i} element={elem} dropdownTrigger="click" onClick={setMobileVisible.close} />
                ))}
              </Stack>
            </ScrollArea>
          </Drawer>
        </div>
      )}

      {isMobile || (
        <Group position="apart" p="xs" mb="xl">
          {props.definition.map((group, i) => (
            <Group key={i}>
              {group.map((elem, j) => (
                <NavbarElementComp key={j} element={elem} dropdownTrigger="hover" />
              ))}
            </Group>
          ))}
        </Group>
      )}
    </nav>
  );
};

type NavbarElementCompProps = {
  element: NavbarElement;
  dropdownTrigger?: "hover" | "click";
  onClick?: () => void;
};

const NavbarElementComp: React.FC<NavbarElementCompProps> = (props) => {
  const cbWrapper =
    (cb?: () => void): (() => void) =>
    () => {
      cb && cb();
      props.onClick && props.onClick();
    };

  if (props.element.type === "link") {
    if (props.element.element.external === true) {
      return (
        <Button type="button" color="dark" variant="subtle" component="a" href={props.element.element.target}>
          {props.element.element.label}
        </Button>
      );
    } else {
      return (
        <Button type="button" color="dark" variant="subtle" component={Link} to={props.element.element.target} onClick={cbWrapper()}>
          {props.element.element.label}
        </Button>
      );
    }
  } else if (props.element.type === "button") {
    return (
      <Button type="button" color="dark" variant="subtle" onClick={cbWrapper(props.element.element.cb)}>
        {props.element.element.label}
      </Button>
    );
  } else if (props.element.type === "dropdown") {
    return (
      <Menu withArrow width={200} position="bottom-start" trigger={props.dropdownTrigger}>
        <Menu.Target>
          <Button type="button" color="dark" variant="subtle">
            {props.element.element.label}
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          {props.element.element.children.map((child, i) => {
            if (child === "divider") {
              return <Divider key={i} />;
            } else {
              if (child.external === true) {
                return (
                  <Menu.Item key={i} component="a" href={child.target}>
                    {child.label}
                  </Menu.Item>
                );
              } else {
                return (
                  <Menu.Item key={i} component={Link} to={child.target} onClick={cbWrapper()}>
                    {child.label}
                  </Menu.Item>
                );
              }
            }
          })}
        </Menu.Dropdown>
      </Menu>
    );
  }

  return <></>;
};

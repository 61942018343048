import { Button, Card, Collapse, PasswordInput, Stack, Text, TextInput, Title } from "@mantine/core";
import { useDisclosure, useId } from "@mantine/hooks";
import { hideNotification, showNotification } from "@mantine/notifications";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../..";
import ajax from "../../service/ajax";

type Props = {
  close: () => void;
};

const LoginDrawer: React.FC<Props> = (props) => {
  const context = useContext(AppContext);
  const errorMessageId = useId();

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [resetUsername, setResetUsername] = useState<string>("");
  const [resetSuccessfull, setResetSuccessfull] = useState<boolean>(false);

  const [collapseCookie, setCollapseCookie] = useDisclosure(false);
  const [collapseReset, setCollapseReset] = useDisclosure(false);

  const cleanUsername = (input: string): string => {
    return input
      .toLowerCase()
      .replace(/ä/g, "ae")
      .replace(/ö/g, "oe")
      .replace(/ü/g, "ue")
      .replace(/ß/g, "ss")
      .replace(/[^a-z]/g, "");
  };

  const login = (e: React.FormEvent): void => {
    e.preventDefault();
    hideNotification(errorMessageId);

    ajax
      .post("/login", {
        username: username,
        password: password,
      })
      .on(204, () => {
        showNotification({
          message: "Du wurdest erfolgreich eingeloggt.",
          color: "green",
        });

        context.reloadUser();
        props.close();
      })
      .on(400, () => {
        showNotification({
          id: errorMessageId,
          message: "Deine Login-Daten sind leider nicht korrekt.",
          color: "red",
          autoClose: false,
          disallowClose: true,
        });
      });
  };

  const reset: React.FormEventHandler = (e) => {
    e.preventDefault();

    ajax
      .get("/login/reset/" + resetUsername)
      .on(204, () => {
        setResetSuccessfull(true);
      })
      .on(400, () => {
        showNotification({
          id: errorMessageId,
          message: "Leider konnte dir kein Rücksetz-Code per Mail zugeschickt werden. Bitte überprüfe, ob der Benutzername korrekt ist.",
          color: "red",
          autoClose: false,
          disallowClose: true,
        });
      });
  };

  useEffect(
    () => () => {
      hideNotification(errorMessageId);
    },
    [errorMessageId]
  );

  return (
    <Stack>
      {resetSuccessfull && (
        <Card shadow="xl">
          <Title order={4}>Passwort zurücksetzen</Title>

          <Text>
            Wir haben dir an deine Mail-Adresse einen Link zum Zurücksetzen deines Passworts geschickt. Bitte folge dem Link in der Mail, um dir ein
            neues Passwort zu setzen.
          </Text>
        </Card>
      )}
      {resetSuccessfull || (
        <>
          <form onSubmit={login}>
            <Stack>
              <TextInput
                label="Benutzername"
                placeholder="Benutzername"
                value={username}
                onChange={(e) => setUsername(cleanUsername(e.currentTarget.value))}
              />

              <PasswordInput label="Passwort" placeholder="Passwort" value={password} onChange={(e) => setPassword(e.currentTarget.value)} />

              <Stack spacing="xs" align="flex-end" mb="md">
                <Button type="button" color="gray" variant="subtle" compact onClick={setCollapseCookie.toggle}>
                  Login benötigt Cookies. Erfahre mehr
                </Button>

                <Collapse in={collapseCookie}>
                  <div id="pageLogin-collapse-cookie">
                    <Text>
                      Um dich nach dem Login identifizieren zu können, nutzen wir sogenannte Cookies. Das sind kleine Textdateien, die auf deinem
                      Rechner gespeichert werden. In diesem Cookie steht eine Nummer, mit der wir dich 100 Tage lang wiedererkennen können, damit du
                      dich nicht auf jeder Seite neu anmelden musst. Genaueres findest du im Datenschutz.
                    </Text>
                    <Text>
                      Mit dem Klick auf den Login-Button erklärst du dich damit einverstanden. Bist du nicht einverstanden, kannst du unsere Seite wie
                      gewohnt nutzen, die Bereiche für eingeloggte Benutzer bleiben jedoch versperrt.
                    </Text>
                  </div>
                </Collapse>

                <Button type="button" color="gray" variant="subtle" compact onClick={setCollapseReset.toggle}>
                  Passwort vergessen?
                </Button>
              </Stack>

              <Button type="submit" variant="outline" fullWidth>
                Login
              </Button>
            </Stack>
          </form>

          <Collapse in={collapseReset}>
            <Card shadow="xl">
              <Title order={4}>Passwort vergessen?</Title>

              <Text>
                Dein Benutzername hat immer das Format <i>vornamenachname</i> und wird klein geschrieben. Die Umlaute <i>ä, ö, ü, ß</i> werden dabei
                ersetzt, z.B. ö wird zu oe. Alle anderen Umlaute entfallen.
              </Text>

              <Text mb="md">
                Falls du dein Passwort vergessen hast, kannst du dir ein neues zusenden lassen. Bitte gebe dazu deinen Benutzernamen hier ein.
              </Text>

              <form onSubmit={reset}>
                <TextInput
                  label="Benutzername"
                  placeholder="Benutzername"
                  value={resetUsername}
                  onChange={(e) => setResetUsername(cleanUsername(e.currentTarget.value))}
                  mb="md"
                />

                <Button type="submit" variant="outline" disabled={resetUsername === ""} fullWidth>
                  Passwort zurücksetzen
                </Button>
              </form>
            </Card>
          </Collapse>
        </>
      )}
    </Stack>
  );
};

export default LoginDrawer;

import { Box, Button, Center, Checkbox, Divider, Grid, Group, Loader, NumberInput, Portal, Radio, Select, Stack, Table, Text } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import Leaflet from "leaflet";
import React, { useEffect, useMemo, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from "react-leaflet";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";
import useMapState from "../../service/useMap";

type TbaAddressType = {
  id: string;
  name: string;
  street: string;
  number: string;
  longitude: number;
  latitude: number;
  route: string | null;
  index: number;
};

type RoutesHolder = {
  names: string[];
  counters: {
    name: string;
    counter: number;
  }[];
  routeless: number;
};

const PageTbaRoutes: React.FC<{}> = () => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  const [addresses, changeAddresses] = useMapState<TbaAddressType>();
  const [clickBehaviour, setClickBehaviour] = useState<string>("select");
  const [visibleRouteless, setVisibleRouteless] = useState<boolean>(true);
  const [visibleRoutes, setVisibleRoutes] = useState<string[]>([]);

  const routes = useMemo<RoutesHolder>(() => {
    let addressesList = Array.from(addresses.values());

    let names = Array.from(new Set(addressesList.map((a) => a.route).filter((a) => a !== null) as string[])).sort((a, b) => a.localeCompare(b));

    let counter = names.map((name) => ({
      name: name,
      counter: addressesList.filter((row) => row.route === name).length,
    }));

    return {
      names: names,
      counters: counter,
      routeless: addressesList.filter((row) => row.route === null).length,
    };
  }, [addresses]);

  const filteredAddresses = useMemo<TbaAddressType[]>(() => {
    console.log("map", addresses.get("313"));
    let x = Array.from(addresses.values()).filter(
      (a) => (a.route === null && visibleRouteless) || (a.route !== null && visibleRoutes.includes(a.route))
    );
    console.log(
      "filter",
      x.find((a) => a.id === "313")
    );
    return x;
  }, [addresses, visibleRoutes, visibleRouteless]);

  const loadData = (): void => {
    ajax.get("/tba/registration/").on(200, (res: RequestTypes.TbaRegistration) => {
      changeAddresses.setFromArray(
        res.addresses.map((a) => [
          a.id,
          {
            id: a.id,
            name: a.name,
            street: a.street,
            number: a.number,
            longitude: +a.longitude,
            latitude: +a.latitude,
            route: a.route,
            index: +a.index,
          },
        ])
      );
      setLoaded(true);

      setVisibleRoutes(Array.from(new Set(res.addresses.map((a) => a.route).filter((route) => route !== null) as string[])));
    });
  };

  const stringToColour = (str: string): string => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 6) - hash);
    }
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      colour += ("00" + value.toString(16)).substr(-2);
    }
    return colour;
  };

  const getIcon = (address: TbaAddressType): Leaflet.DivIcon => {
    let htmlStyles = {
      display: "block",
      position: "relative",
      left: "-1rem",
      top: "-rem",
      "background-color": address.route === null ? "#000000" : stringToColour(address.route),
      width: "2rem",
      height: "2rem",
      color: "#ffffff",
      "text-align": "center",
      "line-height": "2rem",
      "border-radius": "3rem",
      "font-size": "1rem",
    };
    let css = Object.entries(htmlStyles)
      .map(([k, v]) => `${k}: ${v};`)
      .join("\n");

    return Leaflet.divIcon({
      className: "my-custom-pin",
      iconAnchor: [0, 24],
      popupAnchor: [0, -36],
      html: `<span style="${css}">${address.index > 0 ? address.index : ""}</span>`,
    });
  };

  const markerClickHandler = (address: TbaAddressType): void => {
    if (clickBehaviour === "select") return;

    if (clickBehaviour === "remove") {
      changeAddressRoute(address, null);
    } else if (clickBehaviour.startsWith("move-")) {
      changeAddressRoute(address, clickBehaviour.slice(5));
    }
  };

  const changeAddressRoute = (address: TbaAddressType, route: string | null): string | null => {
    let indices = Array.from(addresses.values())
      .filter((a) => a.route === route)
      .map((a) => a.index)
      .filter((i) => i > 0)
      .sort((a, b) => a - b);

    if (route !== null && !routes.names.includes(route)) {
      setVisibleRoutes((old) => [...old, route]);
    }

    changeAddresses.set(address.id, {
      ...address,
      route: route,
      index: route === null ? 0 : indices.length > 0 ? Math.max(...indices) + 1 : 1,
    });

    return route;
  };

  const changeAddressIndex = (address: TbaAddressType, index: number): void => {
    // increment all indices greater or equal of this route
    addresses.forEach((a) => {
      if (a.route === address.route && a.index >= index && a.index > 0) {
        changeAddresses.set(a.id, {
          ...a,
          index: a.index + 1,
        });
      }
    });
    changeAddresses.set(address.id, {
      ...address,
      index: index,
    });
  };

  const trimIndices = (): void => {
    let addressCopy: Map<string, TbaAddressType> = new Map(Array.from(addresses.values()).map((a) => [a.id, { ...a }]));

    ["none", "astra", "bitburger", "carlsberg", "diebels", "radeberger"].forEach((route) => {
      let sortedAddresses = Array.from(addressCopy.values())
        .filter((a) => a.route === route && a.index > 0)
        .sort((a, b) => a.index - b.index);
      sortedAddresses.forEach((address) => {
        let smallerIndices = Array.from(addressCopy.values())
          .filter((a) => a.route === route && a.index < address.index)
          .map((a) => a.index);
        let highestSmallerIndex = smallerIndices.length > 0 ? Math.max(...smallerIndices) : 0;

        addressCopy.set(address.id, {
          ...address,
          index: highestSmallerIndex + 1,
        });
      });
    });

    changeAddresses.setAll(addressCopy);
  };

  const removeIndices = (route: string | null): void => {
    Array.from(addresses.values())
      .filter((a) => a.route === route)
      .forEach((a) => {
        changeAddresses.set(a.id, {
          ...a,
          index: 0,
        });
      });
  };

  const save = (): void => {
    // only id, route and index needed per address
    let data = {
      addresses: Array.from(addresses.values()).map((a) => ({
        id: a.id,
        route: a.route,
        index: a.index,
      })),
    };
    ajax
      .post("/tba/routes", data)
      .on(204, () => {
        showNotification({
          message: "Die Änderungen an den Routen wurden gespeichert.",
          color: "green",
        });
      })
      .on(400, () => {
        showNotification({
          message: "Die Änderungen an den Routen konnten nicht gespeichert werden.",
          color: "red",
        });
      });
  };

  useEffect(loadData, []);

  if (!loaded) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      <Group mb="md">
        <Button type="button" variant="outline" onClick={save}>
          Änderungen speichern
        </Button>
        <Button type="button" variant="outline" color="teal" onClick={() => setShowSidebar((old) => !old)}>
          {showSidebar ? "Seitenleiste einklappen" : "Seitenleiste ausklappen"}
        </Button>
      </Group>

      <Portal>
        <Box pl="md" pr="md">
          <Grid>
            <Grid.Col xs={12} md={showSidebar ? 9 : 12}>
              <MapContainer center={[51.3554579, 6.4170373]} zoom={14} scrollWheelZoom={true} style={{ height: "75vh" }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                {filteredAddresses.map((a) => (
                  <Marker key={a.id} position={[a.latitude, a.longitude]} icon={getIcon(a)} eventHandlers={{ click: () => markerClickHandler(a) }}>
                    <Tooltip>
                      <Table>
                        <tbody>
                          <tr>
                            <th scope="row">Name</th>
                            <td>{a.name}</td>
                          </tr>
                          <tr>
                            <th scope="row">Adresse</th>
                            <td>
                              {a.street} {a.number}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Route</th>
                            <td>{a.route ?? "Keine zugeweisen"}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tooltip>

                    {clickBehaviour === "select" && (
                      <Popup minWidth={200}>
                        <Stack>
                          <Select
                            label="Route"
                            placeholder="Auswählen oder neu angeben"
                            data={routes.names}
                            value={a.route}
                            onChange={(route) => changeAddressRoute(a, route)}
                            zIndex={500}
                            searchable
                            clearable
                            allowDeselect={false}
                            creatable
                            getCreateLabel={(q) => `+ Route '${q}'`}
                            onCreate={(route) => changeAddressRoute(a, route)}
                          />

                          <IndexInput value={a.index} onChange={(i) => changeAddressIndex(a, i)} disabled={a.route === null} />
                        </Stack>
                      </Popup>
                    )}
                  </Marker>
                ))}
              </MapContainer>
            </Grid.Col>

            {showSidebar && (
              <Grid.Col xs={12} md={3}>
                <Stack>
                  <Radio.Group
                    label="Verhalten beim Auswählen"
                    orientation="vertical"
                    spacing={1}
                    value={clickBehaviour}
                    onChange={setClickBehaviour}
                  >
                    <Radio value="remove" label="Aus Route entfernen" />
                    <Radio value="select" label="Einzeln auswählen" />

                    {routes.names.map((route) => (
                      <Radio key={route} value={`move-${route}`} label={`In Route ${route} verschieben`} />
                    ))}
                  </Radio.Group>

                  <Divider />

                  {routes.counters.length > 0 && (
                    <Checkbox.Group
                      label="Angezeigte Routen"
                      orientation="vertical"
                      spacing={1}
                      value={visibleRoutes as string[]}
                      onChange={setVisibleRoutes}
                    >
                      {routes.counters.map((route) => (
                        <Checkbox
                          key={route.name}
                          value={route.name}
                          label={
                            <Text component="span">
                              {route.name}{" "}
                              <Text component="span" color="gray">
                                ({route.counter})
                              </Text>
                            </Text>
                          }
                        />
                      ))}
                    </Checkbox.Group>
                  )}

                  <Checkbox
                    label={
                      <Text component="span">
                        Zeige Adressen ohne Route{" "}
                        <Text component="span" color="gray">
                          ({routes.routeless})
                        </Text>
                      </Text>
                    }
                    checked={visibleRouteless}
                    onChange={(e) => setVisibleRouteless(e.currentTarget.checked)}
                  />

                  <Divider />

                  <Button type="button" variant="outline" color="teal" onClick={trimIndices}>
                    Indizes bereinigen
                  </Button>

                  {routes.names.length > 0 && (
                    <Select label="Indizes löschen" data={routes.names} value={null} onChange={(route) => removeIndices(route)} />
                  )}
                </Stack>
              </Grid.Col>
            )}
          </Grid>
        </Box>
      </Portal>
    </>
  );
};

export default PageTbaRoutes;

type IndexInputProps = {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
};

const IndexInput: React.FC<IndexInputProps> = (props) => {
  const [value, setValue] = useState<number | undefined>(0);
  const [debounced] = useDebouncedValue(value, 300);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    props.onChange(value ?? 0);
  }, [debounced]);

  const keyListener = (e: React.KeyboardEvent) => {
    if (e.code === "Enter" || e.code === "NumpadEnter") {
      props.onChange(value ?? 0);
    }
  };

  return <NumberInput label="Index" placeholder="Index" value={value} onChange={setValue} onKeyPress={keyListener} disabled={props.disabled} />;
};

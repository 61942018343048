import {
  Autocomplete,
  Button,
  Center,
  Grid,
  Group,
  Loader,
  Modal,
  NumberInput,
  Radio,
  Select,
  SelectItem,
  SimpleGrid,
  Switch,
  Table,
  Text,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { useDisclosure, useListState } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import "dayjs/locale/de";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Sum, Trash } from "tabler-icons-react";
import { AppContext } from "../..";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";
import { CurrencyInput } from "../../service/service";

type Entry = {
  id: number;
  creditor: number | null;
  debitor: number | null;
  title: string;
  date: dayjs.Dayjs;
  value: number;
};

type EntryType = "buy-drinks" | "drink-list" | "fine" | "clearing";

type AddForm = {
  type: EntryType;
  creditor: number;
  debitor: number;
  title: string;
  date: Date;
  value: number;
};

type MassEntryColumn = {
  price: number;
  persons: Map<number, number>;
};

const PageFineRegister: React.FC<{}> = () => {
  const context = useContext(AppContext);

  const [persons, setPersons] = useState<Map<number, string> | null>(null);
  const [entries, setEntries] = useState<Entry[] | null>(null);

  const [addShowModal, setAddShowModal] = useDisclosure(false);
  const [massAddModal, setMassAddModal] = useDisclosure(false);
  const [showOnlyOwn, setShowOnlyOwn] = useDisclosure(false);

  const addForm = useForm<AddForm>({
    initialValues: {
      type: "buy-drinks",
      creditor: 0,
      debitor: 0,
      title: "",
      date: new Date(),
      value: 0,
    },
    validate: {
      creditor: (val, values) =>
        !(values.type === "drink-list" || values.type === "fine" || values.type === "clearing") || val > 0
          ? null
          : "Es muss eine Person ausgewählt werden.",
      debitor: (val, values) =>
        !(values.type === "buy-drinks" || values.type === "clearing") || val > 0 ? null : "Es muss eine Person ausgewählt werden.",
      date: (val) => (dayjs(val).isValid() ? null : "Es muss ein gültiges Datum angegeben werden."),
      value: (val) => (val > 0 ? null : "Die Zahl muss größer als Null sein."),
    },
  });

  const isEditor = useMemo<boolean>(() => {
    return context.user !== null && context.user.permissions.includes("fine-register-edit");
  }, [context]);

  const personListItems = useMemo<SelectItem[]>(() => {
    if (persons === null) return [];

    return Array.from(persons.entries()).map(
      ([id, name]): SelectItem => ({
        value: id.toString(),
        label: name,
      })
    );
  }, [persons]);

  const debitorLabel = useMemo<string>(() => {
    switch (addForm.values.type) {
      case "buy-drinks":
        return "Wer hat die Getränke gekauft?";
      case "clearing":
        return "Wer hat das Geld bezahlt?";
      default:
        return "";
    }
  }, [addForm.values.type]);

  const creditorLabel = useMemo<string>(() => {
    switch (addForm.values.type) {
      case "drink-list":
        return "Wessen Strichliste wurde übertragen?";
      case "fine":
        return "Wer muss die Strafe zahlen?";
      case "clearing":
        return "Wer hat das Geld bekommen?";
      default:
        return "";
    }
  }, [addForm.values.type]);

  const knownTitles = useMemo<string[]>(() => {
    if (entries === null) return [];
    return Array.from(new Set(entries.map((e) => e.title).filter((t) => t !== "")));
  }, [entries]);

  const filteredEntries = useMemo<Entry[] | null>(() => {
    if (entries === null) return null;
    let user = context.user ? +context.user.id : 0;

    return entries.filter((e) => !showOnlyOwn || e.creditor === user || e.debitor === user);
  }, [entries, showOnlyOwn]);

  const loadList = (): void => {
    ajax.get("/fine-register").on(200, (res: RequestTypes.Fineregister) => {
      setEntries(
        res.entries.map(
          (row): Entry => ({
            id: +row.id,
            creditor: row.creditor === null ? null : +row.creditor,
            debitor: row.debitor === null ? null : +row.debitor,
            title: row.title,
            date: dayjs(row.date),
            value: +row.value,
          })
        )
      );

      setPersons(new Map(res.persons.map((row): [number, string] => [+row.id, `${row.surname}, ${row.givenname}`])));
    });
  };

  const calcSumOfPerson = (id: number | null) => {
    if (entries === null) {
      return 0;
    }

    let credit = entries.filter((e) => e.creditor === id).reduce((sum, e) => sum + e.value, 0);
    let debit = entries.filter((e) => e.debitor === id).reduce((sum, e) => sum + e.value, 0);

    return debit - credit;
  };

  const addEntry: React.FormEventHandler = (e) => {
    e.preventDefault();

    if (!addForm.validate().hasErrors) {
      let data = {
        creditor:
          addForm.values.type === "drink-list" || addForm.values.type === "fine" || addForm.values.type === "clearing"
            ? addForm.values.creditor
            : null,
        debitor: addForm.values.type === "buy-drinks" || addForm.values.type === "clearing" ? addForm.values.debitor : null,
        title: addForm.values.title.trim(),
        date: dayjs(addForm.values.date).format("YYYY-MM-DD"),
        value: addForm.values.value,
      };

      ajax.post("/fine-register", data).on(204, () => {
        showNotification({
          message: "Die Buchung wurde erfolgreich hinzugefügt.",
          color: "green",
        });

        loadList();
        setAddShowModal.close();

        addForm.reset();
      });
    }
  };

  useEffect(loadList, []);

  if (filteredEntries === null || persons === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  if (massAddModal) {
    return <MassAddForm persons={persons} cancel={setMassAddModal.close} reload={loadList} />;
  }

  return (
    <>
      <Modal opened={addShowModal} onClose={setAddShowModal.close} title="Eintrag hinzufügen">
        <form onSubmit={addEntry}>
          <Radio.Group orientation="vertical" label="Art der Buchung" mb="md" {...addForm.getInputProps("type")}>
            <Radio value="buy-drinks" label="Einkauf neue Getränke" />
            <Radio value="drink-list" label="Übertrag Getränkeliste" />
            <Radio value="fine" label="Strafe Leiterrunde" />
            <Radio value="clearing" label="Verrechnung" />
          </Radio.Group>

          {(addForm.values.type === "buy-drinks" || addForm.values.type === "clearing") && (
            <Select label={debitorLabel} placeholder="Bitte wählen" searchable mb="md" data={personListItems} {...addForm.getInputProps("debitor")} />
          )}

          {(addForm.values.type === "drink-list" || addForm.values.type === "fine" || addForm.values.type === "clearing") && (
            <Select
              label={creditorLabel}
              placeholder="Bitte wählen"
              searchable
              mb="md"
              data={personListItems}
              {...addForm.getInputProps("creditor")}
            />
          )}

          <Autocomplete label="Titel" placeholder="Beschreibung der Transaktion" data={knownTitles} mb="md" {...addForm.getInputProps("title")} />

          <DatePicker
            label="Datum"
            placeholder="Bitte wählen"
            locale="de"
            inputFormat="DD. MMMM YYYY"
            allowFreeInput
            mb="md"
            {...addForm.getInputProps("date")}
          />

          <NumberInput
            label="Betrag"
            placeholder="Betrag"
            min={0}
            precision={2}
            step={0.01}
            decimalSeparator=","
            mb="md"
            {...addForm.getInputProps("value")}
          />

          <Button type="submit" variant="outline" fullWidth>
            Eintrag hinzufügen
          </Button>
        </form>
      </Modal>

      <Grid>
        <Grid.Col md={8}>
          <p>
            Aktueller Kassenstand: <CurrencyDisplay value={calcSumOfPerson(null)} color />
          </p>
        </Grid.Col>

        {isEditor && (
          <>
            <Grid.Col md={2}>
              <Button type="button" color="teal" variant="outline" fullWidth onClick={setMassAddModal.open}>
                Strichliste übertragen
              </Button>
            </Grid.Col>
            <Grid.Col md={2}>
              <Button type="button" color="teal" variant="outline" fullWidth onClick={setAddShowModal.open}>
                Eintrag hinzufügen
              </Button>
            </Grid.Col>
          </>
        )}
      </Grid>

      <Grid>
        <Grid.Col md={4}>
          <Table highlightOnHover>
            <thead>
              <tr>
                <th>Name</th>
                <th className="text-end">Summe</th>
              </tr>
            </thead>
            <tbody>
              {Array.from(persons.entries()).map(([id, name]) => (
                <tr key={id}>
                  <td>{name}</td>
                  <td className="text-end">
                    <CurrencyDisplay value={calcSumOfPerson(id)} color />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Grid.Col>

        <Grid.Col md={8}>
          <Switch label="Nur Einträge von mir anzeigen" checked={showOnlyOwn} onChange={setShowOnlyOwn.toggle} mb="md" />

          <Table highlightOnHover>
            <thead>
              <tr>
                <th>Titel</th>
                <th>Datum</th>
                <th>Kreditor</th>
                <th>Debitor</th>
                <th>Betrag</th>
              </tr>
            </thead>
            <tbody>
              {filteredEntries.map((entry) => (
                <tr key={entry.id}>
                  <td>{entry.title}</td>
                  <td>{entry.date.format("DD.MM.YYYY")}</td>
                  <td>{entry.creditor === null ? "Kasse" : persons.get(entry.creditor) ?? "unbekannt"}</td>
                  <td>{entry.debitor === null ? "Kasse" : persons.get(entry.debitor) ?? "unbekannt"}</td>
                  <td>
                    <CurrencyDisplay value={entry.value} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default PageFineRegister;

const CurrencyDisplay: React.FC<{ value: number; color?: boolean }> = (props) => {
  const styles = useMemo<React.CSSProperties>(() => {
    if (props.color !== true) return {};

    if (props.value > 0)
      return {
        color: "green",
      };

    if (props.value < 0)
      return {
        color: "red",
      };

    return {};
  }, [props.value, props.color]);

  return <span style={styles}>{props.value.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</span>;
};

const MassAddForm: React.FC<{ persons: Map<number, string>; cancel: () => void; reload: () => void }> = (props) => {
  const [prices, setPrices] = useListState<MassEntryColumn>([
    {
      price: 0,
      persons: new Map(),
    },
  ]);

  const persons = useMemo<{ id: number; name: string }[]>(() => {
    return Array.from(props.persons.entries()).map((p) => ({
      id: p[0],
      name: p[1],
    }));
  }, [props.persons]);

  const personSums = useMemo<Map<number, number>>(() => {
    let map: Map<number, number> = new Map(Array.from(persons.keys()).map((id) => [id, 0]));

    prices.forEach((price) => {
      Array.from(price.persons).forEach(([person, count]) => {
        let oldVal = map.get(person) ?? 0;
        map.set(person, oldVal + price.price * count);
      });
    });

    return map;
  }, [persons, prices]);

  const addColumn = (): void => {
    setPrices.append({
      price: 0,
      persons: new Map(),
    });
  };

  const changeCellValue = (priceIndex: number, personID: number, val: number | undefined): void => {
    if (val === undefined) {
      val = 0;
    }

    let map: Map<number, number> = new Map(Array.from(prices[priceIndex].persons.entries()));
    map.set(personID, val);
    setPrices.setItemProp(priceIndex, "persons", map);
  };

  const submit: React.FormEventHandler = (e) => {
    e.preventDefault();

    let promises: Promise<void>[] = Array.from(personSums)
      .filter((row) => row[1] > 0)
      .map((row) => {
        let data = {
          creditor: row[0],
          debitor: null,
          title: "Liste",
          date: dayjs().format("YYYY-MM-DD"),
          value: row[1],
        };

        return new Promise((resolve) => {
          ajax.post("/fine-register", data).on("*", resolve);
        });
      });

    Promise.all(promises).then(() => {
      showNotification({
        message: "Die Buchungen wurden hinzugefügt. Bitte überprüfe dies auf Korrektheit",
      });
      props.cancel();
      props.reload();
    });
  };

  return (
    <>
      <Button type="button" variant="outline" color="teal" mb="md" onClick={props.cancel}>
        Abbrechen
      </Button>

      <form onSubmit={submit}>
        <SimpleGrid cols={prices.length + 1} mb="md">
          <Button type="button" variant="outline" onClick={addColumn}>
            Strichlisten-Spalte hinzufügen
          </Button>

          {prices.map((val, i) => (
            <Group key={i}>
              <CurrencyInput
                placeholder="Preis"
                value={val.price}
                onChange={(newVal) => setPrices.setItemProp(i, "price", newVal ?? 0)}
                sx={{ flex: 1 }}
              />
              <Button type="button" variant="outline" color="red" onClick={() => setPrices.remove(i)}>
                <Trash />
              </Button>
            </Group>
          ))}

          {persons.map((p) => (
            <React.Fragment key={p.id}>
              <Group>
                <Text>{p.name}</Text>
                <Text>
                  <i>
                    <Sum size={16} /> <CurrencyDisplay value={personSums.get(p.id) ?? 0} />
                  </i>
                </Text>
              </Group>

              {prices.map((price, i) => (
                <NumberInput
                  placeholder="Anzahl"
                  min={0}
                  step={0}
                  precision={0}
                  value={price.persons.get(p.id)}
                  onChange={(val) => changeCellValue(i, p.id, val)}
                />
              ))}
            </React.Fragment>
          ))}
        </SimpleGrid>

        <Button type="submit" variant="outline" fullWidth>
          Einträge hinzufügen
        </Button>
      </form>
    </>
  );
};

import { Accordion, Button, Card, Grid, Group, SimpleGrid, Stack, Switch, Text, TextInput, Title } from "@mantine/core";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useState } from "react";
import { Database } from "../../../request-types";
import ajax from "../../../service/ajax";

dayjs.extend(customParseFormat);

type PermissionTemplate = {
  id: string;
  name: string;
  permissions: string[];
};

type TabTemplatesProps = {
  permissions: Database.Permission[];
  templates: PermissionTemplate[];
  reload: () => void;
};

export const TabTemplates: React.FC<TabTemplatesProps> = (props) => {
  const [addName, setAddName] = useState<string>("");

  const add: React.FormEventHandler = (e) => {
    e.preventDefault();

    ajax.post("/permissions/template", { name: addName }).on(204, () => {
      showNotification({
        message: "Das Template wurde hinzugefügt.",
        color: "green",
      });

      props.reload();
      setAddName("");
    });
  };

  return (
    <Grid>
      <Grid.Col xs={12} lg={8}>
        <Accordion>
          {props.templates.map((template) => (
            <Accordion.Item key={template.id} value={template.id}>
              <Accordion.Control>{template.name}</Accordion.Control>
              <Accordion.Panel>
                <EditTemplateItem permissions={props.permissions} template={template} reload={props.reload} />
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </Grid.Col>

      <Grid.Col xs={12} lg={4}>
        <Card shadow="xl">
          <form onSubmit={add}>
            <Stack>
              <Title order={5}>Template hinzufügen</Title>

              <TextInput label="Name" placeholder="Name" value={addName} onChange={(e) => setAddName(e.currentTarget.value)} />

              <Group position="right">
                <Button type="submit" variant="outline">
                  Berechtigung anlegen
                </Button>
              </Group>
            </Stack>
          </form>
        </Card>
      </Grid.Col>
    </Grid>
  );
};

type EditTemplateItemProps = {
  permissions: Database.Permission[];
  template: PermissionTemplate;
  reload: () => void;
};

const EditTemplateItem: React.FC<EditTemplateItemProps> = (props) => {
  const modals = useModals();

  const [permissions, setPermissions] = useState<Set<string>>(new Set(props.template.permissions));

  const changePermission = (permission: string, granted: boolean): void => {
    setPermissions((oldState) => {
      let copy = new Set(oldState);
      if (granted) {
        copy.add(permission);
      } else {
        copy.delete(permission);
      }
      return copy;
    });
  };

  const remove = (): void => {
    modals.openConfirmModal({
      title: "Berechtigung",
      children: (
        <Text>
          Bist Du Dir sicher, dass Du das Template <i>{props.template.name}</i> löschen möchtest?
        </Text>
      ),
      labels: {
        cancel: "Nein, abbrechen",
        confirm: "Ja, löschen",
      },
      cancelProps: {
        variant: "outline",
      },
      confirmProps: {
        color: "red",
        variant: "outline",
      },
      onConfirm: () => {
        ajax.delete(`/permissions/template/${props.template.id}`).on(204, () => {
          showNotification({
            message: "Das Template wurde erfolgreich gelöscht.",
            color: "green",
          });

          props.reload();
        });
      },
    });
  };

  const save = (): void => {
    let data = {
      permissions: Array.from(permissions).join("\n"),
    };

    ajax.post(`/permissions/template/${props.template.id}`, data).on(204, () => {
      showNotification({
        message: "Die Berechtigungen für das Template wurden gesetzt.",
        color: "green",
      });

      props.reload();
    });
  };

  return (
    <Stack>
      <SimpleGrid cols={2}>
        {props.permissions.map((permission) => (
          <Switch
            key={permission.code}
            label={permission.name}
            checked={permissions.has(permission.code)}
            onChange={(e) => changePermission(permission.code, e.currentTarget.checked)}
          />
        ))}
      </SimpleGrid>

      <Group position="right">
        <Button type="button" variant="outline" color="red" onClick={remove}>
          Template löschen
        </Button>

        <Button type="button" variant="outline" onClick={save}>
          Template speichern
        </Button>
      </Group>
    </Stack>
  );
};

import { Alert, Button, Card, Center, Grid, Group, Loader, SimpleGrid, Stack, Table, Text, Title, TypographyStylesProvider } from "@mantine/core";
import dayjs from "dayjs";
import { useContext, useEffect, useMemo, useState } from "react";
import { AlertCircle, Pencil, Plus } from "tabler-icons-react";
import { Link, useParams } from "react-router-dom";
import { AppContext } from "../..";
import ajax from "../../service/ajax";
import PageEventEdit from "./edit";
import { RequestTypes } from "../../request-types";

export type EventType = {
  id: string;
  title: string;
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
  location: string;
  for: string;
  deadline: dayjs.Dayjs | null;
  costs: number;
  description: string;
  attachments: EventAttachmentType[];
  participants?: {
    internal: string[];
    external: {
      id: string;
      name: string;
      sex: "male" | "female" | "other";
      contact: string | null;
    }[];
  };
};

export type EventAttachmentType = {
  hash: string;
  name: string;
};

export type EventPersonType = {
  id: string;
  givenname: string;
  surname: string;
  sex: "male" | "female" | "other";
  age: number;
};

type PARAMS = {
  id: string | undefined;
};

const PageEventList: React.FC<{}> = () => {
  const context = useContext(AppContext);
  const params = useParams<PARAMS>();

  const isEditor = useMemo<boolean>(() => {
    return context.user !== null && context.user.permissions.includes("events-edit");
  }, [context]);

  const [events, setEvents] = useState<{ future: EventType[]; past: EventType[] } | null>(null);
  const [persons, setPersons] = useState<EventPersonType[] | null>(null);

  const currentEvent = useMemo<EventType | "new" | null>(() => {
    if (params.id === "new") {
      return "new";
    }

    if (params.id !== undefined && events !== null) {
      let tmp = events.future.find((e) => e.id === params.id ?? "0");
      if (tmp) return tmp;
      return events.past.find((e) => e.id === params.id ?? "0") ?? null;
    }

    return null;
  }, [params, events]);

  const loadEvents = (): void => {
    ajax.get("/events").on(200, (res: RequestTypes.Events) => {
      let eventsList: EventType[] = res.events.map((e) => ({
        id: e.id,
        title: e.title,
        start: dayjs(e.start),
        end: dayjs(e.end),
        location: e.location,
        for: e.for,
        deadline: e.deadline ? dayjs(e.deadline) : null,
        costs: +e.costs,
        description: e.description,
        attachments: res.attachments.filter((a) => a.event === e.id),
        participants: res.participants
          ? {
              internal: res.participants.internal.filter((p) => p.event === e.id).map((p) => p.user),
              external: res.participants.external.filter((p) => p.event === e.id),
            }
          : undefined,
      }));

      let tmpEvents = {
        future: eventsList.filter((event) => dayjs().isBefore(event.end)),
        past: eventsList.filter((event) => dayjs().isAfter(event.end)),
      };

      // always sort the nearest first
      tmpEvents.future.sort((a, b) => {
        if (a.start.format("YYYY-MM-DD-HH-mm") === b.start.format("YYYY-MM-DD-HH-mm")) return 0;
        return a.start.isBefore(b.start) ? -1 : 1;
      });
      tmpEvents.past.sort((a, b) => {
        if (a.start.format("YYYY-MM-DD-HH-mm") === b.start.format("YYYY-MM-DD-HH-mm")) return 0;
        return a.start.isBefore(b.start) ? 1 : -1;
      });

      setEvents(tmpEvents);

      if (res.participants) {
        setPersons(
          res.participants.persons.map((p) => ({
            ...p,
            age: dayjs().diff(dayjs(p.dateofbirth), "year"),
          }))
        );
      }
    });
  };
  useEffect(loadEvents, []);

  if (events === null) {
    return <Center children={<Loader />} />;
  }

  if (currentEvent === null) {
    return (
      <>
        {isEditor && (
          <Grid justify="flex-end" mb="md">
            <Grid.Col xs={12} md={6} lg={4}>
              <Button type="button" component={Link} to="/event/new" color="cyan" variant="outline" leftIcon={<Plus />} fullWidth>
                Neue Aktion
              </Button>
            </Grid.Col>
          </Grid>
        )}

        <SimpleGrid
          breakpoints={[
            { minWidth: "xs", cols: 1 },
            { minWidth: "md", cols: 2 },
          ]}
        >
          {events.future.map((e) => (
            <Event key={e.id} {...e} />
          ))}
          {events.past.map((e) => (
            <Event key={e.id} {...e} />
          ))}
        </SimpleGrid>
      </>
    );
  }

  if (persons === null) {
    return <Center children={<Loader />} />;
  }

  return <PageEventEdit event={currentEvent as Required<EventType>} persons={persons} reload={loadEvents} />;
};

export default PageEventList;

const Event: React.FC<EventType> = (props) => (
  <>
    <Card shadow="xl">
      {dayjs().isAfter(props.end) && (
        <Alert icon={<AlertCircle size={16} />} color="red" variant="outline" mb="md">
          Diese Aktion liegt in der Vergangenheit!
        </Alert>
      )}

      <Group position="apart" ml="xs" mb="md">
        <Title order={3}>{props.title}</Title>

        {props.participants !== undefined && (
          <Text variant="link" component={Link} to={"/event/" + props.id}>
            <Pencil />
          </Text>
        )}
      </Group>

      <Table>
        <tbody>
          <tr>
            <td>
              <b>Wer</b>
            </td>
            <td>{props.for}</td>
          </tr>

          <tr>
            <td>
              <b>Wo</b>
            </td>
            <td>{props.location}</td>
          </tr>

          <tr>
            <td>
              <b>Wann</b>
            </td>
            <td>
              {props.start.format("YYYY-MM-DD") === props.end.format("YYYY-MM-DD") &&
                props.start.format("DD.MM.YYYY, HH:mm [ bis ]") + props.end.format("HH:mm [Uhr]")}
              {props.start.format("YYYY-MM-DD") === props.end.format("YYYY-MM-DD") ||
                props.start.format("DD.MM.YYYY, HH:mm [ Uhr bis ]") + props.end.format("DD.MM.YYYY, HH:mm [Uhr]")}
            </td>
          </tr>

          <tr>
            <td>
              <b>Anmeldung</b>
            </td>
            <td>
              {props.deadline === null && "Ohne Anmeldung"}
              {props.deadline !== null &&
                props.deadline.isBefore(dayjs()) &&
                "Der Anmeldeschluss ist leider vorbei. Bitte melde dich bei deinen Gruppenleitern, vielleicht ist trotzdem noch ein Platz für dich frei."}
              {props.deadline !== null && !props.deadline.isBefore(dayjs()) && props.deadline.format("[Bis zum] DD.MM.YYYY")}
            </td>
          </tr>

          <tr>
            <td>
              <b>Kosten</b>
            </td>
            <td>{props.costs === 0 ? "keine" : props.costs.toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</td>
          </tr>

          {props.participants !== undefined && (
            <tr>
              <td>
                <b>Teilnemer:innen</b>
              </td>
              <td>{props.participants.internal.length + props.participants.external.length} (aktuell)</td>
            </tr>
          )}
        </tbody>
      </Table>

      <TypographyStylesProvider m="sm">
        <div dangerouslySetInnerHTML={{ __html: props.description }} />
      </TypographyStylesProvider>

      <Stack align="flex-end" spacing="xs">
        {props.attachments.map((a) => (
          <Text key={a.hash} variant="link" component="a" href={`${process.env.REACT_APP_API_BASE}/file/${a.hash}?name=${a.name}`} download={a.name}>
            Anhang: {a.name}
          </Text>
        ))}
      </Stack>
    </Card>
  </>
);

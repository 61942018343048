import { List, ThemeIcon } from "@mantine/core";
import { useMemo } from "react";
import { Check, Clock, X } from "tabler-icons-react";

export type MailType = {
  id: string;
  author: string;
  subject: string;
  message: string;
  show: boolean;
  timestamp: string;
  attachments: {
    hash: string;
    name: string;
  }[];
  recipients: {
    recipient: string;
    givenname: string;
    surname: string;
    status: "waiting" | "success" | "failed";
  }[];
};

type RecipientListItemProps = {
  recipient: string;
  givenname: string;
  surname: string;
  status: "waiting" | "success" | "failed";
};

export const RecipientListItem: React.FC<RecipientListItemProps> = (props) => {
  const icon = useMemo(() => {
    switch (props.status) {
      case "waiting":
        return (
          <ThemeIcon color="orange" size={20} radius="xl">
            <Clock size={15} />
          </ThemeIcon>
        );

      case "success":
        return (
          <ThemeIcon color="green" size={20} radius="xl">
            <Check size={15} />
          </ThemeIcon>
        );

      case "failed":
        return (
          <ThemeIcon color="red" size={20} radius="xl">
            <X size={15} />
          </ThemeIcon>
        );
    }
  }, [props.status]);

  const displayName = useMemo(
    () => (props.givenname === null ? props.recipient : `${props.givenname} ${props.surname}`),
    [props.recipient, props.givenname, props.surname]
  );

  return <List.Item icon={icon}>{displayName}</List.Item>;
};

import {
  Button,
  Center,
  Checkbox,
  Chip,
  Grid,
  Group,
  Input,
  Loader,
  PasswordInput,
  SimpleGrid,
  Stack,
  Switch,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { AppContext } from "../..";
import { Database, RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";

dayjs.extend(customParseFormat);

type PageUserEditProps = {
  user: Database.User | "new";
  reload: () => void;
};

type EditFormType = {
  givenname: string;
  surname: string;
  mail: string;
  password: string;
  address: string;
  phone: string;
  dateOfBirth: Date | null;
  sex: null | "female" | "male" | "other";
  sharePersonal: boolean;
};

const PageUserEdit: React.FC<PageUserEditProps> = (props) => {
  const context = useContext(AppContext);
  const modals = useModals();
  const navigate = useNavigate();

  const editForm = useForm<EditFormType>({
    initialValues: {
      givenname: props.user === "new" ? "" : props.user.givenname,
      surname: props.user === "new" ? "" : props.user.surname,
      mail: props.user === "new" ? "" : props.user.mail,
      password: "",
      address: props.user === "new" ? "" : props.user.address,
      phone: props.user === "new" ? "" : props.user.phone,
      dateOfBirth: props.user === "new" ? null : new Date(props.user.dateofbirth),
      sex: props.user === "new" ? null : props.user.sex,
      sharePersonal: props.user === "new" ? false : props.user.sharepersonal === "1",
    },
    validate: zodResolver(
      z.object({
        givenname: z.string().min(1, "Der Vorname darf nicht leer sein.").max(25, "Der Vorname darf maximal 25 Zeichen lang sein."),
        surname: z.string().min(1, "Der Nachname darf nicht leer sein.").max(25, "Der Nachname darf maximal 25 Zeichen lang sein."),
        mail: z.string().email("Dies ist keine gültige Mail-Adresse.").max(75, "Die Mail-Adresse darf maximal 75 Zeichen lang sein."),
        address: z.string().min(1, "Die Adresse darf nicht leer sein.").max(75, "Die Adresse darf maximal 75 Zeichen lang sein."),
        phone: z.string().min(1, "Die Telefonnummer darf nicht leer sein.").max(25, "Die Telefonnummer darf maximal 25 Zeichen lang sein."),
        dateOfBirth: z.instanceof(Date, { message: "Dies ist kein gültiges Datum." }),
        sex: z.string({ required_error: "Es muss ein Geschlecht ausgewählt werden." }).nonempty("Es muss ein Geschlecht ausgewählt werden."), // TODO: Default-Meldung wenn null
      })
    ),
  });

  const username = useMemo(() => {
    return (editForm.values.givenname + editForm.values.surname)
      .toLowerCase()
      .replace(/ä/g, "ae")
      .replace(/ö/g, "oe")
      .replace(/ü/g, "ue")
      .replace(/ß/g, "ss")
      .replace(/[^a-z]/g, "");
  }, [editForm.values.givenname, editForm.values.surname]);

  const cancel = (): void => {
    navigate("/users");
  };

  const submitPersonal = (e: React.FormEvent): void => {
    e.preventDefault();

    if (!editForm.validate().hasErrors) {
      let data = {
        givenname: editForm.values.givenname,
        surname: editForm.values.surname,
        username: username,
        mail: editForm.values.mail,
        password: editForm.values.password,
        address: editForm.values.address,
        phone: editForm.values.phone,
        dateofbirth: dayjs(editForm.values.dateOfBirth).format("YYYY-MM-DD"),
        sex: editForm.values.sex,
        sharepersonal: editForm.values.sharePersonal ? 1 : 0,
      };

      if (props.user === "new") {
        ajax
          .post("/users", data)
          .on(204, () => {
            showNotification({
              message: "Der Benutzer wurde erfolgreich hinzugefügt.",
              color: "green",
            });

            props.reload();
            navigate("/users");
          })
          .on(400, () => {
            showNotification({
              message: "Der Benutzer konnte aufgrund eines unbekannten Fehlers nicht hinzugefügt werden.",
              color: "red",
            });
          });
      } else {
        ajax
          .post("/users/" + props.user.id, data)
          .on(204, () => {
            showNotification({
              message: "Der Benutzer wurde erfolgreich gespeichert.",
              color: "green",
            });

            props.reload();
            navigate("/users");
          })
          .on(400, () => {
            showNotification({
              message: "Der Benutzer konnte aufgrund eines unbekannten Fehlers nicht gespeichert werden.",
              color: "red",
            });
          });
      }
    }
  };

  const deleteUser = (): void => {
    if (props.user === "new") return;

    modals.openConfirmModal({
      title: "Benutzer löschen",
      children: (
        <Text>
          Bist du dir sicher, dass du den Benutzer {props.user.givenname} {props.user.surname} löschen möchtest?
        </Text>
      ),
      labels: {
        cancel: "Nein, abbrechen",
        confirm: "Ja, löschen",
      },
      cancelProps: {
        variant: "outline",
      },
      confirmProps: {
        color: "red",
        variant: "outline",
      },
      onConfirm: () => {
        if (props.user === "new") return;
        ajax.delete("/users/" + props.user.id).on(204, () => {
          showNotification({
            message: "Der Benutzer erfolgreich gelöscht.",
            color: "green",
          });

          props.reload();
          navigate("/users");
        });
      },
    });
  };

  const openPermissionsModal = (): void => {
    if (props.user === "new") return;

    modals.openModal({
      title: "Berechtigungen bearbeiten",
      centered: true,
      children: <PermissionsModal user={props.user} />,
      size: "lg",
    });
  };

  return (
    <>
      <Group position="apart" mb="md">
        <Title order={3}>Persönliche Daten</Title>

        {props.user !== "new" && context.user?.permissions.includes("permissions-edit") && (
          <Button type="button" color="teal" variant="outline" onClick={openPermissionsModal}>
            Berechtigungen ändern
          </Button>
        )}
      </Group>

      <form onSubmit={submitPersonal}>
        <SimpleGrid
          breakpoints={[
            { minWidth: "xs", cols: 1 },
            { minWidth: "md", cols: 2 },
          ]}
          mb="md"
        >
          <Stack>
            <TextInput type="text" label="Vorname" placeholder="Vorname" {...editForm.getInputProps("givenname")} />
            <TextInput type="text" label="Nachname" placeholder="Nachname" {...editForm.getInputProps("surname")} />
            <TextInput type="text" label="Benutzername" placeholder="Wird automatisch erstellt" value={username} readOnly />
            <TextInput type="email" label="E-Mail-Adresse" placeholder="E-Mail-Adresse" {...editForm.getInputProps("mail")} />
            <PasswordInput label="Passwort" placeholder="(unverändert)" {...editForm.getInputProps("password")} />
          </Stack>

          <Stack>
            <TextInput type="text" label="Adresse" placeholder="Adresse" {...editForm.getInputProps("address")} />
            <TextInput type="tel" label="Telefon" placeholder="Telefon" {...editForm.getInputProps("phone")} />
            <DatePicker
              label="Geburtsdatum"
              placeholder="Datum wählen"
              allowFreeInput
              clearable={false}
              locale="de"
              inputFormat="DD. MMMM YYYY"
              dateParser={(ds) => dayjs(ds, "DD.MM.YYYY").toDate()}
              {...editForm.getInputProps("dateOfBirth")}
            />

            <Input.Wrapper label="Geschlecht" error={editForm.errors.sex}>
              <Chip.Group {...editForm.getInputProps("sex")}>
                <Chip value="female">weiblich</Chip>
                <Chip value="male">männlich</Chip>
                <Chip value="other">divers</Chip>
              </Chip.Group>
            </Input.Wrapper>

            <Switch
              label="E-Mail und Telefon an andere Messdiener:innen freigeben"
              checked={editForm.values.sharePersonal}
              onChange={(e) => editForm.setFieldValue("sharePersonal", e.currentTarget.checked)}
            />
          </Stack>
        </SimpleGrid>

        <Grid justify="space-between">
          <Grid.Col sm={12} xl={4}>
            <Button type="button" color="gray" variant="outline" fullWidth onClick={cancel}>
              Abbrechen
            </Button>
          </Grid.Col>

          {props.user !== "new" && (
            <Grid.Col sm={12} xl={4}>
              <Button type="button" color="red" variant="outline" fullWidth onClick={deleteUser}>
                Löschen
              </Button>
            </Grid.Col>
          )}

          <Grid.Col sm={12} xl={4}>
            <Button type="submit" variant="outline" fullWidth>
              Speichern
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </>
  );
};

export default PageUserEdit;

type PermissionsModalProps = {
  user: Database.User;
};

type PermissionTemplate = {
  id: string;
  name: string;
  permissions: string[];
};

const PermissionsModal: React.FC<PermissionsModalProps> = (props) => {
  const [availablePermissions, setAvailablePermissions] = useState<Database.Permission[] | null>(null);
  const [templates, setTemplates] = useState<PermissionTemplate[] | null>(null);
  const [grantedPermissions, setGrantedPermissions] = useState<Set<string>>(new Set());

  // get available and granted permission on load
  useEffect(() => {
    ajax.get(`/users/permissions/${props.user.id}`).on(200, (res: RequestTypes.UsersPermissions) => {
      setAvailablePermissions(res.available);

      setTemplates(
        res.templates.map((t) => ({
          id: t.id,
          name: t.name,
          permissions: t.permissions.split("\n").map((code) => code.trim()),
        }))
      );

      setGrantedPermissions(new Set(res.granted));
    });
  }, [props.user]);

  const setPermission = (code: string, granted: boolean): void => {
    setGrantedPermissions((oldState) => {
      let copy = new Set(oldState);
      if (granted) {
        copy.add(code);
        return copy;
      } else {
        copy.delete(code);
        return copy;
      }
    });
  };

  const applyTemplates = (permissions: string[], granted: boolean): void => {
    setGrantedPermissions((oldState) => {
      let copy = new Set(oldState);
      if (granted) {
        permissions.forEach((code) => copy.add(code));
        return copy;
      } else {
        permissions.forEach((code) => copy.delete(code));
        return copy;
      }
    });
  };

  const submitPermissions = (e: React.FormEvent): void => {
    e.preventDefault();

    ajax
      .post(`/users/permissions/${props.user.id}`, {
        permissions: Array.from(grantedPermissions),
      })
      .on(204, () => {
        showNotification({
          message: "Die Berechtigungen wurden erfolgreich gespeichert.",
          color: "green",
        });
      })
      .on(400, () => {
        showNotification({
          message: "Die Berechtigungen konnten aufgrund eines unbekannten Fehlers nicht gespeichert werden.",
          color: "red",
        });
      });
  };

  return (
    <>
      {(availablePermissions === null || templates === null) && (
        <Center>
          <Loader />
        </Center>
      )}

      {availablePermissions === null || templates === null || (
        <>
          <SimpleGrid cols={2} mb="md">
            <Stack>
              <Title order={5}>Berechtigungen</Title>

              {availablePermissions.map((permission) => (
                <Checkbox
                  key={permission.code}
                  label={permission.name}
                  checked={grantedPermissions.has(permission.code)}
                  onChange={(e) => setPermission(permission.code, e.currentTarget.checked)}
                />
              ))}
            </Stack>

            <Stack>
              <Title order={5}>Templates</Title>

              {templates.map((template) => (
                <Checkbox
                  key={template.id}
                  label={template.name}
                  checked={template.permissions.every((code) => grantedPermissions.has(code))}
                  indeterminate={
                    template.permissions.some((code) => grantedPermissions.has(code)) &&
                    !template.permissions.every((code) => grantedPermissions.has(code))
                  }
                  onChange={(e) => applyTemplates(template.permissions, e.currentTarget.checked)}
                />
              ))}
            </Stack>
          </SimpleGrid>

          <Button type="button" variant="outline" onClick={submitPermissions} fullWidth>
            Speichern
          </Button>
        </>
      )}
    </>
  );
};

import { Button, Center, Group, Loader, SimpleGrid, Stack, Textarea, Title } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useClipboard } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";

type TbaAddress = {
  id: string;
  name: string;
  street: string;
  number: string;
  mail: string;
  phone: string;
  donation: "tree" | "ask" | "other";
  note: string;
  timestamp: Dayjs;
  longitude: string;
  latitude: string;
  route: string | null;
  index: number;
};

const DonationTexts = {
  tree: "Am Baum",
  ask: "Klingeln",
  other: "Anderes",
};

const PageTbaExport: React.FC<{}> = () => {
  const clipboard = useClipboard();

  const [addresses, setAddresses] = useState<TbaAddress[] | null>(null);
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);
  const [mails, setMails] = useState<string[]>([]);

  const routes = useMemo<string[]>(() => {
    if (addresses === null) return [];

    return Array.from(new Set(addresses.map((a) => a.route).filter((route) => route !== null) as string[])).sort();
  }, [addresses]);

  const loadData = (): void => {
    let url = "/tba/registration/";
    if (start !== null && end !== null) {
      url += dayjs(start).format("YYYY-MM-DD") + "/" + dayjs(end).format("YYYY-MM-DD");
    }

    ajax.get(url).on(200, (res: RequestTypes.TbaRegistration) => {
      setStart(dayjs(+res.start * 1000).toDate());
      setEnd(dayjs(+res.end * 1000).toDate());

      setAddresses(
        res.addresses.map((a) => ({
          id: a.id,
          name: a.name,
          street: a.street,
          number: a.number,
          mail: a.mail,
          phone: a.phone,
          donation: a.donation,
          note: a.note,
          timestamp: dayjs(a.timestamp),
          longitude: a.longitude,
          latitude: a.latitude,
          route: a.route,
          index: +a.index,
        }))
      );

      setMails(res.mails.map((m) => m.mail));
    });
  };

  const copyToClipboard = (route: string): void => {
    if (addresses === null) return;

    let texts = addresses
      .filter((a) => a.route === route)
      .sort((a, b) => a.index - b.index)
      .map((a) => [a.index, a.name, a.street, a.number, DonationTexts[a.donation], a.note].join("\t"));
    clipboard.copy("#\tName\tStraße\tHsnr.\tSpende\tNotiz\n" + texts.join("\n"));

    showNotification({
      message: "Die Adressen wurden in die Zwischenablage eingefügt und können nun in Excel eingefügt werden.",
      color: "green",
    });
  };

  useEffect(loadData, []);

  if (addresses === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Stack>
      <Group>
        <DatePicker label="Zeige Anmeldungen von" value={start} onChange={setStart} sx={{ flex: 1 }} />
        <DatePicker label="bis" value={end} onChange={setEnd} sx={{ flex: 1 }} />
        <Button type="button" variant="outline" color="teal" onClick={loadData} mt={27}>
          Liste laden
        </Button>
      </Group>

      <SimpleGrid cols={2}>
        <Stack>
          <Title order={5}>Adressen in Excel kopieren</Title>

          {routes.map((route) => (
            <Button key={route} variant="outline" onClick={() => copyToClipboard(route)}>
              {route}
            </Button>
          ))}
        </Stack>

        <Stack>
          <Title order={5}>Gespeicherte E-Mail-Adressen</Title>

          <Textarea value={mails.join("\n")} readOnly />
        </Stack>
      </SimpleGrid>
    </Stack>
  );
};

export default PageTbaExport;

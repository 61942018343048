import { Card, Center, Grid, Loader, Stack, Text, Title } from "@mantine/core";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { RequestTypes } from "../../../request-types";
import ajax from "../../../service/ajax";

type ServiceType = {
  id: string;
  time: string;
  kind: string;
  church: string;
  hint: string;
  assigns: {
    user: {
      id: string;
      name: string | undefined;
    };
    canceled: boolean;
  }[];
};

const ComponentPlanHistoryTab: React.FC<{}> = () => {
  const [services, setServices] = useState<ServiceType[] | null>(null);
  const [query, setQuery] = useState<string>("");

  const filteredServices = useMemo<ServiceType[] | null>(() => {
    if (services === null) return null;
    return services;

    // let queries = query.split(" ");
    // return services.filter(s => (
    //   queries.every(q => (
    //     s.time.includes(q) ||
    //     s.church.includes(q) // ||
    //     // s.assigns.some(a => a.user.name?.includes(q))
    //   ))
    // ));
  }, [services, query]);

  const loadData = (): void => {
    ajax.get("/plan/history").on(200, (res: RequestTypes.PlanHistory) => {
      let personsMap = new Map<string, string>(res.persons.map((p) => [p.id, `${p.givenname} ${p.surname}`]));

      setServices(
        res.services.map((s) => ({
          id: s.id,
          time: dayjs(s.time).format("ddd, DD.MM.YYYY, HH:mm [Uhr]"),
          kind: s.kind,
          church: s.church,
          hint: s.hint,
          assigns: res.assigns
            .filter((a) => a.service === s.id)
            .map((a) => ({
              user: {
                id: a.user,
                name: personsMap.get(a.user),
              },
              canceled: a.canceled === "1" || a.canceled === "2",
            })),
        }))
      );
    });
  };

  useEffect(loadData, []);

  if (services === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Stack>
      {/* <TextInput label="Suche" placeholder="Suche nach Datum, Kirche und Namen" icon={<Search size={20} />} value={query} onChange={e => setQuery(e.currentTarget.value)} /> */}

      {services.map((service) => (
        <Card key={service.id} shadow="xl">
          <Grid justify="space-between">
            <Grid.Col sm={12} md={6}>
              <Title order={4}>{service.time}</Title>
              <Text>
                {service.kind}, {service.church}
              </Text>

              {service.hint.length > 0 && <Text color="dimmed">{service.hint}</Text>}
            </Grid.Col>

            <Grid.Col sm={12} md={6}>
              <Stack spacing="xs">
                {service.assigns.map(
                  (assign) =>
                    assign.user === undefined || (
                      <Text
                        key={assign.user.id}
                        style={
                          assign.canceled
                            ? {
                                textDecoration: "line-through",
                                textDecorationColor: "red",
                              }
                            : {}
                        }
                      >
                        {assign.user.name}
                      </Text>
                    )
                )}
              </Stack>
            </Grid.Col>
          </Grid>
        </Card>
      ))}
    </Stack>
  );
};

export default ComponentPlanHistoryTab;

import { ActionIcon, Alert, Button, Card, Grid, Group, Stack, Table, Text, Title, Tooltip, UnstyledButton } from "@mantine/core";
import { isSameDate } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { ArrowsShuffle, DeviceFloppy, Graph, Plus, Trash, WorldUpload } from "tabler-icons-react";
import { RequestTypes } from "../../../request-types";
import ajax from "../../../service/ajax";
import useMapState from "../../../service/useMap";
import PersonModal, { SelectorPerson, SelectorUserDatePair } from "./personModal";
import ServiceModal from "./serviceModal";

type ServiceType = {
  id: string;
  time: string;
  kind: string;
  church: string;
  hint: string;
  assigns: PersonType[];
  totalAssigns: number;
};

type PersonType = {
  id: string;
  givenname: string;
  surname: string;
  size: "s" | "l" | null;
  group: string | null;
};

const ComponentPlanEditNewTab: React.FC<{}> = () => {
  const modals = useModals();

  const [services, changeServices] = useMapState<ServiceType>();
  const [persons, setPersons] = useState<Map<string, PersonType>>(new Map());
  const [existingAssigns, setExistingAssigns] = useState<SelectorUserDatePair[]>([]);
  const [signouts, setSignouts] = useState<SelectorUserDatePair[]>([]);

  const [addService, setAddService] = useDisclosure(false);
  const [editService, setEditService] = useState<ServiceType | null>(null);
  const [addAssign, setAddAssign] = useState<ServiceType | null>(null);

  const selectorPersons = useMemo<SelectorPerson[]>(() => {
    return Array.from(persons.values()).map((p) => ({
      id: p.id,
      title: `${p.surname}, ${p.givenname}`,
      size: p.size,
    }));
  }, [persons]);

  const overallAssigns = useMemo<SelectorUserDatePair[]>(() => {
    let newAssigns: SelectorUserDatePair[] = Array.from(services.values())
      .map((s) =>
        s.assigns.map((a) => ({
          user: a.id,
          date: dayjs(s.time).toDate(),
        }))
      )
      .flat();

    return existingAssigns.concat(...newAssigns);
  }, [existingAssigns, services]);

  const sortedServices = useMemo<ServiceType[]>(() => {
    return Array.from(services.values()).sort((a, b) => {
      if (dayjs(a.time).isBefore(dayjs(b.time))) return -1;
      if (dayjs(a.time).isAfter(dayjs(b.time))) return 1;
      return 0;
    });
  }, [services]);

  const loadData = (): void => {
    ajax.get("/plan/services").on(200, (res: RequestTypes.PlanServices) => {
      let personsMap = new Map<string, PersonType>(res.persons.map((p) => [p.id, p]));
      setPersons(personsMap);

      changeServices.setFromArray(
        res.tmp.map((s: any): [string, ServiceType] => [
          s.id,
          {
            ...s,
            assigns: s.assigns.map((a: any) => personsMap.get(a)),
          },
        ])
      );

      setExistingAssigns(
        res.assigns.map((a) => ({
          user: a.user,
          date: dayjs(a.time).toDate(),
        }))
      );

      setSignouts(
        res.signouts.map((s) => ({
          user: s.user,
          date: dayjs(s.date).toDate(),
        }))
      );
    });
  };

  const saveInDB = (): void => {
    let data = {
      services: Array.from(services.values()).map((s) => ({
        ...s,
        assigns: s.assigns.map((a) => a.id),
      })),
    };

    ajax.post("/plan/tmp", data).on(204, () => {
      showNotification({
        message: "Die Gottesdienste wurden erfolgreich gespeichert.",
        color: "green",
      });
    });
  };

  const execDeleteAssign = (service: ServiceType, person: PersonType): void => {
    modals.openConfirmModal({
      title: "Einteilung löschen",
      children: (
        <Text>
          Bist du dir sicher, dass du die Einteilung von {person.givenname} {person.surname} am {dayjs(service.time).format("DD. MMMM YYYY")} löschen
          möchtest?
        </Text>
      ),
      labels: {
        cancel: "Nein, abbrechen",
        confirm: "Ja, löschen",
      },
      cancelProps: {
        variant: "outline",
      },
      confirmProps: {
        color: "red",
        variant: "outline",
      },
      onConfirm: () => {
        changeServices.set(service.id, {
          ...service,
          assigns: service.assigns.filter((a) => a.id !== person.id),
        });
      },
    });
  };

  const execAddAssign = (serviceID: string, userID: string): void => {
    let service = services.get(serviceID);
    let person = persons.get(userID);

    if (service === undefined || person === undefined) {
      showNotification({
        message: "Es gab einen unbekannten Fehler. Bitte führe die Aktion erneut aus.",
        color: "red",
      });
      return;
    }

    changeServices.set(serviceID, {
      ...service,
      assigns: [...service.assigns, person],
    });

    showNotification({
      message: "Die Einteilung wurde hinzugefügt.",
      color: "green",
    });
  };

  const execDeleteService = (service: ServiceType): void => {
    modals.openConfirmModal({
      title: "Einteilung löschen",
      children: (
        <Text>
          Bist du dir sicher, du den Gottesdienst am {dayjs(service.time).format("DD. MMMM YYYY")} in {service.church} löschen möchtest?
        </Text>
      ),
      labels: {
        cancel: "Nein, abbrechen",
        confirm: "Ja, löschen",
      },
      cancelProps: {
        variant: "outline",
      },
      confirmProps: {
        color: "red",
        variant: "outline",
      },
      onConfirm: () => {
        changeServices.remove(service.id);
        showNotification({
          message: "Der Gottesdienst wurde erfolgreich gelöscht.",
          color: "green",
        });
      },
    });
  };

  const execAddService = (id: string | null, timestamp: Date, kind: string, church: string, hint: string, totalAssigns: number): void => {
    let chars = "abcdefghijklmnopqrstuvwxyz0123456789";
    let newID: string;
    do {
      newID = "";
      while (newID.length < 5) {
        newID += chars.charAt(Math.floor(Math.random() * chars.length));
      }
    } while (services.has(newID));

    changeServices.set(newID, {
      id: newID,
      time: dayjs(timestamp).format("YYYY-MM-DD HH:mm:00"),
      kind: kind,
      church: church,
      hint: hint,
      assigns: [],
      totalAssigns: totalAssigns ?? 0,
    });
    setAddService.close();
  };

  const execEditService = (id: string | null, timestamp: Date, kind: string, church: string, hint: string, totalAssigns: number): void => {
    if (id === null || totalAssigns === undefined) return;

    changeServices.set(id, {
      id: id,
      time: dayjs(timestamp).format("YYYY-MM-DD HH:mm:00"),
      kind: kind,
      church: church,
      hint: hint,
      assigns: services.get(id)?.assigns ?? [],
      totalAssigns: totalAssigns,
    });
    setEditService(null);
  };

  const execAutoAssign = (): void => {
    type Group = {
      ids: string[];
      distances: number[];
    };

    services.forEach((service) => {
      let need = service.totalAssigns - service.assigns.length;
      if (need < 0) return;

      let serviceTs = dayjs(service.time);

      // store all persons which are available accessible by their groups
      let groupsMap = new Map<string, Group>();
      persons.forEach((p) => {
        if (p.size === null || p.group === null) return;

        if (signouts.some((s) => s.user === p.id && isSameDate(s.date, serviceTs.toDate()))) return;

        let distance = Math.min(
          ...existingAssigns.filter((a) => a.user === p.id).map((a) => Math.abs(dayjs(a.date).diff(serviceTs, "day"))),
          ...Array.from(services.values())
            .filter((s) => s.assigns.some((a) => a.id === p.id))
            .map((s) => Math.abs(dayjs(s.time).diff(serviceTs, "day")))
        );
        if (distance < 14) return;

        if (!groupsMap.has(p.group))
          groupsMap.set(p.group, {
            ids: [],
            distances: [],
          });
        groupsMap.get(p.group)?.ids.push(p.id);
        groupsMap.get(p.group)?.distances.push(distance);
      });

      // get the groups as array to able to sort them
      let groupsArray = Array.from(groupsMap.values());

      // sort the group by average distance (highest first), groups with one person should be treated like very low average
      groupsArray.sort((a, b) => {
        if (a.ids.length < 2 && b.ids.length >= 2) return 1;
        if (a.ids.length >= 2 && b.ids.length < 2) return -1;

        let avgA = a.distances.reduce((p, c) => p + c) / a.distances.length;
        let avgB = b.distances.reduce((p, c) => p + c) / b.distances.length;

        return avgB - avgA;
      });

      // get two persons with highest distance of each group until need is satisfied
      while (service.totalAssigns - service.assigns.length) {
        let current = groupsArray.shift();
        if (current === undefined) return;

        // @ts-ignore: Object is possibly 'null'.
        let selection = current.ids.sort((a, b) => current.distances[current.ids.indexOf(b)] - current.distances[current.ids.indexOf(a)]).slice(0, 2);

        // @ts-ignore: Object is possibly 'null'.
        service.assigns.push(...selection.map((p) => persons.get(p)).filter((p) => p !== undefined));
      }
      changeServices.set(service.id, service);
    });

    return;
  };

  const execPublish = (): void => {
    if (services.size <= 0) return;

    let data = {
      services: Array.from(services.values()).map((s) => ({
        time: s.time,
        kind: s.kind,
        church: s.church,
        hint: s.hint,
        assigns: s.assigns.map((a) => a.id),
      })),
    };
    ajax
      .post("/plan/services", data)
      .on(204, () => {
        showNotification({
          message: "Der Plan wurde erfolgreich veröffentlicht.",
          color: "green",
        });

        ajax.post("/plan/tmp", { services: [] }).on(204, () => {
          changeServices.setAll(new Map());
        });
      })
      .on(400, () => {
        showNotification({
          message: "Der Plan konnte nicht veröffentlicht werden.",
          color: "red",
        });
      });
  };

  const showStatistics = (): void => {
    let statistics = new Map<string, number>();
    services.forEach((service) => {
      service.assigns.forEach((assign) => {
        statistics.set(assign.id, (statistics.get(assign.id) ?? 0) + 1);
      });
    });

    let personList = Array.from(persons.values()).filter((p) => p.size !== null);

    modals.openModal({
      title: "Statistik",
      children: (
        <Stack>
          <Table>
            <thead>
              <tr>
                <td style={{ width: "70%" }}>Name</td>
                <td style={{ width: "30%" }}>Einteilungen</td>
              </tr>
            </thead>
            <tbody>
              {personList.map((p) => (
                <tr key={p.id}>
                  <td>
                    {p.surname}, {p.givenname}
                  </td>
                  <td>{statistics.get(p.id) ?? 0}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Stack>
      ),
    });
  };

  useEffect(loadData, [changeServices]);

  return (
    <>
      <ServiceModal
        lockDate
        showTotalAssigns
        show={editService !== null}
        onHide={() => setEditService(null)}
        service={editService}
        onSave={execEditService}
      />
      <ServiceModal showTotalAssigns show={addService} service={null} onSave={execAddService} onHide={setAddService.close} />
      <PersonModal
        opened={addAssign !== null}
        onClose={() => setAddAssign(null)}
        service={addAssign}
        onSave={execAddAssign}
        persons={selectorPersons}
        assigns={overallAssigns}
        signouts={signouts}
      />

      <p>
        Der hier erstellte Plan ist nur in deinem Browser gespeichert. Du kannst die Website schließen und später wieder öffnen, ohne dass
        Gottesdienste verloren gehen. Sie werden jedoch erst sichtbar, sobald du den fertigen Plan veröffentlichst.
      </p>
      <p>
        In einem ersten Schritt kannst du die Gottesdienste anlegen. Sobald du alle Gottesdienste angelegt hast, kannst du überall, wo dies gewünscht
        ist, manuell Messdiener:innen einteilen. Im Anschluss kannst du alle anderen Gottesdienste automatisch planen lassen. Eine Veröffentlichung
        des Plans ist erst möglich, wenn alle angegebenen Messdienerzahlen erfüllt wurden.
      </p>

      <Group position="center" mb="md">
        <Tooltip label="Statistik anzeigen">
          <ActionIcon color="gray" size="lg" onClick={showStatistics}>
            <Graph size={26} />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Gottesdienst hinzufügen">
          <ActionIcon color="teal" onClick={setAddService.open}>
            <Plus />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Automatische Zuteilung starten">
          <ActionIcon color="gray" onClick={execAutoAssign} disabled={Array.from(services.values()).every((s) => s.assigns.length >= s.totalAssigns)}>
            <ArrowsShuffle />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Zwischenstand speichern">
          <ActionIcon type="button" onClick={saveInDB}>
            <DeviceFloppy />
          </ActionIcon>
        </Tooltip>

        <Tooltip label="Plan veröffentlichen">
          <ActionIcon onClick={execPublish} disabled={Array.from(services.values()).some((s) => s.assigns.length < s.totalAssigns)}>
            <WorldUpload />
          </ActionIcon>
        </Tooltip>
      </Group>

      <Stack>
        {sortedServices.map((service) => (
          <Card key={service.id} shadow="xl">
            <Grid justify="space-between">
              <Grid.Col sm={12} md={4}>
                <Title order={4}>{dayjs(service.time).format("ddd, DD.MM.YYYY, HH:mm [Uhr]")}</Title>
                <Text>
                  {service.kind}, {service.church}
                </Text>

                {service.hint.length > 0 && <Text color="dimmed">{service.hint}</Text>}
              </Grid.Col>

              <Grid.Col sm={12} md={4}>
                <Stack spacing="xs">
                  {service.assigns.map(
                    (user) =>
                      user === undefined || (
                        <UnstyledButton
                          key={user.id}
                          type="button"
                          className="on-hover-visible-child"
                          onClick={() => execDeleteAssign(service, user)}
                        >
                          <Group>
                            <span className="hover-child">
                              <Trash size={15} />
                            </span>
                            <span>
                              {user.givenname} {user.surname}
                            </span>
                          </Group>
                        </UnstyledButton>
                      )
                  )}
                  {service.totalAssigns - service.assigns.length > 0 && (
                    <Alert color="grape">{service.totalAssigns - service.assigns.length} werden automatisch zugeteilt</Alert>
                  )}
                </Stack>
              </Grid.Col>

              <Grid.Col sm={12} md={4}>
                <Stack spacing="xs">
                  <Button type="button" color="teal" variant="outline" onClick={() => setEditService(service)}>
                    Bearbeiten
                  </Button>
                  <Button type="button" color="teal" variant="outline" onClick={() => setAddAssign(service)}>
                    Messdiener:in hinzufügen
                  </Button>
                  <Button type="button" color="red" variant="outline" onClick={() => execDeleteService(service)}>
                    Löschen
                  </Button>
                </Stack>
              </Grid.Col>
            </Grid>
          </Card>
        ))}
      </Stack>
    </>
  );
};

export default ComponentPlanEditNewTab;

import { Button, Center, Grid, List, Loader, SimpleGrid, Stack, Text, useMantineTheme } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";

const random5string = (): string => {
  let chars = "abcdefghijklmnopqrstuvwxyz";
  let res = "";
  while (res.length < 5) {
    res += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return res;
};

type PersonDataType = {
  id: string;
  size: "s" | "l" | null;
  sendmails: boolean;
  group: string | null;
  givenname: string;
  surname: string;
  age: string;
};

const PagePlanPersons: React.FC<{}> = () => {
  const [persons, setPersons] = useState<PersonDataType[] | null>(null);
  const [personsInitial, setPersonsInitial] = useState<PersonDataType[]>([]);

  const loadPersons = (): void => {
    ajax.get("/plan/persons").on(200, (res: RequestTypes.PlanPersons) => {
      let tmp: PersonDataType[] = res.map((p) => ({
        id: p.id,
        size: p.size,
        sendmails: p.sendmails === "1",
        group: p.group,
        givenname: p.givenname,
        surname: p.surname,
        age: p.age,
      }));

      setPersons(tmp);
      setPersonsInitial(tmp);
    });
  };

  const changedPersons = useMemo<string[]>(() => {
    if (persons === null) return [];

    let changes: string[] = [];
    for (let i = 0; i < personsInitial.length; i++) {
      if (personsInitial[i].size !== persons[i].size || personsInitial[i].group !== persons[i].group) {
        changes.push(personsInitial[i].id);
      }
    }
    return changes;
  }, [persons, personsInitial]);

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    // extract some data
    let dest = result.destination.droppableId.split("-");
    let personID: string = result.draggableId.split("-")[1];
    let targetSize: "s" | "l" | null = dest[1] === "s" ? "s" : dest[1] === "l" ? "l" : null;
    let targetGroup: string | null = dest[2] === "null" ? null : dest[2];

    // change persons array
    setPersons((oldPersons) => {
      if (oldPersons === null) return null;

      let map = new Map();
      oldPersons.forEach((p) => {
        map.set(p.id, { ...p });
      });

      if (!map.has(personID)) {
        return oldPersons;
      }
      map.set(personID, {
        ...map.get(personID),
        size: targetSize,
        group: targetGroup,
      });

      return Array.from(map.values());
    });
  };

  const save = (): void => {
    if (changedPersons.length <= 0 || persons === null) return;

    // only send id, size and group of changed persons
    let updates = persons
      .filter((p) => changedPersons.includes(p.id))
      .map((p) => ({
        id: p.id,
        size: p.size,
        group: p.group,
      }));

    ajax.post("/plan/persons", { updates: updates }).on(204, () => {
      showNotification({
        message: "Die geänderten Personen wurden gespeichert.",
        color: "green",
      });
      loadPersons();
    });
  };

  useEffect(loadPersons, []);

  if (persons === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <List mb="md">
        <List.Item>
          Für den Plan werden alle Personen in den Listen unten genutzt (nach Größe). Alle Personen in der linken Liste dienen nicht.
        </List.Item>
        <List.Item>Wenn eine Person vom System für eine Messe ausgewählt wird, wird auch eine Person aus ihrer Gruppe ausgewählt</List.Item>
        <List.Item>Personen in einer Gruppe sollten auch in der gleichen Kirche dienen können</List.Item>
      </List>

      {changedPersons.length > 0 && (
        <Grid justify="flex-end" mb="md">
          <Grid.Col xs={12} lg={4}>
            <Button type="button" variant="outline" onClick={save} fullWidth>
              Änderungen speichern
            </Button>
          </Grid.Col>
        </Grid>
      )}

      <SimpleGrid cols={3}>
        <div>
          <h4>
            dient nicht <small> - {persons.filter((p) => p.size === null).length}</small>
          </h4>
          <PersonsSizeList size={null} persons={persons} />
        </div>

        <div>
          <h4>
            klein <small> - {persons.filter((p) => p.size === "s").length}</small>
          </h4>
          <PersonsSizeList size="s" persons={persons} />
        </div>

        <div>
          <h4>
            groß <small> - {persons.filter((p) => p.size === "l").length}</small>
          </h4>
          <PersonsSizeList size="l" persons={persons} />
        </div>
      </SimpleGrid>
    </DragDropContext>
  );
};

export default PagePlanPersons;

type PersonsSizeListProps = {
  size: "s" | "l" | null;
  persons: PersonDataType[];
};

const PersonsSizeList: React.FC<PersonsSizeListProps> = (props) => {
  const theme = useMantineTheme();

  // get matching persons (by size)
  const matchingPersons = useMemo<PersonDataType[]>(() => {
    return props.persons.filter((p) => p.size === props.size);
  }, [props.size, props.persons]);

  // get (distinct) groups
  const groups = useMemo<Set<string | null>>(() => {
    return new Set(matchingPersons.map((p) => p.group));
  }, [matchingPersons]);

  // calc random string for new group as identifier
  const newGroupID = useMemo<string>(() => {
    let res: string;
    do {
      res = random5string();
    } while (groups.has(res));

    return res;
  }, [groups]);

  const getGroupStyle = (isDraggingOver: boolean) => ({
    backgroundColor: isDraggingOver ? theme.colors.indigo[2] : theme.black,
    borderRadius: "5px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.colors.gray[5],
  });

  const getElementStyles = (append?: React.CSSProperties): React.CSSProperties => ({
    backgroundColor: theme.black,
    borderRadius: "5px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: theme.colors.gray[5],
    padding: "5px",
    ...append,
  });

  return (
    <Stack spacing="md" mb="xl">
      {Array.from(groups).map((g) => (
        <Droppable key={g} droppableId={`area-${props.size}-${g}`}>
          {(provided, snapshot) => (
            <Stack p="xs" spacing="xs" style={getGroupStyle(snapshot.isDraggingOver)} ref={provided.innerRef} {...provided.droppableProps}>
              {matchingPersons
                .filter((p) => p.group === g)
                .map((p, i) => (
                  <Draggable key={p.id} draggableId={`person-${p.id}`} index={i}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getElementStyles(provided.draggableProps.style)}
                      >
                        {p.givenname} {p.surname} ({p.age} Jahre)
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      ))}

      {props.size !== null && (
        <Droppable droppableId={`area-${props.size}-${newGroupID}`}>
          {(provided, snapshot) => (
            <Stack p="xs" spacing="xs" style={getGroupStyle(snapshot.isDraggingOver)} ref={provided.innerRef} {...provided.droppableProps}>
              <Text>Hier hinziehen, um neue Gruppe zu Erstellen</Text>

              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      )}
    </Stack>
  );
};

import { Center, Loader, Tabs } from "@mantine/core";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import { Database, RequestTypes } from "../../../request-types";
import ajax from "../../../service/ajax";
import { TabPermissions } from "./permissions";
import { TabTemplates } from "./templates";

dayjs.extend(customParseFormat);

type PermissionTemplate = {
  id: string;
  name: string;
  permissions: string[];
};

const PageUserPermissions: React.FC<{}> = () => {
  const [permissions, setPermissions] = useState<Database.Permission[] | null>(null);
  const [templates, setTemplates] = useState<PermissionTemplate[] | null>(null);
  const [assigns, setAssigns] = useState<Database.UserPermission[]>([]);
  const [users, setUsers] = useState<Pick<Database.User, "id" | "givenname" | "surname">[]>([]);

  const loadData = (): void => {
    ajax.get("/permissions").on(200, (res: RequestTypes.Permissions) => {
      setPermissions(res.permissions);
      setAssigns(res.assigns);
      setUsers(res.users);

      setTemplates(
        res.templates.map((t) => ({
          id: t.id,
          name: t.name,
          permissions: t.permissions
            .split("\n")
            .map((code) => code.trim())
            .filter((code) => code.length > 0),
        }))
      );
    });
  };

  useEffect(loadData, []);

  if (permissions === null || templates === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Tabs defaultValue="permissions">
      <Tabs.List>
        <Tabs.Tab value="permissions">Berechtigungen</Tabs.Tab>
        <Tabs.Tab value="templates">Templates</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="permissions" pt="xs">
        <TabPermissions permissions={permissions} assigns={assigns} users={users} reload={loadData} />
      </Tabs.Panel>

      <Tabs.Panel value="templates" pt="xs">
        <TabTemplates permissions={permissions} templates={templates} reload={loadData} />
      </Tabs.Panel>
    </Tabs>
  );
};

export default PageUserPermissions;

import { Button, Center, Grid, LoadingOverlay, Select, SelectItem, useMantineTheme } from "@mantine/core";
import { Calendar, isSameDate } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import "dayjs/locale/de";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../..";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";

const PagePlanSignout: React.FC<{}> = () => {
  const context = useContext(AppContext);
  const [persons, setPersons] = useState<SelectItem[] | null>(null);
  const [currentPerson, setCurrentPerson] = useState<string | null>(null);
  console.log(currentPerson);

  const loadPersons = (): void => {
    ajax
      .get("/plan/persons")
      .on(200, (res: RequestTypes.PlanPersons) => {
        setPersons(
          res.map(
            (p): SelectItem => ({
              value: p.id,
              label: `${p.givenname} ${p.surname}`,
            })
          )
        );
      })
      .on(403, () => {
        // will occure each time a person without editor rights tries to open this page for themselve -> set user's id as current person
        setCurrentPerson(context.user === null ? null : context.user.id);
      });
  };

  useEffect(loadPersons, [context.user]);

  return (
    <>
      <Grid>
        <Grid.Col xs={12} md={6}>
          <h4>So funktionierts</h4>

          <p>
            Bitte gebe hier rechtzeitig an, an welchen Tagen du keine Zeit hast, eine Messe zu dienen. Dann können wir das direkt im nächsten
            Messdienerplan berücksichtigen und du brauchst dich nicht um eine Vertretung zu kümmern. Im Kalender kannst du sehen, an welchen Tagen du
            dich bereits abgemeldet hast; diese sind blau markiert. Um dich abzumelden, klicke einfach auf den entsprechenden Tag im Kalender und am
            Ende auf <i>Änderungen speichern</i>.
          </p>

          <p>Bitte beachte, dass Abmeldungen, die mehr als 30 Tage zurückliegen, nicht mehr angezeigt werden!</p>

          {persons === null || (
            <>
              <hr />

              <p>
                Neben deinen eigenen Abmeldungen kannst du auch für andere Personen Abmeldungen einsehen und angeben. Wähle dazu über dem Kalender
                aus, für welche Person du die Abmeldungen editieren möchtest.
              </p>
            </>
          )}
        </Grid.Col>

        <Grid.Col xs={12} md={6}>
          {persons === null || (
            <Select
              label="Person auswählen"
              data={persons}
              value={currentPerson}
              onChange={setCurrentPerson}
              searchable
              clearable
              mb="md"
              sx={{ flex: 1 }}
            />
          )}

          {currentPerson === null || <CalendarSelection user={currentPerson} />}
        </Grid.Col>
      </Grid>
    </>
  );
};

export default PagePlanSignout;

type CalendarSelectionProps = {
  user: string;
};

const CalendarSelection: React.FC<CalendarSelectionProps> = (props) => {
  const theme = useMantineTheme();

  const [dates, setDates] = useState<Date[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadCurrentDates = (): void => {
    setLoading(true);

    ajax.get("/plan/signouts/" + props.user).on(200, (res: RequestTypes.PlanSignouts) => {
      setDates(res.signouts.map((date: string) => dayjs(date).toDate()));
      setLoading(false);
    });
  };

  const switchDate = (date: Date): void => {
    setDates((oldDates) => {
      let done: boolean = false;

      let newDates = oldDates.filter((d) => {
        let same = isSameDate(date, d);
        if (same) {
          done = true;
        }
        return !same;
      });

      if (!done) {
        newDates.push(date);
      }

      return newDates;
    });
  };

  const calcDateStyle = (date: Date): React.CSSProperties => {
    if (dates.some((d) => isSameDate(date, d))) {
      return {
        backgroundColor: theme.colors.blue[9],
        color: theme.white,
      };
    }

    return {};
  };

  const save = (): void => {
    let data = {
      signouts: dates.map((date) => dayjs(date).format("YYYY-MM-DD")),
    };

    ajax.post("/plan/signouts/" + props.user, data).on(204, () => {
      showNotification({
        message: "Die gewählten Tage wurden als Abmeldungen gespeichert.",
        color: "green",
      });
    });
  };

  useEffect(loadCurrentDates, [props.user]);

  return (
    <div style={{ position: "relative" }}>
      <LoadingOverlay visible={loading} />

      <Center>
        <Calendar locale="de" dayStyle={calcDateStyle} onChange={switchDate} size="xl" mb="md" />
      </Center>

      <Button type="submit" variant="outline" onClick={save} fullWidth>
        Änderungen speichern
      </Button>
    </div>
  );
};

import { Autocomplete, Button, Group, Modal, NumberInput, Stack, Text, TextInput } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { z } from "zod";
import { combineDateTime } from "../../../service/service";

type ServiceType = {
  id: string;
  time: string;
  kind: string;
  church: string;
  hint: string;
  totalAssigns?: number;
};

type FormType = {
  datetime: Date | null;
  kind: string;
  church: string;
  hint: string;
  totalAssigns: number;
};

type Props = {
  service: ServiceType | null;
  lockDate?: boolean;
  showTotalAssigns?: boolean;
  onSave: (id: string | null, datetime: Date, kind: string, church: string, hint: string, totalAssigns: number) => void;
  show: boolean;
  onHide: () => void;
};

const ServiceModal: React.FC<Props> = (props) => {
  const form = useForm<FormType>({
    initialValues: {
      datetime: null,
      kind: "",
      church: "",
      hint: "",
      totalAssigns: 0,
    },
    validate: zodResolver(
      z.object({
        datetime: z.instanceof(Date, { message: "Das Datum ist nicht gültig." }),
        kind: z.string().nonempty("Bitte gebe an, um was für einen Gottesdienst es sich handelt."),
        church: z.string().nonempty("Bitte gebe an, wo der Gottesdienst stattfindet."),
        totalAssigns: z.number().min(0, "Die Zahl darf nicht negativ sein."),
      })
    ),
  });

  const submit = (e: React.FormEvent): void => {
    e.preventDefault();

    if (!form.validate().hasErrors && form.values.datetime !== null) {
      props.onSave(
        props.service === null ? null : props.service.id,
        form.values.datetime,
        form.values.kind,
        form.values.church,
        form.values.hint,
        form.values.totalAssigns
      );
    }
  };

  const setDefaultFormData = () => {
    if (props.service === null) {
      form.setValues({
        datetime: null,
        kind: "",
        church: "",
        hint: "",
        totalAssigns: 0,
      });
    } else {
      form.setValues({
        datetime: dayjs(props.service.time).toDate(),
        kind: props.service.kind,
        church: props.service.church,
        hint: props.service.hint,
        totalAssigns: props.service.totalAssigns ?? 0,
      });
    }
    form.clearErrors();
  };

  useEffect(setDefaultFormData, [props.service]);

  return (
    <Modal opened={props.show} onClose={props.onHide} title="Gottesdienst hinzufügen/bearbeiten">
      <form onSubmit={submit}>
        <Stack>
          {props.lockDate && (
            <Text>
              Ein Ändern des Datums eines Gottesdienstes ist nicht möglich, da hierzu die Anmeldungen neu geprüft werden müssen. Sofern notwendig,
              lösche bitte den Gottesdienst und erstelle ihn dann erneut.
            </Text>
          )}
          {props.lockDate || (
            <DatePicker
              label="Datum"
              value={form.values.datetime}
              onChange={(date) => form.setFieldValue("datetime", combineDateTime(date, form.values.datetime))}
              error={form.errors.datetime}
            />
          )}

          <TimeInput
            label="Uhrzeit"
            value={form.values.datetime}
            onChange={(time) => form.setFieldValue("datetime", combineDateTime(form.values.datetime, time))}
            error={form.errors.datetime}
          />

          <Autocomplete
            label="Art"
            placeholder="Bitte wählen oder eingeben"
            data={["Heilige Messe", "Wortgottesdienst"]}
            {...form.getInputProps("kind")}
          />

          <Autocomplete
            label="Kirche"
            placeholder="Bitte wählen oder eingeben"
            data={["St. Josef", "St. Marien", "Christ König"]}
            {...form.getInputProps("church")}
          />

          <TextInput label="Hinweistext" placeholder="Hinweistext" {...form.getInputProps("hint")} />

          {props.showTotalAssigns && (
            <NumberInput
              label="Gesamtzahl der Messdiener"
              placeholder="Gesamtzahl der Messdiener"
              {...form.getInputProps("totalAssigns")}
              min={0}
              step={1}
            />
          )}

          <Group align="flex-end">
            <Button type="button" color="gray" variant="outline" onClick={props.onHide}>
              Abbrechen
            </Button>
            <Button type="submit" variant="outline">
              Speichern
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  );
};

export default ServiceModal;

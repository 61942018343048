import { Button, Divider, Grid, PasswordInput, Stack, Switch, TextInput, Title, Tooltip } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useForm, zodResolver } from "@mantine/form";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { z } from "zod";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";

type EditFormType = {
  givenname: string;
  surname: string;
  mail: string;
  address: string;
  phone: string;
  dateOfBirth: Date | null;
  sharePersonal: boolean;
};

type PasswordFormType = {
  pass1: string;
  pass2: string;
};

const PageProfile: React.FC<{}> = (props) => {
  const editForm = useForm<EditFormType>({
    initialValues: {
      givenname: "",
      surname: "",
      mail: "",
      address: "",
      phone: "",
      dateOfBirth: null,
      sharePersonal: false,
    },
    validate: zodResolver(
      z.object({
        givenname: z.string().min(1, "Der Vorname darf nicht leer sein.").max(25, "Der Vorname darf maximal 25 Zeichen lang sein."),
        surname: z.string().min(1, "Der Nachname darf nicht leer sein.").max(25, "Der Nachname darf maximal 25 Zeichen lang sein."),
        mail: z.string().email("Dies ist keine gültige Mail-Adresse.").max(75, "Die Mail-Adresse darf maximal 75 Zeichen lang sein."),
        address: z.string().min(1, "Die Adresse darf nicht leer sein.").max(75, "Die Adresse darf maximal 75 Zeichen lang sein."),
        phone: z.string().min(1, "Die Telefonnummer darf nicht leer sein.").max(25, "Die Telefonnummer darf maximal 25 Zeichen lang sein."),
        dateOfBirth: z.instanceof(Date, { message: "Dies ist kein gültiges Datum." }),
      })
    ),
  });

  const passwordForm = useForm<PasswordFormType>({
    initialValues: {
      pass1: "",
      pass2: "",
    },
    validate: {
      pass1: (val) => null,
      pass2: (val, values) => (val === values.pass1 ? null : "Das Passwort ist nicht gleich."),
    },
  });

  const loadData = (): void => {
    ajax.get("/profile").on(200, (res: RequestTypes.Profile) => {
      editForm.setValues({
        givenname: res.givenname,
        surname: res.surname,
        mail: res.mail,
        address: res.address,
        phone: res.phone,
        dateOfBirth: dayjs(res.dateofbirth).toDate(),
        sharePersonal: res.sharepersonal === "1",
      });
    });
  };

  const submit = (e: React.FormEvent): void => {
    e.preventDefault();

    if (!editForm.validate().hasErrors) {
      let data = {
        givenname: editForm.values.givenname,
        surname: editForm.values.surname,
        mail: editForm.values.mail,
        address: editForm.values.address,
        phone: editForm.values.phone,
        dateofbirth: dayjs(editForm.values.dateOfBirth).format("YYYY-MM-DD"),
        sharepersonal: editForm.values.sharePersonal ? "1" : "0",
      };

      ajax.post("/profile/data", data).on(204, () => {
        showNotification({
          message: "Deine Daten wurden erfolgreich gespeichert.",
          color: "green",
        });
      });
    }
  };

  const changePassword = (e: React.FormEvent): void => {
    e.preventDefault();

    if (!passwordForm.validate().hasErrors) {
      let data = {
        password: passwordForm.values.pass1,
      };

      ajax.post("/profile/password", data).on(204, () => {
        showNotification({
          message: "Dein Password wurde erfolgreich geändert.",
          color: "green",
        });
      });
    }
  };

  useEffect(loadData, []);

  return (
    <>
      <Title order={3}>Persönliche Daten</Title>
      <form onSubmit={submit}>
        <Grid mb="md">
          <Grid.Col md={6}>
            <Stack>
              <TextInput type="text" label="Vorname" placeholder="Vorname" {...editForm.getInputProps("givenname")} />
              <TextInput type="text" label="Nachname" placeholder="Nachname" {...editForm.getInputProps("surname")} />
              <TextInput type="email" label="E-Mail-Adresse" placeholder="E-Mail-Adresse" {...editForm.getInputProps("mail")} />
            </Stack>
          </Grid.Col>

          <Grid.Col md={6}>
            <Stack>
              <TextInput type="text" label="Adresse" placeholder="Adresses" {...editForm.getInputProps("address")} />
              <TextInput type="tel" label="Telefon" placeholder="Telefon" {...editForm.getInputProps("phone")} />
              <DatePicker
                label="Geburtsdatum"
                placeholder="Datum wählen"
                clearable={false}
                locale="de"
                inputFormat="DD. MMMM YYYY"
                {...editForm.getInputProps("dateOfBirth")}
              />

              <Tooltip
                openDelay={500}
                width={400}
                label="Andere Messdiener:innen können in der Messdienerliste deine E-Mail-Adresse und deine Telefonnumer sehen, sobald sie sich einloggen. Dies kann vor allem dabei helfen, Vertretungen für Gottesdienste zu finden."
              >
                <Switch
                  label="E-Mail und Telefon an andere Messdiener:innen freigeben"
                  checked={editForm.values.sharePersonal}
                  onChange={(e) => editForm.setFieldValue("sharePersonal", e.currentTarget.checked)}
                />
              </Tooltip>

              <Button type="submit" variant="outline" fullWidth>
                Speichern
              </Button>
            </Stack>
          </Grid.Col>
        </Grid>
      </form>

      <Divider mb="md" />

      <Title order={3}>Passwort ändern</Title>
      <form onSubmit={changePassword}>
        <Grid mb="md">
          <Grid.Col md={6}>
            <Stack>
              <PasswordInput label="Dein neues Passwort" placeholder="Passwort eingeben" {...passwordForm.getInputProps("pass1")} />
            </Stack>
          </Grid.Col>

          <Grid.Col md={6}>
            <Stack>
              <PasswordInput label="Passwort wiederholen" placeholder="Passwort wiederholen" {...passwordForm.getInputProps("pass2")} />
              <Button type="submit" variant="outline" fullWidth>
                Passwort ändern
              </Button>
            </Stack>
          </Grid.Col>
        </Grid>
      </form>
    </>
  );
};

export default PageProfile;

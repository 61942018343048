import { Button, Card, Center, Grid, Loader, Text, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";

type Params = {
  id: string;
  token: string;
};

type Data = {
  id: string;
  givenname: string;
  surname: string;
  sharePersonal: boolean;
};

const PageSharePersonal: React.FC<{}> = () => {
  const params = useParams() as Params;
  const [status, setStatus] = useState<Data | "loading" | "failed" | "done">("loading");

  const loadData = (): void => {
    ajax
      .get(`/profile/sharepersonal/${params.id}/${params.token}`)
      .on(200, (res: RequestTypes.ProfileSharepersonal) => {
        setStatus({
          id: res.id,
          givenname: res.givenname,
          surname: res.surname,
          sharePersonal: res.sharepersonal === "1",
        });
      })
      .on(400, () => {
        setStatus("failed");
      });
  };

  const enable = (): void => {
    ajax
      .post(`/profile/sharepersonal/${params.id}/${params.token}`, null)
      .on(204, () => {
        setStatus("done");
      })
      .on(400, () => {
        setStatus("failed");
      });
  };

  useEffect(loadData, []);

  if (status === "loading") {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Grid justify="center">
      <Grid.Col xs={12} md={8} lg={5}>
        <Card shadow="xl">
          <Title order={3} mb="md">
            Datenfreigabe
          </Title>

          {status === "failed" && (
            <Text>
              Der von Dir aufgerufene Link ist nicht korrekt. Bitte überprüfe den Link oder gehe in Dein{" "}
              <Text variant="link" component={Link} to="/profile">
                Profil
              </Text>
              , um die gewünschte Einstellung zu ändern.
            </Text>
          )}

          {status === "done" && <Text>Deine Datenfreigabe wurde erfolgreich aktiviert.</Text>}

          {status !== "failed" && status !== "done" && status.sharePersonal && (
            <Text>
              Die Freigabe Deiner Daten (E-Mail-Adresse und Telefonnummer) ist bereits aktiv. Wenn Du diese zurücknehmen möchtest, kannst Du dies in
              Deinem{" "}
              <Text variant="link" component={Link} to="/profile">
                Profil
              </Text>{" "}
              machen.
            </Text>
          )}

          {status === "failed" || status === "done" || status.sharePersonal || (
            <>
              <Text mb="md">
                Indem Du Deine Daten (E-Mail-Adresse und Telefonnummer) mit anderen Messdiener:innen teilst, hilft Du z.B. dabei, dass es für alle
                möglich ist, eine Vertretung für Gottesdienste zu finden. Deine Daten werden dabei niemals an Personen, die nicht zu den Kempener
                Messdiener:innen gehören, weitergegeben. Du kannst die Weitergabe Deiner Daten jederzeit zurücknehmen.
              </Text>

              <Button type="button" variant="outline" fullWidth onClick={enable}>
                Daten jetzt freigeben
              </Button>
            </>
          )}
        </Card>
      </Grid.Col>
    </Grid>
  );
};

export default PageSharePersonal;

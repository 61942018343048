import { Button, Card, Center, Checkbox, Grid, Loader, Text, Title } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { RequestTypes } from "../../request-types";
import ajax from "../../service/ajax";

type ServiceType = {
  time: string;
  kind: string;
  church: string;
};

const PagePlanCancel: React.FC<{}> = () => {
  const { token } = useParams<{ token: string }>();

  const [service, setService] = useState<ServiceType | null>(null);
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    ajax
      .get("/plan/cancel/" + token)
      .on(200, (res: RequestTypes.PlanCancel) => {
        setService(res);
      })
      .on(400, () => {
        showNotification({
          message: "Der von dir verwendete Link ist ungültig.",
          color: "red",
        });
      });
  }, [token]);

  const cancel = (): void => {
    ajax
      .post("/plan/cancel/" + token, null)
      .on(204, () => {
        showNotification({
          message: "Die Abmeldung wurde erfolgreich an das Pfarrbüro und die Leiterrunde weitergeleitet.",
          color: "green",
        });
      })
      .on(400, () => {
        showNotification({
          message:
            "Die Abmeldung konnte nicht entgegengenommen werden. Bitte versuche es später erneut oder melde dich per Mail im Pfarrbüro und bei der Leiterrunde ab.",
          color: "red",
        });
      });
  };

  if (service === null) {
    return (
      <Center>
        <Loader />
      </Center>
    );
  }

  return (
    <Grid justify="center" mb="xl">
      <Grid.Col xs={12} md={8} lg={4}>
        {
          <Card shadow="xl">
            <Title order={4}>Von Gottesdienst abmelden</Title>

            <Text color="dimmed" mb="md">
              {service.kind} am {dayjs(service.time).format("DD.MM.YYYY")} um {dayjs(service.time).format("HH:mm")} Uhr, {service.church}
            </Text>

            <Text>
              Solltest du einmal einen Gottesdienst, für den du bereits eingeteilt wurdest, nicht dienen können, kannst du dich hier von diesem
              Abmelden.
            </Text>
            <Text mb="md">
              Dies dient jedoch nur unserer Information, wir finden dadurch nicht automatisch einen Vertretung für dich. Bitte versuche daher erst,
              die um eine Vertretung zu kümmern, und gebe uns so früh wie möglich Bescheid.
            </Text>

            <Checkbox
              label="Ich habe eine Vertretung gesucht, aber konnte niemanden finden."
              checked={checked}
              onChange={(e) => setChecked(e.currentTarget.checked)}
              mb="md"
            />

            <Button type="submit" variant="outline" disabled={!checked} onClick={cancel} fullWidth>
              Jetzt abmelden
            </Button>
          </Card>
        }
      </Grid.Col>
    </Grid>
  );
};

export default PagePlanCancel;

import { NavbarDefinition, NavbarDropdownDivider, NavbarElement, NavbarGroup, NavbarLink } from "./mantine-navbar";

export type NavbarGeneratorType = {
  addGroup: () => NavbarGeneratorGroup;
  getDefinition: () => NavbarDefinition;
};

type NavbarGeneratorGroup = {
  addLink: (label: string, target: string, external?: boolean) => void;
  addButton: (label: string, cb: () => void) => void;
  addDropdown: (label: string) => NavbarGeneratorDropdown;
  get: () => NavbarGroup;
};

type NavbarGeneratorDropdown = {
  addLink: (label: string, target: string, external?: boolean) => NavbarGeneratorDropdown;
  addDivider: () => NavbarGeneratorDropdown;
  get: () => NavbarElement;
};

export const NavbarGenerator = (): NavbarGeneratorType => {
  const groups: NavbarGeneratorGroup[] = [];

  const createGroup = (): NavbarGeneratorGroup => {
    const groupChildren: NavbarGroup = [];

    const addLinkToGroup = (label: string, target: string, external?: boolean): void => {
      groupChildren.push({
        type: "link",
        element: { label, target, external },
      });
    };

    const addButtonToGroup = (label: string, cb: () => void): void => {
      groupChildren.push({
        type: "button",
        element: { label, cb },
      });
    };

    const createDropdownForGroup = (label: string): NavbarGeneratorDropdown => {
      const dropdownChildren: (NavbarLink | NavbarDropdownDivider)[] = [];

      const addLinkToDropdown = (label: string, target: string, external?: boolean): NavbarGeneratorDropdown => {
        dropdownChildren.push({ label, target, external });

        return {
          addLink: addLinkToDropdown,
          addDivider: addDividerToDropdown,
          get: getDropdownChildren,
        };
      };

      const addDividerToDropdown = (): NavbarGeneratorDropdown => {
        dropdownChildren.push("divider");

        return {
          addLink: addLinkToDropdown,
          addDivider: addDividerToDropdown,
          get: getDropdownChildren,
        };
      };

      const getDropdownChildren = (): NavbarElement => {
        return {
          type: "dropdown",
          element: {
            label: label,
            children: dropdownChildren,
          },
        } as NavbarElement;
      };

      return {
        addLink: addLinkToDropdown,
        addDivider: addDividerToDropdown,
        get: getDropdownChildren,
      };
    };

    const addDropdownToGroup = (label: string): NavbarGeneratorDropdown => {
      let dropdown = createDropdownForGroup(label);
      groupChildren.push(dropdown.get());

      return dropdown;
    };

    const getGroupChildren = (): NavbarGroup => {
      return groupChildren;
    };

    return {
      addLink: addLinkToGroup,
      addButton: addButtonToGroup,
      addDropdown: addDropdownToGroup,
      get: getGroupChildren,
    };
  };

  const addGroup = (): NavbarGeneratorGroup => {
    let group = createGroup();
    groups.push(group);

    return group;
  };

  const getDefintion = (): NavbarDefinition => {
    return groups.map((group) => group.get());
  };

  return {
    addGroup: addGroup,
    getDefinition: getDefintion,
  };
};
